<div *ngIf="oneInstall !== true && showBar == true" class="content-card-blue">
  <div (click)="toggleInstallSelect('false')" style="cursor:pointer;color:white;position:absolute;top:10px;right:10px" class="hide-installbar">
    <mat-icon style="cursor: pointer; width:30px;height:30px;font-size:30px">expand_less</mat-icon>
  </div>
  <p style="color:white; position:relative; top: -15px; right: 10px; margin-bottom: 0; font-size: 18px"><span><mat-icon style="height: 40px; width: 40px; font-size: 40px; margin-right: 15px; position: relative; top: 14px;">dvr</mat-icon></span>Please select installations to view on the chart below:</p>
  <div style="overflow: auto">
  <div class="install-selections" *ngFor="let install of installationChecks; let i = index">
    <div *ngIf="i == 0">
    <label for={{install.name}}>{{install.name}}</label>
    <input type="checkbox" name={{install.name}} [checked]="install.checked == true" (change)="installChecked(install, $event.target.checked)" />
    </div>
    <div *ngIf="0 < i">
      <label for={{install.name}}>{{install.name}}</label>
      <input type="checkbox" name={{install.name}} [checked]="install.checked == true ? true : false" (change)="installChecked(install, $event.target.checked)" />
    </div>
  </div>
</div>
</div>
<div style="text-align: right;" class="no-bar" *ngIf="showBar == false">
<button (click)="toggleInstallSelect('true')" class="btn btn-primary">Show Install Select</button>
</div>
<div class="content-card">
<h2>Metrics <span id="dashDate">{{todayDate | date:'fullDate'}}</span></h2>
<div class="form-group row">
  <div class="col-sm-6">
  <label for="selectChartType">Report Type</label>
  <select class="form-control" id="selectChartType" 
  #typeSelect
  (change)="typeChanged($event.currentTarget.selectedOptions[0].title, typeSelect.value)" [(ngModel)]="selectedChartType">
    <!-- <option title="Per Minute Concurrency" value = "PerMinute" >Per Minute Concurrency</option> -->
    <option title="Per Minute Metrics" value = "PerMinute" >Per Minute Metrics</option>
    <!-- <option title="Hourly Peak Concurrency" value = "HourlyPeak" >Hourly Peak Concurrency</option> -->
    <option title="Hourly Metrics" value = "HourlyPeak" >Hourly Metrics</option>
    <!-- <option title="Extended Hourly" value = "ExtendedHourly" >Extended Hourly</option> -->
    <!-- <option title="Daily Metrics" value = "DailyMetrics" >Daily Metrics</option>
    <option title="Monthly Metrics" value = "MonthlyMetrics" >Monthly Metrics</option> -->
  </select>
  </div>
  <div class="col-sm-6">
  <label for="subcharts">Available Reports</label>
<select   #subcharts *ngIf="selectedChartType == 'PerMinute'"  (change)="subtypechanged()" class="form-control"  [(ngModel)]="selectedSubchart" id="subcharts">
  <option selected value="ActiveMeetings">Active Meetings</option>
  <option value="TotalUsers">Total Concurrent Users</option>
  <option value="MeetingUsers">Meeting Users</option>
  <option value="AuthUserogins">Authenticated User Logins</option>
  <option value="GuestUserAccess">Guest User Access</option>
  <option value="OnDemandUsers">On-Demand Users</option>
  <option value="TrainingCount">Training Quota</option>
  <option value="OnDemandInter">On-Demand Interactions</option>
</select>
  <select   #subcharts *ngIf="selectedChartType == 'HourlyPeak'" (change)="subtypechanged()" class="form-control"  [(ngModel)]="selectedSubchart" id="subcharts">
    <option selected value="PeakMeeting">Peak Meeting user and largest single meeting</option>
    <option value="PeakOnDemand">Peak On-Demand Users</option>
    <option value="PeakTraining">Peak Training Users</option>
    <option value="PeakTotalUsers">Peak Total Users</option>
  </select>
  <select   #subcharts *ngIf="selectedChartType == 'ExtendedHourly'"  (change)="subtypechanged()" class="form-control"  [(ngModel)]="selectedSubchart" id="subcharts">
    <option selected value="MaxConcurrentMeetingUsers">Max Concurrent Meeting Users</option>
    <option value="MaxConcurrentUsers">Max Concurrent Users</option>
    <option value="MaxConDemandUsers">Max Concurrent On-Demand Users</option>
    <option value="LargestMeeting">Largest Meeting</option>
    <option value="MaxGuestMinute">Max Guest Accesses/Minute</option>
    <option value="MaxLoginsMinute">Max Logins/Minute</option>
  </select>
  <select   #subcharts *ngIf="selectedChartType == 'DailyMetrics' || selectedChartType == 'MonthlyMetrics'"  (change)="subtypechanged()" class="form-control"  [(ngModel)]="selectedSubchart" id="subcharts">
    <option selected value="TotalMeetings">Total Meetings</option>
    <option value="TotalMeetingMinutes">Total Meeting Minutes</option>
    <option value="AverageMeetingsLength">Average Meetings Length(minutes)</option>
    <option value="AverageMeetingSize">Average Meeting Size (Concurrent Users)</option>
    <option value="LargestMeetingSize">Largest Meeting Size (Concurrent Users)</option>
  </select>
  </div>
</div>
</div>
<div class="content-card">
<h2>{{chartTitle}}</h2>
<div class="row">
<div *ngIf="selectedChartType == 'PerMinute'" class="form-group col-sm-6">
  <label for="minutesBack">Report Time Ending At</label>
  <select   #minutesBackSelect   (change)="metUpdate()"
   class="form-control"  [(ngModel)]="selectedMinutesBack" id="minutesBack">
      <option value="0">now</option>
      <option value="15">15 minutes ago</option>
      <option value="30">30 minutes ago</option>
      <option value="45">45 minutes ago</option>
      <option value="60">1 hour ago</option>
      <option value="90">1 1/2 hour ago</option>
      <option value="120">2 hours ago</option>
      <option value="180">3 hours ago</option>
      <option value="240">4 hours ago</option>
      <option value="300">5 hours ago</option>
      <option value="360">6 hours ago</option>
      <option value="420">7 hours ago</option>
      <option value="480">8 hours ago</option>
      <option value="540">9 hours ago</option>
      <option value="600">10 hours ago</option>
      <option value="660">11 hours ago</option>
      <option value="720">12 hours ago</option>
      <option value="780">13 hours ago</option>
      <option value="840">14 hours ago</option>
      <option value="900">15 hours ago</option>
      <option value="960">16 hours ago</option>
      <option value="1020">17 hours ago</option>
      <option value="1080">18 hours ago</option>
      <option value="1140">19 hours ago</option>
      <option value="1200">20 hours ago</option>
      <option value="1260">21 hours ago</option>
      <option value="1320">22 hours ago</option>
      <option value="1380">23 hours ago</option>
      <option value="1440">24 hours ago</option>
      <option value="1680">28 hours ago</option>
      <option value="1920">32 hours ago</option>
      <option value="2160">36 hours ago</option>
      <option value="2400">40 hours ago</option>
      <option value="2640">44 hours ago</option>
      <option value="2880">2 days ago</option>
      <option value="3240">54 hours ago</option>
      <option value="3600">60 hours ago</option>
      <option value="3960">66 hours ago</option>
      <option value="4320">3 days ago</option>
      <option value="4680">78 hours ago</option>
      <option value="5040">84 hours ago</option>
      <option value="5400">90 hours ago</option>
      <option value="5760">4 days ago</option>
      <option value="7200">5 days ago</option>
      <option value="8640">6 days ago</option>
      <option value="10080">7 days ago</option>
      <option value="11520">8 days ago</option>
      <option value="12960">9 days ago</option>
      <option value="14400">10 days ago</option>
      <option value="15840">11 days ago</option>
      <option value="17280">12 days ago</option>
      <option value="18720">13 days ago</option>
      <option value="20160">14 days ago</option>
  </select>
</div>
<div *ngIf="selectedChartType == 'PerMinute'" class="form-group col-sm-6">
  <label for="minHourRange">Minute / Hourly Range</label>
  <select   #minHourlySelect   (change)="metUpdate()"
   class="form-control"  [(ngModel)]="minHourRange" id="minHourRange">
   <option value="15">15 min</option>
   <option value="30">30 min</option>
   <option value="45">45 min</option>
   <option value="60">1 hour ago</option>
   <option value="90">1 1/2 hour</option>
   <option value="120">2 hours</option>
   <option value="180">3 hours</option>
   <option value="240">4 hours</option>
   <option value="300">5 hours</option>
   <option value="360">6 hours</option>
   <option value="420">7 hours</option>
   <option value="480">8 hours</option>
   <option value="540">9 hours</option>
   <option value="600">10 hours</option>
   <option value="660">11 hours</option>
   <option value="720">12 hours</option>
   <option value="780">13 hours</option>
   <option value="840">14 hours</option>
   <option value="900">15 hours</option>
   <option value="960">16 hours</option>
   <option value="1020">17 hours</option>
   <option value="1080">18 hours</option>
   <option value="1140">19 hours</option>
   <option value="1200">20 hours</option>
   <option value="1260">21 hours</option>
   <option value="1320">22 hours</option>
   <option value="1380">23 hours</option>
   <option value="1440">24 hours</option>

   <option value="1680">28 hours ago</option>
   <option value="1920">32 hours ago</option>
    <option value="2160">36 hours ago</option>
    <option value="2400">40 hours ago</option>
    <option value="2640">44 hours ago</option>
    <option value="2880">2 days ago</option>
    <option value="3240">54 hours ago</option>
    <option value="3600">60 hours ago</option>
    <option value="3960">66 hours ago</option>
    <option value="4320">3 days ago</option>
    <option value="4680">78 hours ago</option>
    <option value="5040">84 hours ago</option>
    <option value="5400">90 hours ago</option>
    <option value="5760">4 days ago</option>
    <option value="7200">5 days ago</option>
    <option value="8640">6 days ago</option>
    <option value="10080">7 days ago</option>
    <option value="11520">8 days ago</option>
    <option value="12960">9 days ago</option>
    <option value="14400">10 days ago</option>
    <option value="15840">11 days ago</option>
    <option value="17280">12 days ago</option>
    <option value="18720">13 days ago</option>
    <option value="20160">14 days ago</option>
  </select>
</div>
</div>
<div *ngIf="selectedChartType == 'HourlyPeak'" class="form-group">
  <label for="selectTime">Range</label>
  <select   #weekSelect   (change)="metUpdate()"
   class="form-control"  [(ngModel)]="dailyTimeRange" id="selectTime">
    <option value="WEEK">Last Week</option>
    <option value="MONTH">Last Month</option>
    <option value="2MONTHS">Last 2 Months</option>
    <option value="3MONTHS">Last 3 Months</option>
    <option value="YEAR">Last Year</option>
  </select>
</div>
<!-- <div *ngIf="selectedChartType == 'ExtendedHourly'" class="form-group">
  <label for="selectTime">Range</label>
  <select   #weekSelect   (change)="metUpdate()"
   class="form-control"  [(ngModel)]="dailyTimeRange" id="selectTime">
    <option value="WEEK">Last Week</option>
    <option value="MONTH">Last Month</option>
    <option value="2MONTHS">Last 2 Months</option>
    <option value="3MONTHS">Last 3 Months</option>
    <option value="YEAR">Last Year</option>
  </select>
</div> -->
<!-- <div *ngIf="selectedChartType == 'DailyMetrics'" class="form-group">
  <label for="selectTime">Range</label>
  <select   #dailyWeekSelect   (change)="metUpdate()"
   class="form-control"  [(ngModel)]="dailyTimeRange" id="selectTime">
    <option value="WEEK">Last Week</option>
    <option value="MONTH">Last Month</option>
    <option value="2MONTHS">Last 2 Months</option>
    <option value="3MONTHS">Last 3 Months</option>
    <option value="YEAR">Last Year</option>
  </select>
</div> -->
<div *ngIf="metricsChartReady == true" style="display: block; height:50vh;">
  <canvas baseChart id="metricsChart"
    [datasets]="metricsChartData"
    [labels]="metricsChartLabels"
    [options]="metricsChartOptions"
    [legend]="metricsChartLegend"
    [chartType]="metricsChartType"
    [plugins]="metricsChartPlugins"
    [colors]="metricsChartColors"
    >
  </canvas>
</div>
<hr />
<div class="row">
  <div class="col-4">
    <button class="btn btn-outline-secondary" (click)="download()"><span style="position:relative; top:5px;"><mat-icon> local_printshop</mat-icon></span> Print Chart</button>
  </div>
  <div class="col-4">
    <button class="btn btn-outline-secondary" (click)="downloadpdf()"><span style="position:relative; top:5px;"><mat-icon>picture_as_pdf</mat-icon></span> Print PDF Chart</button>
  </div>
  <div class="col-4">
    <button class="btn btn-outline-secondary" (click)="downloadcsv()"><span style="position:relative; top:5px;"><mat-icon>arrow_downward</mat-icon></span> Download CSV</button>
  </div>
</div>
</div>
<ngb-toast class="alert alert-danger" id="missingToast" *ngIf="missingDataToast">
  <p>Some install data was not found.</p>
</ngb-toast>

