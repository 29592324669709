import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/services/alert.service.service';
import { ApiCallsService } from 'src/app/shared/services/api-calls.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  public userFromParam: any = undefined
  errorBool = undefined;
  errorMsg;
  installSpinner = true;

  constructor(
    private router: Router, 
    private apiCallsService:ApiCallsService,  
    private utilsService:UtilsService, 
    private modalService: NgbModal,
    private alertService: AlertService,
    private route: ActivatedRoute
  ) { }

  baseURLSend = window.location.origin
  seshkeyraw = sessionStorage.getItem("sessionKey");
  seshKey = JSON.parse(this.seshkeyraw);
  userFullList
  getUserData : any;

  async ngOnInit(): Promise<any> {
    this.userFromParam = this.route.snapshot.paramMap.get('id');
    if (this.baseURLSend ===  environment.frontendBaseURL) {
  this.baseURLSend = environment.baseUrl; 
  }
  this.getUser()
  // var userResponse = await this.getUser()
  }

  getUser = async() => {
    var data = {
      "api": "authentication.administratorGetUsers",
      "session-key": this.seshKey,
      "user_id": this.userFromParam
    };
    var response = await this.apiCallsService.sendApi(data);
    this.getUserData = response[0]
    return response
  }

  EditUser = async () => {
    var data = {
      "api": "administration.administratorEditUser",
      "session-key": this.seshKey,
      "newUser": {
        "lastName": this.getUserData.lastname,
        "firstName": this.getUserData.firstname,
        "email": this.getUserData.email,
        "user_id": this.getUserData.id

    }
    };
    
    try {
      var response = await this.apiCallsService.sendApi(data);
      if(response.status === 400){
        this.installSpinner = true;
        this.errorBool = true;
        this.alertService.showAlert(
          this.errorMsg = response.message,
          'error'
          );
      }
      else {
        this.router.navigate(['/admin']);
      }
      // this.alertService.showAlert('Update Successful Installation has been updated.', 'success');
      // this.router.navigate(['/admin/install']);
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error(error);
    }
  }

}
