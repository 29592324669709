import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import {Router} from '@angular/router';
import { UtilsService } from '../shared/services/utils.service';
import {NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';

@Component({
  selector: 'app-license-report',
  templateUrl: './license-report.component.html',
  styleUrls: ['./license-report.component.scss']
})
export class LicenseReportComponent implements OnInit {

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private router: Router, private apiCallsService:ApiCallsService, private utilsService:UtilsService) { }

  installList
  connectIDSelected = false
  serverList
  renderDrop
  peakIndex
  dataPeak
  endDisplay
  rangeDisplay
  datePeak:any
  licenseSelectedServer
  licenseSelectedInstall = "Please Select Connect ID"
  selectedMonitor = false
  stopTime:any = ""
  startTime
  licenseYes = false;
  selectedType = "by-hour"
  graphLoaded
  maxDate: NgbDateStruct
  selectedTimeRange = "4"
  selectedDayRange = "3"
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio:true,
    aspectRatio:3.5,
    elements: 
    { 
        point: 
        {
            radius: 0,
            hitRadius: 5,
            hoverRadius: 1,
            hoverBorderWidth: 2
        }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        ticks:{
          display: true,
          autoSkip: true,
          beginAtZero: false,
          labelOffset: 0,
          maxTicksLimit: 16,
          maxRotation: 30,
          minRotation: 0
        },
        type: 'time',
        distribution: 'series',
        time: {  	
          tooltipFormat:'MMM D Y h:mm a',
                  displayFormats: {
                    minute:'dddd LT',
                    hour: 'MMM D h:mm a',
                      day: 'MMM D Y'
                  }
              },
              gridLines: {
                borderDash: [8, 4],
                  color: "#808080"
                     }
          }],
      yAxes: [
        {
          id: 1,
          position: 'left',
          ticks: {
                beginAtZero: true
            }
        }
      ]
    },
  };
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(148,159,177,0)',
      borderColor: 'rgba(0,166,217,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {
      backgroundColor: 'rgba(77,83,96,0)',
      borderColor: 'rgba(214, 69, 65, 1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];


  async ngOnInit(): Promise<any> {
    this.stopTime = this.calendar.getToday()
    this.installList = JSON.parse(sessionStorage.getItem('installs'))
    this.renderDrop = true
    this.maxDate = this.calendar.getToday()
  }

  maxPeak = (arr) => {
    if (arr.length === 0) {
      return -1;
  }

  var max = arr[0];
  var maxIndex = 0;

  for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
          maxIndex = i;
          max = arr[i];
      }
  }

  return maxIndex;
  }

  renderMonitorGraph = async(param) => {
    var chartArr = this.utilsService.chartDataTransform(param.data)
    var chartData = chartArr[0]
    this.peakIndex = this.maxPeak(chartArr[0][0].data)
    var chartLabels = chartArr[1]
    this.dataPeak = chartData[0].data[this.peakIndex]
    this.datePeak = chartLabels[this.peakIndex]
    this.datePeak = new Date(this.datePeak)
    this.lineChartData = chartData
    this.lineChartLabels = chartLabels
    this.graphLoaded = true
  }
  
  selectConnectIDApi = async() => {
    if (this.licenseSelectedInstall !== "Please Select Connect ID") {
    var data = { 
      'domain': 'data-lake',
      'api': 'get-connect-license-model',
      'properties': {
        'connect-id': this.licenseSelectedInstall
      }
    }
    var response = await this.apiCallsService.sendApi(data)
  } else {
    this.connectIDSelected = false
  }
  if (response.data.model == "concurrent-meeting-user") {
  this.licenseYes = true
  this.callGraphAPI()
  }
  else {
    this.licenseYes = false;
    this.graphLoaded = false;
    alert("This connect ID is not configured for Licenses.")
  }
  }
  getinstallList = async() => {
    var data = { 
      'domain': 'data-lake',
      'api': 'ccListAccounts',
    }
    var response = await this.apiCallsService.sendApi(data)
    return response
  }
  callGraphAPI = async() => {
    var endDate = new Date();
    endDate.setMonth(this.stopTime.month - 1)
    endDate.setDate(this.stopTime.day)
    endDate.setFullYear(this.stopTime.year)
    var startdate = new Date(endDate)
    if (this.selectedType == "by-hour") {
    startdate.setHours(endDate.getHours() - (parseInt(this.selectedTimeRange) + 2));
    }
    if (this.selectedType == "by-day") {
      startdate.setDate(endDate.getDate() - (parseInt(this.selectedDayRange) + 2));
    }
    this.endDisplay = endDate
    this.rangeDisplay = startdate
    var data = { 
      'domain': 'data-lake',
      'api': 'get-connect-quota-report',
      'properties': {
        'connect-id': this.licenseSelectedInstall,
        'start-time': startdate.getTime(),
        'type': this.selectedType
      }
    }
    if (this.stopTime !== "") {
      data.properties['end-time'] = endDate.getTime()
    }
    var response = await this.apiCallsService.sendApi(data)
    this.renderMonitorGraph(response)
  }

}
