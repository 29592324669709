import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import { UtilsService } from '../shared/services/utils.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../shared/services/alert.service.service';
import { utils, writeFile } from 'xlsx';


@Component({
  selector: 'app-sso-logs',
  templateUrl: './sso-logs.component.html',
  styleUrls: ['./sso-logs.component.scss']
})
export class SSOLogsComponent implements OnInit {
  http: any;
  exportData: any[];

  renderSso = false
  renderNewSso = true
  sessionKeys: any;
  

  constructor(private router: Router,
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
    private modalService: NgbModal,
    private alertService: AlertService) { }
  

  ngOnInit(): void {
  }

  async downloadCSV(){
    const sessionKeys = JSON.parse(sessionStorage.getItem("sessionKeys"));
      const data = {
        "api": "sumologic.sumologicResponse",
        "session-key": sessionKeys
      };
      var response = await this.apiCallsService.sendApi(data);
      this.renderNewSso = true;
      // if(response.lenght > 0){
      const headings = [[
        'owner',
        'blockid',
        'loglevel',
        'messagetime',
        'raw',
        'collectorid',
        'sourceid',
        'collector',
        'messagecount',
        'sourcehost',
        'messageid',
        'sourcename',
        'size',
        'environment',
        'application',
        'view',
        'receipttime',
        'sourcecategory',
        'format',
        'source',
        'region',
        'tenant',
      ]];
      const wb = utils.book_new();
      const ws: any = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, response, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, 'SSO.xlsx');
      // } 
      // else{
      //   alert("no data found")
      // } 
  }
  
}

