import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import {Router} from '@angular/router';
import { UtilsService } from '../shared/services/utils.service';
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  providers:[CcJVMComponent],
  selector: 'app-cc-jvm',
  templateUrl: './cc-jvm.component.html',
  styleUrls: ['./cc-jvm.component.scss']
})
export class CcJVMComponent implements OnInit {

  constructor(private apiCallsService:ApiCallsService, private utilsService:UtilsService) { }

  renderJvm = false
  public firstChartData: ChartDataSets[] = [];
  public firstChartLabels: Label[] = [];
  public secondChartData: ChartDataSets[] = [];
  public secondChartLabels: Label[] = [];
  public thirdChartData: ChartDataSets[] = [];
  public thirdChartLabels: Label[] = [];
  public fourthChartData: ChartDataSets[] = [];
  public fourthChartLabels: Label[] = [];
  public firstChartOptions = {
    responsive: true,
    maintainAspectRatio:true,
    aspectRatio:3.5,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        ticks:{
          display: true,
          autoSkip: true,
          beginAtZero: false,
          labelOffset: 0,
          maxTicksLimit: 15,
          maxRotation: 30,
          minRotation: 0
        },
        type: 'time',
        distribution: 'series',
        time: {  	
          tooltipFormat:'MMM D Y h:mm a',
                  displayFormats: {
                    minute:'MMM D h:mm a',
                    hour: "dddd LT",
                      day: 'MMM D Y'
                  }
              },
              gridLines: {
                borderDash: [8, 4],
                  color: "#808080"
                     }
          }],
      yAxes: [
      ]
    },
  };
  public firstChartLegend = true;
  public firstChartType = 'line';
  public firstChartPlugins = [];

  public secondChartOptions = {
    responsive: true,
    maintainAspectRatio:true,
    aspectRatio:3.5,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        ticks:{
          display: true,
          autoSkip: true,
          beginAtZero: false,
          labelOffset: 0,
          maxTicksLimit: 15,
          maxRotation: 30,
          minRotation: 0
        },
        type: 'time',
        distribution: 'series',
        time: {  	
          tooltipFormat:'MMM D Y h:mm a',
                  displayFormats: {
                    minute:'MMM D h:mm a',
                    hour: "dddd LT",
                      day: 'MMM D Y'
                  }
              },
              gridLines: {
                borderDash: [8, 4],
                  color: "#808080"
                     }
          }],
      yAxes: [
      ]
    },
  };
  public secondChartLegend = true;
  public secondChartType = 'line';
  public secondChartPlugins = [];

  public thirdChartOptions = {
    responsive: true,
    maintainAspectRatio:true,
    aspectRatio:3.5,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        ticks:{
          display: true,
          autoSkip: true,
          beginAtZero: false,
          labelOffset: 0,
          maxTicksLimit: 15,
          maxRotation: 30,
          minRotation: 0
        },
        type: 'time',
        distribution: 'series',
        time: {  	
          tooltipFormat:'MMM D Y h:mm a',
                  displayFormats: {
                    minute:'MMM D h:mm a',
                    hour: "dddd LT",
                      day: 'MMM D Y'
                  }
              },
              gridLines: {
                borderDash: [8, 4],
                  color: "#808080"
                     }
          }],
      yAxes: [
      ]
    },
  };
  public thirdChartLegend = true;
  public thirdChartType = 'line';
  public thirdChartPlugins = [];

  public fourthChartOptions = {
    responsive: true,
    maintainAspectRatio:true,
    aspectRatio:3.5,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        ticks:{
          display: true,
          autoSkip: true,
          beginAtZero: false,
          labelOffset: 0,
          maxTicksLimit: 15,
          maxRotation: 30,
          minRotation: 0
        },
        type: 'time',
        distribution: 'series',
        time: {  	
          tooltipFormat:'MMM D Y h:mm a',
                  displayFormats: {
                    minute:'MMM D h:mm a',
                    hour: "dddd LT",
                      day: 'MMM D Y'
                  }
              },
              gridLines: {
                borderDash: [8, 4],
                  color: "#808080"
                     }
          }],
      yAxes: [
      ]
    },
  };
  public fourthChartLegend = true;
  public fourthChartType = 'line';
  public fourthChartPlugins = [];

  ngOnInit(): void {
  }

  getJvmChart = async(param) => {
    var data = { 
      'domain': 'data-lake',
      'api': 'get-server-jmx-reports',
      'properties': {
        'server-key': param,
        'start-time': -15,
        'end-time': 0
      } 
    }
    var response = await this.apiCallsService.sendApi(data)
    this.renderJvmGraph(response.data)
    return response
  }
  renderJvmGraph = (param) => {
    this.renderJvm = false
      for (var key in param) {
        switch (key) {
          case "gc-5-minute":
            this.firstChartData = param[key].chartDataSets
            this.firstChartLabels = param[key].lineChartLabels
            for (var i = 0; i < param[key].yAxisCount; i++) {
              this.firstChartOptions.scales.yAxes.push(param[key].yAxes[i])
            }
            break;
          case "gc-60-minute":
            this.secondChartData = param[key].chartDataSets
            this.secondChartLabels = param[key].lineChartLabels
            for (var i = 0; i < param[key].yAxisCount; i++) {
              this.secondChartOptions.scales.yAxes.push(param[key].yAxes[i])
            }
            break;
          case "jvm-mem":
            this.thirdChartData = param[key].chartDataSets
            this.thirdChartLabels = param[key].lineChartLabels
            for (var i = 0; i < param[key].yAxisCount; i++) {
              this.thirdChartOptions.scales.yAxes.push(param[key].yAxes[i])
            }
            break;
          case "jvm-threads":
            this.fourthChartData = param[key].chartDataSets
            this.fourthChartLabels = param[key].lineChartLabels
            for (var i = 0; i < param[key].yAxisCount; i++) {
              this.fourthChartOptions.scales.yAxes.push(param[key].yAxes[i])
            }
            break;
          default:
            return 
          }
      }
      this.renderJvm = true
  }
}
