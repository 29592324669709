<div class="content-card">
<div class="row">
    <div class="col-sm-2">
    <label for="connectID">Select Install</label>
<select *ngIf="renderDrop == true" class="form-control" id="type" (change)="selectConnectIDApi()" [(ngModel)]="licenseSelectedInstall" name="connectID">
    <option selected>Please Select Connect ID</option>
    <option *ngFor="let install of installList; let i = index" [ngValue]="install['connect-id']">
      {{install.name}}
    </option>
  </select>
  </div>
  <div *ngIf="licenseYes" class=" col-sm-2">
    <label for="type">Range</label>
    <select [(ngModel)]="selectedType" class="form-control"  (change)="callGraphAPI()"  name="type" id="typeDrop">
        <option value="by-hour">By Hour</option>
        <option value="by-day">By Day</option>
    </select>
</div>
<div *ngIf="licenseYes" class="col-sm-2">
    <label for="endDate">End Date</label>
    <form class="form-inline">
        <div style="width: 100%" class="form-group">
          <div class="input-group">
            <input class="form-control" placeholder="yyyy-mm-dd"
                   name="endDate" [maxDate]="maxDate" [(ngModel)]="stopTime" (ngModelChange)="callGraphAPI()" ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </form>
</div>
<div *ngIf="licenseYes && selectedType == 'by-hour'" class="col-sm-2">
    <label for="range">Range</label>
    <select [(ngModel)]="selectedTimeRange" class="form-control"  (change)="callGraphAPI()"  name="range" id="rangeDrop">
        <option value="4">4 Hours</option>
        <option value="8">8 Hours</option>
        <option value="12">12 Hours</option>
        <option value="16">16 Hours</option>
        <option value="20">20 Hours</option>
        <option value="24">24 Hours</option>
    </select>
</div>
<div *ngIf="licenseYes && selectedType == 'by-day'" class="col-sm-2">
    <label for="range">Range</label>
    <select [(ngModel)]="selectedDayRange" class="form-control"  (change)="callGraphAPI()"  name="range" id="rangeDrop">
        <option value="3">3 Days</option>
        <option value="7">7 Days</option>
        <option value="14">14 Days</option>
        <option value="30">30 Days</option>
        <option value="60">60 Days</option>
    </select>
</div>
<div class=" col-sm-4" *ngIf="licenseYes">
    <p style="color:#00a6d9; font-weight:400;">Concurrent Meeting Users</p>
    <p>{{rangeDisplay.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}} <b> to </b> {{endDisplay.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}}</p>
    <p><b>Peak:</b> {{datePeak.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}} <span style="color:#00a6d9; font-weight:400;">{{dataPeak}}</span> concurrent users</p>
</div>
</div>
<hr class="col-sm-12" *ngIf="licenseYes" />
<div class="row">
    <div *ngIf="graphLoaded == true" class="col-sm-12 form-group">
        <canvas id="performance-graph" baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
        [plugins]="lineChartPlugins">
      </canvas>
      </div>
</div>
<div class="row">
    <p *ngIf="licenseYes" class="text-center col-sm-12"><b>Time Range:</b> Last <span *ngIf="licenseYes && selectedType == 'by-day'">{{selectedDayRange}} Days</span><span *ngIf="licenseYes && selectedType == 'by-hour'">{{selectedTimeRange}} Hours</span></p>
</div>
</div>
