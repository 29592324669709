import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import {Router} from '@angular/router';
import { UtilsService } from '../shared/services/utils.service';
import {NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbTimepickerConfig, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, BaseChartDirective } from 'ng2-charts';
import { NgbTime } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  selector: 'app-custom-monitor',
  templateUrl: './custom-monitor.component.html',
  styleUrls: ['./custom-monitor.component.scss']
})
export class CustomMonitorComponent implements OnInit {

  constructor(public config: NgbTimepickerConfig, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private router: Router, private apiCallsService:ApiCallsService, private utilsService:UtilsService) { }

  customAccountsJson = JSON.parse(localStorage.getItem("customMonitors"))
  customAccountsArr = []
  testArr = []
  showTests
  connectID
  testID
  time
  endDate
  showChart = false
  dateDisabled = true
  dateToggle = false
  dayToggle = false
  selectedTimeRange = "30"
  selectedStartDate
  startTime
  maxDate
  selectedIndex
  fullEndDate
  fullStartDate
  customMonitorInterval

  public scatterChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio:true,
    aspectRatio:3.5,
    animation: {
      duration: 0
  },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        ticks:{
          display: true,
          autoSkip: true,
          beginAtZero: false,
          labelOffset: 0,
          maxTicksLimit: 15,
          maxRotation: 30,
          minRotation: 0
        },
        type: 'time',
        distribution: 'series',
        time: {  	
          tooltipFormat:'MMM D Y h:mm a',
                  displayFormats: {
                    minute:'MMM D h:mm a',
                    hour: "dddd LT",
                      day: 'MMM D Y'
                  }
              },
              gridLines: {
                borderDash: [8, 4],
                  color: "#808080"
                     }
          }],
      yAxes: [
      ]
    },
  };
  public scatterChartLabels: Label[] = [];

  public scatterChartData: ChartDataSets[] = [];
  public scatterChartType: ChartType = 'scatter';
  public scatterChartColors: Color[] = [];

  ngOnInit(): void {
    var initialKey = []
    for (var key in this.customAccountsJson) {
      initialKey.push(key)
      var newObj = {
        "account": key,
        "name": this.customAccountsJson[key]
      }
      this.customAccountsArr.push(newObj)
    }
    var today = new Date()
    this.endDate = this.calendar.getToday()
    this.time = {hour: today.getHours(), minute: today.getMinutes(), second: 0}
    this.selectedStartDate = this.calendar.getToday()
    this.startTime = {hour: today.getHours(), minute: today.getMinutes(), second: 0}
    this.maxDate = this.calendar.getToday()
    this.config.seconds = false;
    this.config.spinners = false;
    this.getCustomMonitor(initialKey[0], 0)
    this.customMonitorInterval = setInterval(() => { if (this.dateDisabled == true) { this.getMonitorData() }}, 20000);
  }

  ngOnDestroy() {
    clearInterval(this.customMonitorInterval);
  }

  sendTime = async() => {
    setTimeout(()=>{ this.getMonitorData() }, 100);
  }

  toggleTimeRange = () => {
    if (this.dayToggle == false) {
      this.selectedTimeRange = "30"
    } else {
      var ngbDate = NgbDate.from(this.endDate)
      this.selectedStartDate = this.calendar.getPrev(ngbDate, "d", 1)
    }
    this.getMonitorData()
  }

  toggleDate = () => {
    if (this.dateToggle == false) {
      this.setTimeNow()
      this.dateDisabled = true
    } else {
      this.dateDisabled = false
    }
  }

  setTimeNow = () => {
    var today = new Date()
    this.time = {hour: today.getHours(), minute: today.getMinutes(), second: 0}
    this.endDate = this.calendar.getToday()
    this.getMonitorData()
  }

  getCustomMonitor = async(param, index) => {
    this.selectedIndex = index
    this.connectID = param
    var data = {
      "domain":"data-lake",
      "api":"list-available-custom-monitors",
      "properties": {
        "connect-id":param,
      }
    };
    var response = await this.apiCallsService.sendApi(data)
    this.testArr = response.data
    this.showTests = true
    this.testID = this.testArr[0]['test-id']
    this.getMonitorData()
  }

  getMonitorData = async() => {
    var tempEndDate = new Date()
var tempStartDate = new Date()
var range = parseInt(this.selectedTimeRange)
var target
tempEndDate.setMonth(this.endDate.month - 1)
tempEndDate.setDate(this.endDate.day)
tempEndDate.setFullYear(this.endDate.year)

if (this.time !== undefined) {
  tempEndDate.setHours(this.time.hour)
  tempEndDate.setMinutes(this.time.minute + 1)
  tempEndDate.setSeconds(this.time.second)
}
this.fullEndDate = tempEndDate;
if (this.dayToggle == true) {
  tempStartDate.setMonth(this.selectedStartDate.month - 1)
  tempStartDate.setDate(this.selectedStartDate.day)
  tempStartDate.setFullYear(this.selectedStartDate.year)
  if (this.startTime !== undefined) {
    tempStartDate.setHours(this.startTime.hour)
    tempStartDate.setMinutes(this.startTime.minute + 1)
    tempStartDate.setSeconds(this.startTime.second)
  }
  this.fullStartDate = tempStartDate
  if (this.fullStartDate.getTime() == this.fullEndDate.getTime()) {
    this.fullStartDate.setDate(this.fullEndDate.getDate() - 1);
    this.startTime.minute = this.time.minute
    this.startTime.hour = this.fullEndDate.getHours();
    var ngbDate = NgbDate.from(this.endDate)
    this.selectedStartDate = this.calendar.getPrev(ngbDate, "d", 1)
  } else {
    tempStartDate.setMinutes(this.startTime.minute)
  }
} else {
  this.fullStartDate = new Date(tempEndDate);
  this.fullStartDate.setMinutes(this.fullStartDate.getMinutes() - range);
}

if (this.fullStartDate.getTime() >= this.fullEndDate.getTime()) {
  let today = new Date()
  alert("You cannot set the start time to be after the end time")
  this.startTime = {hour: this.fullEndDate.getHours(), minute: this.fullEndDate.getMinutes(), second: 0}
  this.fullStartDate = new Date(this.fullEndDate)
  this.fullStartDate.setMinutes(this.fullEndDate.getMinutes() - 15)
  this.startTime.minute = this.fullStartDate.getMinutes();
  if (this.fullEndDate.getMinutes() < 15) {
    this.startTime.hour = this.fullEndDate.getHours() - 1
  }
  var ngbDate = NgbDate.from(this.endDate)
  this.selectedStartDate = this.calendar.getPrev(ngbDate, "d", 0.0104166666666667)
  this.selectedStartDate.month = this.fullEndDate.getMonth() + 1
  this.selectedStartDate.day = this.fullEndDate.getDate()
  this.selectedStartDate.year = this.fullEndDate.getFullYear()
}
    var data = {
      "domain":"data-lake",
      "api":"get-custom-monitor-data",
      "properties": {
        "connect-id":this.connectID,
        "test-id": this.testID,
        "start-time": this.fullStartDate.getTime(),
        "end-time": this.fullEndDate.getTime()
      }
    };
    var response = await this.apiCallsService.sendApi(data)
    this.renderChart(response.data)
  }

  renderChart = (param) => {
    this.scatterChartColors = []
    for (var i = 0; i < param.colors.length; i++) {
      var color = param.colors[i]
      this.scatterChartColors.push({
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: color,
        pointBackgroundColor: color,
        pointBorderColor: color,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color
      })
    }
    this.scatterChartData = param.datasets
    this.showChart = true
  }

}
