<div class="content-card">
<div class="row">
    <div class="col-sm-12">
        <h2>Disable Inactive Users</h2>
    </div>
    <div class="col-sm-12">
        <div>
            <p>On this page you can download a report of inactive users as well as disable those users. Please follow the steps below to disable inactive users.</p>
            <ul class="stepsList">
                <li><span class="stepColor">Step 1:</span> <span *ngIf="multiInstall == true"> Select an install and</span><span *ngIf="multiInstall == false"> Select a</span> time range from the dropdown<span *ngIf="multiInstall == true">s</span> and click the download button to get a report of the inactive users within that time range</li>
                <li><span class="stepColor">Step 2:</span> Click the Disable Users tab and enter the report key of the inactive user report you downloaded into the text box</li>
                <li><span class="stepColor">Step 3:</span> Click the Disable Users button and "Yes" on the confirmation prompt. This will disable the inactive users.</li>
            </ul>
        </div>
        <!-- <button (click)="showInstructionsClick()" *ngIf="showInstructions == true" class="btn btn-info instButton">Hide Instructions</button>
        <button (click)="showInstructionsClick()" *ngIf="showInstructions == false" class="btn btn-info instButton">Show Instructions</button> -->
        <hr />
    </div>
    <mat-tab-group id="disableTabs" [selectedIndex]="indexVal" class="col-sm-12">
        <mat-tab class="row" label="Run Inactive User Report">
        <div class="col-sm-12 form-group">
            <div class="row">
                <div *ngIf="multiInstall == true" class="col-sm-3">
                    <label for="connectID">Select Install</label>
                    <select class="form-control" id="type" [(ngModel)]="disableSelectedInstall" name="connectID">
                        <option selected>Please Select an Install</option>
                        <option *ngFor="let install of installList; let i = index" [ngValue]="install['connect-id']">
                        {{install.name}}
                        </option>
                    </select>
                </div>
                <div class="col-sm-3">
                    <label for="days">Select Time Range</label>
                    <select class="form-control" [(ngModel)]="daysValue" name="days">
                        <option value="30">30 Days</option>
                        <option value="60">60 Days</option>
                        <option value="90" selected>90 Days</option>
                    </select>
                </div>
                <div class="col-sm-3">
                    <button id="downloadBtn" (click)="downloadDisableReport()" class="btn btn-primary">Download</button>
                </div>
            </div>
            <div class="row">
                <div style="margin-top: 20px;" *ngIf="inactiveResponseCode == 0" class="alert alert-warning col-sm-5 text-center" role="alert">
                    There are no inactive users within this timerange.
                </div>
            </div>
        </div>
        </mat-tab>
        <mat-tab class="row" label="Disable Users">
        <div class="col-sm-12 form-group">
            <div class="row">
                <div class="col-sm-5">
                    <p>Enter the report key for the report containing the users you'd like to disable</p>
                    <input class="form-control" type="text" [(ngModel)]="reportKey" placeholder="Report Key" />
                </div>
                <div class="col-sm-3">
                    <button id="disableBtn" (click)="disableCall()" class="btn btn-danger">Disable Users</button>
                </div>
            </div>
            <div class="row">
                    <div *ngIf="showSuccess == 0" class="alert alert-primary col-sm-5" role="alert">
                        The {{userCountResponse}} users from the report have been successfully disabled!
                    </div>
                    <div *ngIf="showSuccess == 3" class="alert alert-warning col-sm-5" role="alert">
                        This report has already been run or does not exist, this key is invalid.
                    </div>
                    <div *ngIf="showSuccess == 1" class="alert alert-danger col-sm-5" role="alert">
                        There was an issue with the download key or API. No users have been disabled.
                    </div>
            </div>
        </div>
        </mat-tab>
    </mat-tab-group>
</div>
</div>