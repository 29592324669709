import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import {Router} from '@angular/router';
import { Subject } from 'rxjs';
import { newArray } from '@angular/compiler/src/util';

@Component({
  selector: 'app-meeting-tables',
  templateUrl: './meeting-tables.component.html',
  styleUrls: ['./meeting-tables.component.scss']
})
export class MeetingTablesComponent implements OnInit {

  constructor(private router: Router, private apiCallsService:ApiCallsService) { }
  selectedInstall:string;
  controlNuggets = JSON.parse(sessionStorage.getItem("installs"));
  nuggetHTML: string;
  meetingTableHeads
  meetingTableName
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  meetingTableRows
  activeNugget
  meetingTableID
  meetingTableSize
  meetingPage
  meetingPageSize
  userTableHeads
  userTableName
  userTableRows
  userTableID
  userTableSize
  meetingCSV =[]
  userCSV =[]
  activeMeetingRow
  killMeetingEntitlement = false
  userPage
  userPageSize
  controls: any[] = []
  controlsSpinner = true;
  meetingTable = undefined
  userTable = undefined
  async ngOnInit(): Promise<any> {
    
    this.controls = await this.createControls(this.controlNuggets);
    this.controlsSpinner = false;
    var entitlementsDataboard = JSON.parse(sessionStorage.getItem("entitlements"))
    if (entitlementsDataboard["kill-meeting"] === true) {
      this.killMeetingEntitlement = true
    }
    this.selectInstallControl(this.controls[0]['connect-id'], this.controls[0].name)
    

  }

  createControls = async (controlsParam) => {
    var controlArr = [];
    for (var i = 0; i < controlsParam.length; i++) {
        var controlResponse = await this.fetchControlSummary(controlsParam[i]["connect-id"]);
        this.controlNuggets[i].response = controlResponse.data;
        controlArr.push(this.controlNuggets[i])
    }
    return controlArr
}

fetchControlSummary = async (connectIDParam) =>{
  var data = {
      "domain":"data-lake",
      "api":"currentDataPerConnect",
      "properties": {
          "connect-id": connectIDParam,
      }
  };
  var response = await this.apiCallsService.sendApi(data)
  return response 
}

 selectInstallControl = (install, name) => {
  this.meetingTable = undefined
  this.activeNugget = name;
  this.selectedInstall = install
  var selectedName = name
  this.fetchMeetings(this.selectedInstall, selectedName)
}

fetchMeetings = async (installParam, nameParam) => {
  var data = {
      "domain":"data-lake",
      "api":"activeMeetingsReport",
      "properties": {
          "connect-id": installParam,
      }
  };
  var response = await this.apiCallsService.sendApi(data)
  if (response.status == "FAILED_INVALID") {
      alert("The Connect instance does not have proper data. Try another Server")
      return
  }
  this.renderMeetings(response.data.data["query-results"], installParam, nameParam)
}

renderMeetings = (tableParam, installID, installName) => {
  this.userTable = undefined
  this.meetingCSV = []
  this.meetingTableName = installName
  this.meetingTableID = installID
  this.meetingTableHeads = tableParam.headings
  this.meetingTableRows = tableParam.rows
  if (tableParam.rows.length !== 0) {
  for (var m = 0; m < tableParam.rows[0].length; m++) {
    this.meetingCSV[m] = []
  }
  for (var m = 0; m < tableParam.rows.length; m++) {
    for (var l = 0; l < tableParam.rows[m].length; l++) {
      this.meetingCSV[l].push(tableParam.rows[m][l])
    }
  }
  for (var p = 0; p < this.meetingCSV[5].length; p++) {
    this.meetingCSV[4][p] = new Date(this.meetingCSV[4][p])
    this.meetingCSV[4][p] = this.meetingCSV[4][p].toLocaleString()
  }
  this.meetingCSV.unshift(tableParam.headings)
  }
  for (var i = 0; i < this.meetingTableRows.length; i++) {
    this.meetingTableRows[i][4] = new Date(this.meetingTableRows[i][4])
  }
  this.meetingTable = true
}

killMeeting = (param) => {
  this.sendKillRequest(param)
}

sendKillRequest = async(killParam) => {
  var data = {
      "domain":"data-lake",
      "api":"killMeeting",
      "properties": {
          "connect-id": this.meetingTableID,
          "sco-id": killParam,
          "double-tap": true
      }
  };
  var response = await this.apiCallsService.sendApi(data)
  this.fetchMeetings(this.meetingTableID, this.meetingTableName)
  this.createControls(this.controlNuggets);
}

downloadrptcsv = () => {
  var csvNameDate:any = new Date()  
  csvNameDate = csvNameDate.toDateString()
  var data = this.meetingCSV
  var csv = data[0].toString()+"%0D%0A";
  for(var c=0; c<data[1].length;c++){
      for(var i=1; i<data.length;i++){
          data[i][c] = data[i][c].toString();
          data[i][c] = data[i][c].replace(' ', '%20')
          data[i][c] = data[i][c].replace('#', '%20')
          data[i][c] = data[i][c].replace(',', '%20')
          csv += data[i][c]+","
      }
      csv+="%0D%0A";
  }

  var link = document.createElement('a');
  link.href = "data:application/octet-stream,field1%2Cfield2%0Afoo%2Cbar%0Agoo%2Cgai%0A";
  link.href = "data:application/octet-stream,"+csv;
  link.download = 'activeMeetings '+csvNameDate+'.csv';
  link.click();
}

downloadusercsv = () => {
  var csvNameDate:any = new Date()  
  csvNameDate = csvNameDate.toDateString()
  var data = this.userCSV
  var csv = data[0].toString()+"%0D%0A";
  for(var c=0; c<data[1].length;c++){
      for(var i=1; i<data.length;i++){
          data[i][c] = data[i][c].toString();
          data[i][c] = data[i][c].replace(' ', '%20')
          data[i][c] = data[i][c].replace('#', '%20')
          data[i][c] = data[i][c].replace(',', '%20')
          csv += data[i][c]+","
      }
      csv+="%0D%0A";
  }

  var link = document.createElement('a');
  link.href = "data:application/octet-stream,field1%2Cfield2%0Afoo%2Cbar%0Agoo%2Cgai%0A";
  link.href = "data:application/octet-stream,"+csv;
  link.download = 'meetingUsers '+csvNameDate+'.csv';
  link.click();
}

renderUserTable = async (scoParam, assetParam, installParam, meetingName) => {
  this.activeMeetingRow = scoParam
  var data = {
    "domain":"data-lake",
    "api":"meetingUsersReport",
    "properties": {
        "connect-id": installParam,
        "cacheable-sco-id": scoParam,
        "cacheable-asset-id": assetParam
    }
};
var response = await this.apiCallsService.sendApi(data)
this.renderMeetingUsers(response.data.data["query-results"], meetingName)
}
renderMeetingUsers = (userTableParam, meetingName) => {
  this.userTable = true
  this.userCSV = []
  this.userTableName = meetingName
  this.userTableHeads = userTableParam.headings
  this.userTableRows = userTableParam.rows
  if (userTableParam.rows.length !== 0) {
    for (var m = 0; m < userTableParam.rows[0].length; m++) {
      this.userCSV[m] = []
    }
    for (var m = 0; m < userTableParam.rows.length; m++) {
      for (var l = 0; l < userTableParam.rows[m].length; l++) {
        this.userCSV[l].push(userTableParam.rows[m][l])
      }
    }
    for (var p = 0; p < this.userCSV[5].length; p++) {
      this.userCSV[4][p] = new Date(this.userCSV[4][p])
      this.userCSV[4][p] = this.userCSV[4][p].toLocaleString()
    }
    this.userCSV.unshift(userTableParam.headings)
    }
  for (var i = 0; i < this.userTableRows.length; i++) {
    if (this.userTableRows[i][4] === "") {
      this.userTableRows[i][4] = "Open"
    } else {
      this.userTableRows[i][4] = new Date(this.userTableRows[i][4])
    }
  }
  for (var i = 0; i < this.userTableRows.length; i++) {
    this.userTableRows[i][12] = new Date(this.userTableRows[i][12])
  }
  this.userPage = 1
  this.userPageSize = 10
  this.userTableSize = this.userTableRows.length
}


}
