import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { ApiCallsService } from '../shared/services/api-calls.service';

@Component({
  selector: 'app-cc-recent-meetings',
  templateUrl: './cc-recent-meetings.component.html',
  styleUrls: ['./cc-recent-meetings.component.scss']
})
export class CcRecentMeetingsComponent implements OnInit {

  @Output()
  recentClicked: EventEmitter<any> = new EventEmitter<any>();
  connectID

  constructor() { }

  ngOnInit(): void {
    this.connectID = JSON.parse(sessionStorage.getItem("ccConnectID"))
    
  }

  getRecentMeetings = async() => {
    var data = {
      "domain":"data-lake",
      "api":"recent-meetings-report",
      "properties": {
        "connect-id": this.connectID,
        "start-date":'hi',
        "end-date": 'hi',
        "min-parts": 'hi'
      }
    };
  }

  recentButton(sco, asset) {
    this.recentClicked.emit([sco, asset])
  }

}
