import { Component, OnInit, ViewChild } from '@angular/core';
import { async } from '@angular/core/testing';
import { ApiCallsService } from '../shared/services/api-calls.service';
import {Router} from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbTimepickerConfig, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import { Color, Label } from 'ng2-charts';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-cc-list-account',
  templateUrl: './cc-list-account.component.html',
  styleUrls: ['./cc-list-account.component.scss']
})


export class CcListAccountComponent implements OnInit {
  @ViewChild('tabs', {static: false}) tabGroup: MatTabGroup;


  // Logs Vars
  configModel:any
  serviceModel:any
  initialLoad
  logInterval
  intervalSet
  settingsData
  meetingInterval
  logModel: any
  reportModel:any
  agentDaysBack:any = '0'
  debugDaysBack:any = '0'
  teleDaysBack:any = '0'
  acMinutes:any = '1'
  teleMinutes:any = '1'
  reportSco = ''
  stateModel
  serverName
  serviceSuccess
  serviceError
  serviceWait
  sqlSuccess
  sqlWait
  sqlError
  stateWait
  stateSuccess
  stateError
  //

  // time vars
  selectedStartDate
  selectedTimeRange = '10'
  startTime
  endDate
  time
  maxDate
  fullEndDate
  dayToggle
  fullStartDate
  meetingStartTime
  meetingEndTime

  primaryServerMeeting
  backupServerMeeting
  coreWait = false
  settingsArr = []
  listResponse
  accountName
  dataCenterCheckArr = []
  tableDisplay = false
  loadingTable
  settingsEditing = false
  logsServerTable = false
  accountDetails = undefined
  loginTable = false
  accountServers = undefined
  showAttendeeTable = false
  datacenterArr
  meetingDetailGraph
  initialAccount = true
  meetingsCheck = false;
  currentAsset
  failuresCheck = false;
  meetingTab = false
  indexVal = 0;
  showLogControls = false
  meetingData
  killSco
  newServers = false
  statusData
  logsDanger
  backupEditSelect
  logsServerData
  dataCenterControls
  logsWait = false
  logsSuccess = false
  datacenterCheck
  logsServerChoice
  meetingsTable = false
  selectedConnectId
  meetingLoaded = false
  sqlExecute
  accountSQLName
  dataCenterEditSelect
  sqlErrorCode
  accountInterval
  accountDisabledSuccess
  meetingDetailTable
  meetingDetailResp
  connectID
  showListAccounts = true
  loginData
  loadingAccount = false
  meetBtnVar = "Graph"
  disableAll = false
  sessionData
  accountTable = false;
  public barChartData: ChartDataSets[] = [];
  public barChartLabels: Label[] = [];
  public barChartOptions = {
    responsive: true,
    maintainAspectRatio:true,
    aspectRatio:3.5,
    elements: 
    { 
        point: 
        {
            radius: 0,
            hitRadius: 5,
            hoverRadius: 1,
            hoverBorderWidth: 2
        }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        ticks:{
          display: true,
          autoSkip: true,
          beginAtZero: false,
          labelOffset: 0,
          maxTicksLimit: 16,
          maxRotation: 30,
          minRotation: 0
        },
        type: 'time',
        distribution: 'series',
        time: {  	
          tooltipFormat:'MMM D Y h:mm a',
                  displayFormats: {
                    minute:'dddd LT',
                    hour: 'MMM D h:mm a',
                      day: 'MMM D Y'
                  }
              },
              gridLines: {
                borderDash: [8, 4],
                  color: "#808080"
                     }
          }],
      yAxes: [
        {
          id: 1,
          position: 'left',
          ticks: {
                beginAtZero: true
            }
        }
      ]
    },
  };
  public barChartLegend = true;
  public barChartType = 'bar';
  public barChartPlugins = [];
  public barChartColors: Color[] = [
    {
      backgroundColor: 'rgba(148,159,177,0)',
      borderColor: 'rgba(0,166,217,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {
      backgroundColor: 'rgba(77,83,96,0)',
      borderColor: 'rgba(214, 69, 65, 1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];

  public meetingChartData: ChartDataSets[] = [];
  public meetingChartLabels: Label[] = [];
  public meetingChartOptions = {
    responsive: true,
    maintainAspectRatio:true,
    aspectRatio:3.5,
    elements: 
    { 
        point: 
        {
            radius: 0,
            hitRadius: 5,
            hoverRadius: 1,
            hoverBorderWidth: 2
        }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        ticks:{
          display: true,
          autoSkip: true,
          beginAtZero: false,
          labelOffset: 0,
          maxTicksLimit: 16,
          maxRotation: 30,
          minRotation: 0
        },
        type: 'time',
        distribution: 'series',
        time: {  	
          tooltipFormat:'MMM D Y h:mm a',
                  displayFormats: {
                    minute:'dddd LT',
                    hour: 'MMM D h:mm a',
                      day: 'MMM D Y'
                  }
              },
              gridLines: {
                borderDash: [8, 4],
                  color: "#808080"
                     }
          }],
      yAxes: [
        {
          id: 1,
          position: 'left',
          ticks: {
                beginAtZero: true
            }
        }
      ]
    },
  };
  public meetingChartLegend = true;
  public meetingChartType = 'line';
  public meetingChartPlugins = [];
  public meetingChartColors: Color[] = [
    {
      backgroundColor: 'rgba(148,159,177,0)',
      borderColor: 'rgba(0,166,217,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {
      backgroundColor: 'rgba(77,83,96,0)',
      borderColor: 'rgba(214, 69, 65, 1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];

  constructor(public config: NgbTimepickerConfig, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private router: Router, private apiCallsService:ApiCallsService) { }

  async ngOnInit(): Promise<any> {
  this.initialLoad = true
   this.listResponse = await this.getInitialList()
   for (var i = 0; i < this.datacenterArr.length; i++) {
    this.dataCenterCheckArr.push(this.datacenterArr[i])
   }
   this.initialLoad = false
   this.tableDisplay = true
   this.dataCenterControls = true
   var today = new Date()
   this.endDate = this.calendar.getToday()
   this.time = {hour: today.getHours(), minute: today.getMinutes(), second: 0}
   this.selectedStartDate = this.calendar.getToday()
   this.startTime = {hour: today.getHours(), minute: today.getMinutes(), second: 0}
   this.maxDate = this.calendar.getToday()
   this.config.seconds = false;
   this.config.spinners = false;
  }

  ngOnDestroy() {
      clearInterval(this.intervalSet);
      clearInterval(this.logInterval)
      clearInterval(this.meetingInterval)
      clearInterval(this.accountInterval)
  }

  clearDataFilters = () => {
    const nodeItems = Array.from(document.getElementsByClassName('datacheck'));

    for (var i = 0; i < nodeItems.length; i++) {
      nodeItems[i].setAttribute('checked', 'true')  ;
    }
    this.dataCenterCheckArr = []
    this.refreshList()
  }

  refreshList = async() => {
    this.tableDisplay = false
    this.loadingAccount = false
    this.accountTable = false
    this.loadingTable = true;
    this.listResponse = await this.getInitialList()
    this.tableDisplay = true
    this.loadingTable = false;
  }

  changeMeetingView = () => {
    if (this.showAttendeeTable == false) {
      this.showAttendeeTable = true
      this.meetBtnVar = "Graph"
    } else {
      this.showAttendeeTable = false
      this.meetBtnVar = "Table"
    }
  }

  sleep = async(ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  showMeeting = async(sco, asset) => {
    clearInterval(this.meetingInterval)
    var limit = 0
    var fmsLimit = 0
    var meetingData = [{data:[], label:'Meeting Attendees'}]
    this.killSco = sco
    this.currentAsset = asset
    var meetingLabels = []
    this.meetingTab = true
    this.meetingLoaded = false;
    this.showAttendeeTable = false
    this.indexVal = 3;
    var data = {
      "domain":"data-lake",
      "api":"ccMeetingDetailView",
      "properties": {
        "connect-id": this.connectID,
        "cacheable-sco-id": sco,
        "cacheable-asset-id": asset
      }
    };
    do {
      this.meetingDetailResp = await this.apiCallsService.sendApi(data)
      await this.sleep(500)
      limit++
    } 
    while (this.meetingDetailResp.status == "FAILED_RETRIABLE" && limit < 10)
    this.meetingDetailTable = this.meetingDetailResp.data.attendees
    this.meetingDetailGraph = this.meetingDetailResp.data.population
    for (var i = 0; i < this.meetingDetailGraph.length; i++) {
      meetingData[0].data.push(this.meetingDetailGraph[i][1])
      meetingLabels.push (this.meetingDetailGraph[i][0])
    }
    this.primaryServerMeeting = this.meetingDetailResp.data.primary
    this.backupServerMeeting = this.meetingDetailResp.data.backup
    this.meetingStartTime = this.meetingDetailResp.data.start
    this.meetingEndTime = this.meetingDetailResp.data.end
    this.meetingChartData = meetingData
    this.meetingChartLabels = meetingLabels
    this.meetingLoaded = true;
    this.showAttendeeTable = true
    this.meetingInterval = setInterval(() => { if (this.indexVal == 3) {this.meetingUpdate(this.killSco, this.currentAsset)} }, 60000);
  }

  connectLogin = async() => {
    var data = {
      "domain":"data-lake",
      "api":"ccSupportLogin",
      "properties": {
        "connect-id": this.connectID,
      }
    };
    var loginResp = await this.apiCallsService.sendApi(data)
    if (loginResp.data) {
      var downloadURL = loginResp.data.login
      window.open(downloadURL)
    } else {
      alert("Login failed: " + loginResp['status-detail'])
    }
  }

  meetingUpdate = async(sco, asset) => {
    var meetingData = [{data:[], label:'Meeting Attendees'}]
    var limit = 0
    this.killSco = sco
    this.currentAsset = asset
    var meetingLabels = []
    this.meetingTab = true
    this.indexVal = 3;
    var data = {
      "domain":"data-lake",
      "api":"ccMeetingDetailView",
      "properties": {
        "connect-id": this.connectID,
        "cacheable-sco-id": sco,
        "cacheable-asset-id": asset
      }
    };
    do {
      this.meetingDetailResp = await this.apiCallsService.sendApi(data)
      await this.sleep(500)
      limit++
    } 
    while (this.meetingDetailResp.status == "FAILED_RETRIABLE" && limit < 10)
    this.meetingDetailTable = this.meetingDetailResp.data.attendees
    this.meetingDetailGraph = this.meetingDetailResp.data.population
    for (var i = 0; i < this.meetingDetailGraph.length; i++) {
      meetingData[0].data.push(this.meetingDetailGraph[i][1])
      meetingLabels.push (this.meetingDetailGraph[i][0])
    }
    this.meetingChartData = meetingData
    this.meetingChartLabels = meetingLabels
    this.meetingStartTime = this.meetingDetailResp.data.start
    this.meetingEndTime = this.meetingDetailResp.data.end
    this.meetingLoaded = true;
  }

  newServer = async(connectId, connectName) => {
    this.initialAccount = false
    this.loadingAccount = true
    this.meetingTab = false
    this.logsServerTable = false
    this.meetingsTable = false
    this.loginTable = false
    this.accountName = connectName
    this.showLogControls = false
    this.connectID = connectId
    var resp = await this.settingsTab(connectId)
    this.showListAccounts = false;
    this.loadingAccount = false
    this.accountTable = true;
    this.newServers = true 
    return true
  }

  getAccountDetails = async(connectId, connectName, servers) => {
    this.disableAll = false
    this.newServers = false
    if (servers == 0) {
    var newResp = await this.newServer(connectId, connectName)
    return
    }
    clearInterval(this.accountInterval)
    clearInterval(this.intervalSet);
    clearInterval(this.logInterval)
    clearInterval(this.meetingInterval)
    sessionStorage.setItem('ccConnectID',JSON.stringify(connectId))
    var limit = 0
    this.initialAccount = false
    this.loadingAccount = true
    this.meetingTab = false
    this.logsServerTable = false
    this.meetingsTable = false
    this.loginTable = false
    this.accountName = connectName
    this.showLogControls = false
    this.indexVal = 0
    var data = {
      "domain":"data-lake",
      "api":"ccActiveMeetings",
      "properties": {
        "connect-id": connectId
      }
    };
    this.accountSQLName = connectName
    do {
      var meetingsResp = await this.apiCallsService.sendApi(data)
      await this.sleep(500)
      limit++
    } 
    while (meetingsResp.status == "FAILED_RETRIABLE" && limit < 10)

    var data2 = {
      "domain":"data-lake",
      "api":"ccAccountStatus",
      "properties": {
        "connect-id": connectId
      }
    };
    var statusResp = this.apiCallsService.sendApi(data2)

    var data3 = {
      "domain":"data-lake",
      "api":"recentLoginReport",
      "properties": {
        "connect-id": connectId
      }
    };
    var loginResp = this.apiCallsService.sendApi(data3)

    var data4 = {
      "domain":"data-lake",
      "api":"ccAccountSessions",
      "properties": {
        "connect-id": connectId
      }
    };
    var sessionsResp = this.apiCallsService.sendApi(data4)
    this.getAccountInfo(connectId)
    this.logsTab(connectId)
    this.settingsTab(connectId)

    var promise:any = await Promise.all([meetingsResp, sessionsResp, loginResp, statusResp])
    this.loginData = promise[2].data.data['query-results']
      for (var i = 0; i < this.loginData.rows.length; i++) {
        this.loginData.rows[i][0] = new Date(this.loginData.rows[i][0]).toLocaleString();
      }
      this.meetingData = promise[0].data
      this.sessionData = promise[1].data
      this.statusData = promise[3].data
      this.barChartData = this.sessionData.yAxes[0].dataSeries
      this.barChartLabels = this.sessionData.xAxis.labels
      this.accountTable = true;
      this.meetingsTable = true
      this.loginTable = true
      this.showListAccounts = false;
      this.loadingAccount = false

    this.intervalSet = setInterval(() => { if (this.indexVal == 0) {this.getAccountDetailsUpdate(connectId)} }, 13000);
    this.logInterval = setInterval(() => { if (this.indexVal == 2) {this.logsTab(this.connectID)} }, 30000);
    this.accountInterval = setInterval(() => { if (this.indexVal == 1) {this.getAccountInfo(this.connectID)} }, 60000);
  }

  getAccountDetailsUpdate = async(connectId) => {
    var limit = 0
    this.meetingsTable = false;
    this.loginTable = false;
    var data = {
      "domain":"data-lake",
      "api":"ccActiveMeetings",
      "properties": {
        "connect-id": connectId
      }
    };
    do {
      var meetingsResp = await this.apiCallsService.sendApi(data)
      await this.sleep(500)
      limit++
    } 
    while (meetingsResp.status == "FAILED_RETRIABLE" && limit < 10)
    this.meetingData = meetingsResp.data

    var data2 = {
      "domain":"data-lake",
      "api":"ccAccountStatus",
      "properties": {
        "connect-id": connectId
      }
    };
    var statusResp = await this.apiCallsService.sendApi(data2)
    this.statusData = statusResp.data

    var data3 = {
      "domain":"data-lake",
      "api":"recentLoginReport",
      "properties": {
        "connect-id": connectId
      }
    };
    var loginResp = await this.apiCallsService.sendApi(data3)
    for (var i = 0; i < loginResp.data.data['query-results'].rows.length; i++) {
      loginResp.data.data['query-results'].rows[i][0] = new Date(loginResp.data.data['query-results'].rows[i][0]).toLocaleString();
    }
    this.loginData = loginResp.data.data['query-results']

    var data4 = {
      "domain":"data-lake",
      "api":"ccAccountSessions",
      "properties": {
        "connect-id": connectId
      }
    };
    var sessionsResp = await this.apiCallsService.sendApi(data4)
    this.sessionData = sessionsResp.data
    this.barChartData = this.sessionData.yAxes[0].dataSeries
    this.barChartLabels = this.sessionData.xAxis.labels
    this.accountTable = true;
    this.meetingsTable = true
    this.loginTable = true
  }

  tabIndexValue = (param) => {
    this.indexVal = param
    switch(param) {
      case 0:
        this.getAccountDetailsUpdate(this.connectID)
      break;
      case 1:
        this.getAccountInfo(this.connectID)
      break;
      case 2:
        this.logsTab(this.connectID)
      break;
      case 3:
        this.showMeeting(this.killSco, this.currentAsset)
      break;
      case 4:
        this.settingsTab(this.connectID)
      break;
      default:
        // code block
    }
  } 

  settingsTab = async(connectId) => {
    this.settingsArr = []
    var data = {
      "domain":"data-lake",
      "api":"ccGetAccountDetails",
      "properties": {
        "connect-id": connectId
      }
    };
    var sessionsResp = await this.apiCallsService.sendApi(data)
    this.settingsData = sessionsResp.data
    this.settingsArr = this.settingsData.fields
    if (sessionsResp.data.fields.backup_server_instance_id) {
    this.backupEditSelect = sessionsResp.data.fields.backup_server_instance_id.value
    }
    if (sessionsResp.data.fields.datacenter_key) {
    this.dataCenterEditSelect = sessionsResp.data.fields.datacenter_key.value
    }
    return sessionsResp
  }

  editAccount = async() => {
    var data = {
      "domain":"data-lake",
      "api":"ccSetAccountDetails",
      "properties": {
          "connect-id": this.connectID,
      }
  };
    var settingElements:any = document.getElementsByClassName('editingInputs')
    for (var i = 0; i < settingElements.length; i++) {
      if (settingElements[i].value !== '') {
        if (settingElements[i].value == "true") {
          settingElements[i].value = true
        } else if (settingElements[i].value == 'false') {
          settingElements[i].value = false
        }
        data.properties[settingElements[i].name] = settingElements[i].value
      }
    }

    data.properties['Backup Server ID'] = this.backupEditSelect
    var response = await this.apiCallsService.sendApi(data)
    this.settingsTab(this.connectID)
  }

  disableAccount = async() => {

    var data = {
      "domain":"data-lake",
      "api":"ccDisableAccount",
      "properties": {
          "connect-id": this.connectID,
      }
  };
  if (window.confirm("Really disable this account?")) {
  var response = await this.apiCallsService.sendApi(data)
  this.accountDisabledSuccess = true
  this.showListAccounts = true; 
  this.accountTable = false
  setTimeout(()=>{ this.accountDisabledSuccess = false }, 5000);
  } else {
    return
  }
  }
  
  killMeeting = async() => {
    var data = {
        "domain":"data-lake",
        "api":"killMeeting",
        "properties": {
            "connect-id": this.connectID,
            "sco-id": this.killSco,
            "double-tap": true
        }
    };
    if (window.confirm("Really end this meeting?")) {
    var response = await this.apiCallsService.sendApi(data)
    this.indexVal = 0;
    this.meetingTab = false
    this.meetingLoaded = false;
    this.showAttendeeTable = false
    this.getAccountDetailsUpdate(this.connectID)
    } else {
      return
    }
  }

  submitSQL = async() => {
    this.sqlWait = true
    var data = {
      "domain":"data-lake",
      "api":"control-center-read-only-sql-query",
      "properties": {
        "connect-id": this.connectID,
        "sql": this.sqlExecute,
        "account-name": this.accountSQLName
      }
    };
    var response = await this.apiCallsService.sendApi(data)
    if (response == undefined) {
      this.sqlWait = false
      this.sqlSuccess = true
      setTimeout(()=>{ this.sqlSuccess = false }, 5000);
    } else {
      this.sqlWait = false
      this.sqlError = true
      this.sqlErrorCode = response.data.result
      setTimeout(()=>{ this.sqlError = false }, 5000);
    }
  }

  getLogs = async(logType) => {
    var data:any
    
    if (logType == 'application') {
     data = {
      "domain":"data-lake",
      "api":"getLogs",
      "properties": {
        "connect-id": this.primaryServerMeeting,
        "log-type": logType,
        "sco-id": this.meetingDetailResp.data.sco
      }
    };
  } else if (logType == 'analysis') {
     data = {
      "domain":"data-lake",
      "api":"meeting-session-analysis",
      "properties": {
        "connect-id": this.primaryServerMeeting,
        "log-type": logType,
        "sco-id": this.meetingDetailResp.data.sco,
        "asset-id": this.meetingDetailResp.data.asset
      }
  }
} else if (logType == 'meeting') {
  var startDate = new Date(this.meetingDetailResp.data.start)
  var endDate
  if (this.meetingDetailResp.data.end == '') {
    endDate = new Date()
  } else {
    endDate = new Date(this.meetingDetailResp.data.end)
  }
  data = {
   "domain":"data-lake",
   "api":"getLogs",
   "properties": {
     "connect-id": this.primaryServerMeeting,
     "log-type": logType,
     "sco-id": this.meetingDetailResp.data.sco,
     "asset-id": this.currentAsset
   }
}
}
  var logResp = await this.apiCallsService.sendApi(data)
  if (logResp.status == "SERVER_EXCEPTION") {
    window.alert('Unknown Primary Host')
  } else {
  this.downloadRedirect(logResp.data.data['download-key'])
  }
  }

  resetFMSCore = async() => {
    this.coreWait = true
    var fmsLimit = 0
    var startFMS = new Date(this.meetingDetailResp.data.start)
    var data2 = {
      "domain":"data-lake",
      "api":"getFmsCores",
      "properties": {
        "connect-id": this.connectID,
        "sco-id": this.killSco,
        "begin": startFMS.getTime()
      }
    }; 
    do {
      var fmsResponse = await this.apiCallsService.sendApi(data2)
      await this.sleep(500)
      fmsLimit++
    } 
    while (fmsResponse.status == "INTERIM_STATUS" && fmsLimit < 10)
    var aggKey = fmsResponse.data.data['response-aggregation-key']
    var data3: {[k: string]: any} = {
      "domain":"data-lake",
      "api":"ccGetFMSCoreResponse",
      "properties": {
        "response-aggregation-key": aggKey,
        "begin": this.meetingStartTime
      }
    }; 
    if (this.meetingEndTime !== '') {
      data3.properties.end = this.meetingEndTime
    }
    var coreResponse = await this.apiCallsService.sendApi(data3)
    this.primaryServerMeeting = coreResponse.data[0]
    if (coreResponse.data.length > 1) {
      this.backupServerMeeting = coreResponse.data[1]
    } else {
      this.backupServerMeeting = ''
    }
    this.coreWait = false
  }

  logsTab = async(connectId) => {
    var serverResp
    var data = {
      "domain":"data-lake",
      "api":"list-connect-install-servers",
      "properties": {
        "connect-id": connectId,
      }
    };
      serverResp = await this.apiCallsService.sendApi(data)
      this.logsServerData = serverResp.data['server-list']
      this.logsServerTable = true
  }

  getLogsForServer = async(name, key, status) => {
    this.showLogControls = true
    this.logsServerChoice = key
    this.serverName = name
    var serverStat = status
    if (serverStat == 'in-rotation') {
      this.stateModel = 'into-rotation' 
    } else {
      this.stateModel = 'out-of-rotation'
    }
  }

  recentEventHandlerFunction(valueEmitted) {

  }


searchEventHandlerFunction(valueEmitted){
  var sco = valueEmitted[0]
  var asset = valueEmitted[1]
  this.showMeeting(sco, asset)
}

  downloadRedirect = (param) => {
    var baseURL = this.apiCallsService.urlUtil();
    var downloadURL = baseURL + "/download/" + param
    window.open(downloadURL)
  }

  getAccountInfo = async(connectId) => {
    var limit = 0
    var data = {
      "domain":"data-lake",
      "api":"ccAccountDetailView",
      "properties": {
        "connect-id": connectId
      }
    };
    this.connectID = connectId
    do {
      var detailsResp = await this.apiCallsService.sendApi(data)
      await this.sleep(500)
      limit++
    } 
    while (detailsResp.status == "FAILED_RETRIABLE" && limit < 10)
    this.accountDetails = detailsResp.data.account
    this.accountServers = detailsResp.data.account.servers
  }

  onCheckboxChange = (e) => {

    if (e.target.checked) {
      this.dataCenterCheckArr.push(e.target.value);
    } else {
      for (var i = 0; i < this.dataCenterCheckArr.length; i++) {
        if (this.dataCenterCheckArr[i] == e.target.value) {
          const index = this.dataCenterCheckArr.indexOf(e.target.value);
          if (index > -1) {
            this.dataCenterCheckArr.splice(index, 1);
          }
        }
      }
      };
      this.refreshList()
    }

  getInitialList = async() => {
    var data = {
      "domain":"data-lake",
      "api":"ccListAccounts",
      "properties": {
      }
    };
    var accountArr = []
    var response = await this.apiCallsService.sendApi(data)
    this.datacenterArr = []
    var tempDatacenterArr = response.data
    for (var i = 0; i < tempDatacenterArr.length; i++) {
      if (this.datacenterArr.indexOf(tempDatacenterArr[i].datacenter_key) === -1) {
        if (tempDatacenterArr[i].datacenter_key == null) {
          continue
        } else {
          this.datacenterArr.push(tempDatacenterArr[i].datacenter_key)
        }
      }
    }
    if (this.meetingsCheck == true && this.failuresCheck !== true) {
      for (var i = 0; i < response.data.length; i++) {
        if (response.data[i].meetingCount && response.data[i].meetingCount !== 0) {
          accountArr.push(response.data[i])
        }
      }
    } else if (this.failuresCheck == true && this.meetingsCheck !== true) {
      for (var i = 0; i < response.data.length; i++) {
        if (response.data[i].failM !== '') {
          accountArr.push(response.data[i])
        }
      }
    } else if (this.failuresCheck == true && this.meetingsCheck == true) {
      for (var i = 0; i < response.data.length; i++) {
        if (response.data[i].failM !== '' && response.data[i].meetingCount && response.data[i].meetingCount !== 0) {
          accountArr.push(response.data[i])
        }
      }
    }
    else {
      accountArr = response.data
    }

    if (Array.isArray(this.dataCenterCheckArr) == true && this.dataCenterCheckArr.length !== 0) {
      var tempFullArr = []
      for (var i = 0; i < this.dataCenterCheckArr.length; i++) {
        for (var p = 0; p < accountArr.length; p++) {
          if (this.dataCenterCheckArr[i] == accountArr[p]['datacenter_key']) {
            tempFullArr.push(accountArr[p])
          }
        }
      }
      accountArr = tempFullArr
    }

    return accountArr
  }

  // Log Functions

  downloadLog = async(param) => {
    var logType
    var data:any
    if (param !== 'services') {
      this.logsWait = true
    }
    if (param == 'logs') {
      logType = this.logModel[0]
      for (var i = 0; i < this.logModel.length; i++) {
      if (this.logModel.length !== 1) {
        if (this.logModel[1] == 'daysBack') {
          var daysBack = 0
          if (this.logModel[0] == 'agent') {
            daysBack = parseInt(this.agentDaysBack)
            data = {
              "domain":"data-lake",
              "api":"getLogs",
              "properties": {
                "connect-id": this.logsServerChoice,
                "log-type": logType,
                "days-ago": daysBack
              } 
            }
          } else {
          var tempEndDate = new Date()
          var range:any = this.selectedTimeRange
          tempEndDate.setMonth(this.selectedStartDate.month - 1)
          tempEndDate.setDate(this.selectedStartDate.day)
          tempEndDate.setFullYear(this.selectedStartDate.year)
          
          if (this.startTime !== undefined) {
            tempEndDate.setHours(this.startTime.hour)
            tempEndDate.setMinutes(this.startTime.minute)
            tempEndDate.setSeconds(this.startTime.second)
          }
          this.fullEndDate = tempEndDate;
            this.fullStartDate = new Date(tempEndDate);
            this.fullStartDate.setHours(this.fullStartDate.getHours() + range.hour)
            this.fullStartDate.setMinutes(this.fullStartDate.getMinutes() + range.minute);
          
           data = {
            "domain":"data-lake",
            "api":"getLogs",
            "properties": {
              "connect-id": this.logsServerChoice,
              "log-type": logType,
              "start-time":this.fullEndDate,
              "end-time": this.fullStartDate
            } 
          }
        }
        } else if (this.logModel[1] == 'minutes') {
          var minutes = "0"
          if (this.logModel[0] == 'debug') {
            minutes = this.acMinutes
          } else if (this.logModel[0] == 'telephony') {
            minutes = this.teleMinutes
          }
          data = {
            "domain":"data-lake",
            "api":"getLogs",
            "properties": {
              "connect-id": this.logsServerChoice,
              "log-type": logType,
              "past-minutes": minutes
            }
          }
      } else if (logType == 'gc') {
        if (this.logModel[1] == true) {
          data = {
            "domain":"data-lake",
            "api":"getLogs",
            "properties": {
              "connect-id": this.logsServerChoice,
              "log-type": logType, 
              "archive": true
            }
          }
        } else {
        data = {
          "domain":"data-lake",
          "api":"getLogs",
          "properties": {
            "connect-id": this.logsServerChoice,
            "log-type": logType,
            "archive": false
          }
        }
      }
      }
    }
    else {
      data = {
        "domain":"data-lake",
        "api":"getLogs",
        "properties": {
          "connect-id": this.logsServerChoice,
          "log-type": logType 
        }
      }
    }
  }
}  else if (param == 'config') {
  if (this.configModel == 'agent-config') {
    data = {
      "domain":"data-lake",
      "api":"getLogs",
      "properties": {
        "connect-id": this.logsServerChoice,
        "log-type": this.configModel 
      }
    }
  } else if (this.configModel == 'configDB') {
    data = {
      "domain":"data-lake",
      "api":"get-db-config",
      "properties": {
        "connect-id": this.logsServerChoice,
        "log-type": this.configModel 
      }
    }
  } else if (this.configModel == 'configArch') {
    data = {
      "domain":"data-lake",
      "api":"getConfigArchive",
      "properties": {
        "connect-id": this.logsServerChoice,
        "log-type": this.configModel 
      }
    }
  }
} else if (param == 'services') {
  data = {
    "domain":"data-lake",
    "api":"serviceOps",
    "properties": {
      "connect-id": this.logsServerChoice,
      "service": this.serviceModel[0],
      "operation": this.serviceModel[1]
    }
  }
} else if (param == 'reports') {
    data = {
      "domain":"data-lake",
      "api":"sco-version-scan-report",
      "properties": {
        "connect-id": this.logsServerChoice
      }
    }
  }
 else if (param == 'scoFolder') {
    if (this.reportSco == '') { alert("Please enter a sco"); return}
    data = {
      "domain":"data-lake",
      "api":"get-sco-folder",
      "properties": {
        "connect-id": this.logsServerChoice,
        "sco-id" : this.reportSco
      }
    }
}
if (param == 'services') {
  this.serviceWait = true
}

  var response = await this.apiCallsService.sendApi(data)
  if (param == 'logs' || param == 'config' || param == 'report' || param == 'scoFolder') {
    this.logsWait = false
    if (response.data.result !== 'code-exception') {
    this.logsSuccess = true
    setTimeout(()=>{ this.logsSuccess = false }, 5000);
    } else if  (response.data.result == 'code-exception') {
      this.logsDanger = true
      setTimeout(()=>{ this.logsDanger = false }, 5000);
    }
    this.downloadRedirect(response.data.data['download-key'])
  }
  if (param == 'services') {
    if (response.data.result == 'success') {
      this.serviceWait = false
      this.serviceSuccess = true
      setTimeout(()=>{ this.serviceSuccess = false }, 5000);
    } else {
      this.serviceWait = false
      this.serviceError = true
      setTimeout(()=>{ this.serviceError = false }, 5000);
    }
  }
  }

  changeServerState = async() => {
    this.stateWait = true
    var data = {
      "domain":"data-lake",
      "api":"setConnectServerState",
      "properties": {
        "connect-id": this.logsServerChoice,
        "new-state" : this.stateModel,
        "server-name": this.serverName
      }
    }
    var response = await this.apiCallsService.sendApi(data)
    if (response.data.result == 'success') {
      this.stateWait = false;
      this.stateSuccess = true
      setTimeout(()=>{ this.stateSuccess = false }, 5000);
    } else {
      this.stateWait = false;
      this.stateError = true
      setTimeout(()=>{ this.stateError = false }, 5000);
    }
    this.logsTab(this.connectID)
  }

  getServerProcess = async() => {
    var data = {
      "domain":"data-lake",
      "api":"server-process-list",
      "properties": {
        "connect-id": this.logsServerChoice,
      }
    }
    this.logsWait = true
    var response = await this.apiCallsService.sendApi(data)
    this.logsWait = false
    this.logsSuccess = true
    setTimeout(()=>{ this.logsSuccess = false }, 5000);
    this.downloadRedirect(response.data.data['download-key'])
  }
  

  //

}
