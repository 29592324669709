<div class="content-card">
<p>Please select installations</p>
  <div style="overflow: auto">
  <div class="install-selections" *ngFor="let install of installationChecks">
    <label for={{install.name}}>{{install.name}}</label>
    <input type="checkbox" name={{install.name}} [checked]="install.checked == true" (change)="installChecked(install, $event.target.checked)" />
  </div>
</div>
  <div style="width: 100%; clear:both; margin-top: 20px;">
    <button class="btn btn-primary" [routerLink]="['/dashboard']">Go to Dashboard</button>
  </div>
</div>