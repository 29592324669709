import { Component, OnInit, ViewChild  } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import {Router} from '@angular/router';
import { UtilsService } from '../shared/services/utils.service';
import {NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct, NgbTimepickerConfig, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, BaseChartDirective } from 'ng2-charts';
import { NgbTime } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time';
import { Options } from 'ng5-slider';

@Component({
  selector: 'app-cc-remote-monitor',
  templateUrl: './cc-remote-monitor.component.html',
  styleUrls: ['./cc-remote-monitor.component.scss']
})
export class CcRemoteMonitorComponent implements OnInit {

  constructor(public config: NgbTimepickerConfig, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private router: Router, private apiCallsService:ApiCallsService, private utilsService:UtilsService) { }
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  installList
  connectIDSelected = false
  serverList
  monitorList
  showDest = false
  noDataShow = false
  renderDrop
  fullEndDate
  fullStartDate
  showMonitor
  dateToggle = false
  tempParam
  sliderDate = new Date()
  tempTimeParam = []
  tracertData:any = null
  perfMonSelectedServer = "Select Monitor"
  perfMonSelectedInstall = "Select Account"
  selectedMonitor:any = false
  stopTime = ""
  typeNotNull
  datacenters
  TCPSelection = null
  destSelection = "ext"
  showTCP = false
  dataCenter = "Select Datacenter"
  startTime: NgbTimeStruct
  setint
 temp2Param
  sliderVal:any = 50
  sliderSendVal:any = 50
  sliderOptions:Options = {
    floor: 0,
    ceil: 100,
    step: .5,
    showTicks: true,
    showTicksValues: true,
  }
  maxDate: NgbDateStruct
  time: NgbTimeStruct
  endDate: NgbDateStruct
  selectedStartDate: NgbDateStruct
  selectedType = "Select Source Type"
  renderInstallDrop = false
  renderDataDrop = false
  TCPResponse
  testTypes
  dateDisabled = true
  dayToggle = false
  testType = "Select Type"
  graphLoaded
  selectedTimeRange = "30"
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio:true,
    aspectRatio:3.5,
    animation: {
      duration: 0
  },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{
        ticks:{
          display: true,
          autoSkip: true,
          beginAtZero: false,
          labelOffset: 0,
          maxTicksLimit: 15,
          maxRotation: 30,
          minRotation: 0
        },
        type: 'time',
        distribution: 'series',
        time: {  	
          tooltipFormat:'MMM D Y h:mm a',
                  displayFormats: {
                    minute:'MMM D h:mm a',
                    hour: "dddd LT",
                      day: 'MMM D Y'
                  }
              },
              gridLines: {
                borderDash: [8, 4],
                  color: "#808080"
                     }
          }],
      yAxes: [
      ]
    },
  };
  public lineChartLegend = true;
  public lineChartType = 'scatter';
  public lineChartPlugins = [];
  public lineChartColors: Color[] = [];
  graphInt

  async ngOnInit(): Promise<any> {
    // var sliderElement = <HTMLInputElement>document.getElementById("scale")
    // document.getElementById("scale").oninput = function() {
    //   document.getElementById("spanscale").innerHTML = sliderElement.value;
    // }
    var dataResponse = await this.getDatacenters();
    if (dataResponse.data.length == 1) {
      this.dataCenter = dataResponse.data[0].id
      this.getDest()
    }
    var today = new Date()
    this.endDate = this.calendar.getToday()
    this.time = {hour: today.getHours(), minute: today.getMinutes(), second: 0}
    this.selectedStartDate = this.calendar.getToday()
    this.startTime = {hour: today.getHours(), minute: today.getMinutes(), second: 0}
    this.maxDate = this.calendar.getToday()
    this.config.seconds = false;
    this.config.spinners = false;
   this.graphInt = setInterval(() => {
      var today = new Date()
      if (this.endDate.month - 1 == today.getMonth() && this.endDate.day == today.getDate() && this.endDate.year == today.getFullYear() && this.time.hour == today.getHours()) {
        this.endDate = this.calendar.getToday()
        this.time = {hour: today.getHours(), minute: today.getMinutes() + 1, second: today.getSeconds()}
        this.sendMonitorAPI()
      }
      }, 60000);
  }

  ngOnDestroy() {
    if (this.setint) {
      clearInterval(this.setint);
    }
    if (this.graphInt) {
      clearInterval(this.graphInt);
    }
  }

  toggleTimeRange = () => {
    if (this.dayToggle == false) {
      this.selectedTimeRange = "30"
    } else {
      var ngbDate = NgbDate.from(this.endDate)
      this.selectedStartDate = this.calendar.getPrev(ngbDate, "d", 1)
    }
    this.sendMonitorAPI()
  }

  toggleDate = () => {
    if (this.dateToggle == false) {
      this.setTimeNow()
      this.dateDisabled = true
    } else {
      this.dateDisabled = false
    }
  }

  sendTime = async() => {
    setTimeout(()=>{ this.sendMonitorAPI() }, 100);
  }

  updateSliderValue = () => {
    let currentDate = new Date()
    let difference = currentDate.getTime() - this.sliderDate.getTime()
    if (difference > 2000) {
     var elementCollection = <HTMLCollection>document.getElementsByClassName('mat-slider-thumb-label-text')
     var el:HTMLElement = <HTMLElement>elementCollection[0]
    if (parseFloat(this.sliderSendVal) !== parseFloat(el.innerText)) {
      this.sliderSendVal = parseFloat(el.innerText)
      this.sliderVal = parseFloat(el.innerText)
      this.sliderDate = new Date()
      console.log(this.sliderSendVal)
      this.renderMonitorGraph(null)
    }
  else {
    return
  }
  } else {
    return
  }
}

  setTimeNow = () => {
    var today = new Date()
    this.time = {hour: today.getHours(), minute: today.getMinutes(), second: 0}
    this.endDate = this.calendar.getToday()
    this.sendMonitorAPI()
  }

  selectedDataType = async() => {
    this.selectedMonitor = false
    if (this.selectedType == "datacenter") {
      this.renderInstallDrop = false
      this.typeNotNull = true
      this.renderDataDrop = true
    } else if (this.selectedType == "connectid") {
      this.renderDataDrop = false
      this.typeNotNull = true
      this.renderInstallDrop = true
    } else {
      this.typeNotNull = null
    }
    this.installList = await this.getinstallList();
  }

  getDatacenters = async() => {
    var data = { 
      'domain': 'data-lake',
      'api': 'listMonitoredDatacenters',
      'properties': {
      } 
    }
    var response = await this.apiCallsService.sendApi(data)
    this.datacenters = response.data
    return response
  }

  getApisPer = async() => {
    var target
    if (this.destSelection == "acct") {
      target = this.perfMonSelectedInstall
      clearInterval(this.setint);
    } else if (this.destSelection == "ext") {
      target = this.dataCenter
      clearInterval(this.setint);
    } else if (this.destSelection == "int") {
      target = this.TCPSelection
      this.setint = setInterval(() => { 
        let elementCollections = <HTMLCollection>document.getElementsByClassName('mat-slider-thumb-label-text')
        let element:HTMLElement = <HTMLElement>elementCollections[0]
        if (parseFloat(this.sliderSendVal) !== parseFloat(element.innerText)) {
          this.sliderSendVal = parseFloat(element.innerText)
          this.sliderVal = parseFloat(element.innerText)
          console.log(this.sliderSendVal)
          this.renderMonitorGraph(null)
        }
      }, 1000);
    } else {
      return
    }
    var data = { 
      'domain': 'data-lake',
      'api': 'listApisPer',
      'properties': {
        "target_id": target,
        "monitor_id": this.perfMonSelectedServer
      } 
    }
    var response = await this.apiCallsService.sendApi(data)
    this.testTypes = response.data

    for (var m = 0; m < this.testTypes.length; m++) {
      if (this.testType == this.testTypes[m].id) {
        break
      } else {
        this.testType = this.testTypes[0].id
      }
    }
    this.sendMonitorAPI();
    return response
  }

  getDest = async() => {
    if (this.testType == "meeting-tcp-test") {
      this.testType = null
    }
    var data
    if (this.destSelection == "acct") {
     data = await this.installsPerData()
    } else if (this.destSelection == "ext") {
      data = await this.getMonitors()
    } else if (this.destSelection == "int") {
      data = await this.getTCP()
      var res = await this.getMonitors();
    }
    if (this.perfMonSelectedServer !== 'Select Monitor') {
      var res = await this.getMonitors();
      this.getApisPer()
    }
  }

  installsPerData = async() => {
    var data = { 
      'domain': 'data-lake',
      'api': 'listInstallsPerDatacenter',
      'properties': {
        "datacenter_id":this.dataCenter
      } 
    }
    this.showTCP = true
    var response = await this.apiCallsService.sendApi(data)
    this.installList = response.data
    this.showMonitor = true
    return response
  }

  getTCP = async() => {
    var data = { 
      'domain': 'data-lake',
      'api': 'listTCPMonitorsPerDatacenter',
      'properties': {
        "datacenter_id":this.dataCenter
      } 
    }
    this.showTCP = true
    var response = await this.apiCallsService.sendApi(data)
    this.TCPResponse = response.data
    if (this.TCPResponse.length == 0) {
      this.TCPSelection = null
    }
    if (this.TCPSelection == null) {
      this.TCPSelection = this.TCPResponse[0].id
    }
    this.showMonitor = true
    return response
  }

getMonitors = async() => {
  var target
  if (this.destSelection == "acct") {
    target = this.perfMonSelectedInstall
  } else if (this.destSelection == "ext") {
    target = this.dataCenter
  } else if (this.destSelection == "int") {
    target = this.TCPSelection
  } else {
    return
  }
  var data = { 
    'domain': 'data-lake',
    'api': 'listMonitorsPerTarget',
    'properties': {
      "target_id": target,
    } 
  }
  
  var response = await this.apiCallsService.sendApi(data)
  this.monitorList = response.data
  this.showMonitor = true
  if (this.perfMonSelectedServer !== 'Select Monitor') {
    this.getApisPer()
  }
}


// testTypes = async() => { 

//   var target
//   if (this.monitorList !== "Select Monitor") {
//     var data = { 
//       'domain': 'data-lake',
//       'api': 'listTestTypesFor',
//       'properties': {
//         "target_id": target,
//         "monitor_id": this.perfMonSelectedServer
//       } 
//     }
//     var response = await this.apiCallsService.sendApi(data)
//     this.monitorList = response.data
//   } else {
//     return
//   }
// }

sendMonitorAPI = async() => {
var tempEndDate = new Date()
var tempStartDate = new Date()
var range = parseInt(this.selectedTimeRange)
var target
tempEndDate.setMonth(this.endDate.month - 1)
tempEndDate.setDate(this.endDate.day)
tempEndDate.setFullYear(this.endDate.year)

if (this.time !== undefined) {
  tempEndDate.setHours(this.time.hour)
  tempEndDate.setMinutes(this.time.minute + 1)
  tempEndDate.setSeconds(this.time.second)
}
this.fullEndDate = tempEndDate;
if (this.dayToggle == true) {
  tempStartDate.setMonth(this.selectedStartDate.month - 1)
  tempStartDate.setDate(this.selectedStartDate.day)
  tempStartDate.setFullYear(this.selectedStartDate.year)
  if (this.startTime !== undefined) {
    tempStartDate.setHours(this.startTime.hour)
    tempStartDate.setMinutes(this.startTime.minute + 1)
    tempStartDate.setSeconds(this.startTime.second)
  }
  this.fullStartDate = tempStartDate
  if (this.fullStartDate.getTime() == this.fullEndDate.getTime()) {
    this.fullStartDate.setDate(this.fullEndDate.getDate() - 1);
    this.startTime.minute = this.time.minute
    this.startTime.hour = this.fullEndDate.getHours();
    var ngbDate = NgbDate.from(this.endDate)
    this.selectedStartDate = this.calendar.getPrev(ngbDate, "d", 1)
  } else {
    tempStartDate.setMinutes(this.startTime.minute)
  }
} else {
  this.fullStartDate = new Date(tempEndDate);
  this.fullStartDate.setMinutes(this.fullStartDate.getMinutes() - range);
}

if (this.fullStartDate.getTime() >= this.fullEndDate.getTime()) {
  let today = new Date()
  alert("You cannot set the start time to be after the end time")
  this.startTime = {hour: this.fullEndDate.getHours(), minute: this.fullEndDate.getMinutes(), second: 0}
  this.fullStartDate = new Date(this.fullEndDate)
  this.fullStartDate.setMinutes(this.fullEndDate.getMinutes() - 15)
  this.startTime.minute = this.fullStartDate.getMinutes();
  if (this.fullEndDate.getMinutes() < 15) {
    this.startTime.hour = this.fullEndDate.getHours() - 1
  }
  var ngbDate = NgbDate.from(this.endDate)
  this.selectedStartDate = this.calendar.getPrev(ngbDate, "d", 0.0104166666666667)
  this.selectedStartDate.month = this.fullEndDate.getMonth() + 1
  this.selectedStartDate.day = this.fullEndDate.getDate()
  this.selectedStartDate.year = this.fullEndDate.getFullYear()
}

if (this.destSelection == "acct") {
  target = this.perfMonSelectedInstall
} else if (this.destSelection == "ext") {
  target = this.dataCenter
} else if (this.destSelection == "int") {
  target = this.TCPSelection
} else {
  return
}
    var data = { 
      'domain': 'data-lake',
      'api': 'getRemoteMonitor',
      'properties': {
        "target_id": target,
        "monitor_id": this.perfMonSelectedServer,
        "api": this.testType,
        "begin": this.fullStartDate.getTime(),
        "end": this.fullEndDate.getTime()
      } 
    }
    var response = await this.apiCallsService.sendApi(data)
    this.tempParam = response
    this.tempTimeParam = []
    if (response.data.times) {
    for (var i = 0; i < response.data.times.length; i++) {
      this.tempTimeParam.push(response.data.times[i])
    }
  }
    this.renderMonitorGraph(response)
  }
  isInt = async(n) =>{
    return n % 1 === 0;
 }

  renderMonitorGraph = async(param) => {
    var chartArr = []
    if (param == undefined || param == null) {
      this.tempParam.data.times = this.tempTimeParam.slice();
      param = this.tempParam
    } 
    if (param.data.traceroute) {
    if (param.data.traceroute.length == 0 && this.tracertData == null) {
      this.tracertData = null
    } else {
      this.tracertData = [];
      for (var i = 0; i < param.data.traceroute.length; i++) {
        this.tracertData.push(param.data.traceroute[i])
      }
    }
    }
    if (param.data.chartData) {
      chartArr = this.utilsService.chartDataTransform(param.data.chartData)
    } else if (this.testType == "meeting-tcp-test") {
      var result = await this.isInt(this.sliderSendVal)
      var percentage
      if (result == true) {
        percentage = parseFloat(this.sliderSendVal)
        percentage = this.sliderVal.toString() + ".0"
      } else {
        percentage = parseFloat(this.sliderSendVal)
        percentage = this.sliderSendVal.toString()
      }
      console.log(this.tempParam)
      chartArr = await this.utilsService.testPercentile(percentage, param.data)
      if (chartArr[0] == undefined) {
        this.noDataShow = true
        chartArr[0] = [
          {
            label: "",
            fillColor: "rgba(220,220,220,0.0)",
            strokeColor: "rgba(220,220,220,0)",
            pointColor: "rgba(220,220,220,0)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            // change this data values according to the vertical scale
            // you are looking for 
            data: [65, 59, 80, 81, 56, 55, 40]
        },
        // your real chart here
        {
            label: "",
            fillColor: "rgba(220,220,220,0.2)",
            strokeColor: "rgba(220,220,220,1)",
            pointColor: "rgba(220,220,220,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            data: []
        }
        ]
        chartArr[1] = ["There", "is", "no", "data", "for", "this", "graph"]
        chartArr[2] = 1
        chartArr[4] = ""
      } else {
        this.noDataShow = false
      }
    } 
    else {
      chartArr = this.utilsService.scatterChartTransform(param.data.yAxes)
    }
    this.lineChartOptions.scales.yAxes = []
    if (this.chart == undefined) {
      if (chartArr[2] > 1) {
      for (var i = 1; i < chartArr[2] + 1; i++) {
        var axisOptions
        if (i % 2 == 1) {
          axisOptions = {id: i, position: 'left', ticks: { beginAtZero: true }, scaleLabel: {display: true, labelString: chartArr[4][i - 1]}}
          this.lineChartOptions.scales.yAxes.push(axisOptions)
        }
        else {
          axisOptions = {id: i, position: 'right', ticks: { beginAtZero: true},scaleLabel: {display: true, labelString: chartArr[4][i - 1]}}
          this.lineChartOptions.scales.yAxes.push(axisOptions)
        }
      }
      } else {
        axisOptions = {id: 1, position: 'left', ticks: { beginAtZero: true }, scaleLabel: {display: true, labelString: chartArr[4]}}
        this.lineChartOptions.scales.yAxes.push(axisOptions)
      }
    } else {
      if (this.dayToggle == false) {
        this.chart.chart.options.scales.xAxes[0].time.displayFormats.hour = "dddd LT"
      } else {
        this.chart.chart.options.scales.xAxes[0].time.displayFormats.hour = "MMM D Y"
      }
      if (this.chart.chart) {
      this.chart.chart.options.scales.yAxes = []
      } else {
        this.chart.options.scales.yAxes = []
      }
      if (chartArr[2] > 1) {
      for (var i = 1; i < chartArr[2] + 1; i++) {
        var axisOptions
        if (i % 2 == 1) {
          axisOptions = {id: i, position: 'left', ticks: {beginAtZero: true}, scaleLabel: {display: true, labelString: chartArr[4][i - 1]}}
          this.chart.chart.options.scales.yAxes.push(axisOptions)
        }
        else {
          axisOptions = {id: i, position: 'right', ticks: {beginAtZero: true}, scaleLabel: {display: true, labelString: chartArr[4][i - 1]}}
          this.chart.chart.options.scales.yAxes.push(axisOptions)
        }
      }
      } else {
        axisOptions = {id: 1, position: 'left', ticks: { beginAtZero: true }, scaleLabel: {display: true, labelString: chartArr[4]}}
        this.chart.chart.options.scales.yAxes.push(axisOptions)
      }
    }
    var chartData = chartArr[0]
    var chartLabels = chartArr[1]
    this.lineChartData = chartData
    this.lineChartLabels = chartLabels
    this.lineChartColors = []
    if (chartArr[3] == null) {      
      this.lineChartColors.push({
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: "#269AD0",
      pointBackgroundColor: "#269AD0",
      pointBorderColor: "#269AD0",
      pointHoverBackgroundColor: "#269AD0",
      pointHoverBorderColor: "#269AD0"
    })
    } else {
      for (var o = 0; o < chartArr[3].length; o++) {
        var colorParam = chartArr[3][o]
        var color = this.utilsService.getColors(colorParam)
        this.lineChartColors.push({
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: color,
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointHoverBackgroundColor: color,
          pointHoverBorderColor: color
        })
      }
    }
    this.graphLoaded = true
    if (this.chart !== undefined) {
      if (this.chart.chart) {
        this.chart.chart.update()
      }
      else {
        this.chart.update()
      }
    }
  }

  clearRouteData = () => {
    this.tracertData = null
  }
  
  selectConnectIDApi = async() => {
    if (this.perfMonSelectedInstall !== "Select Connect ID") {
    var data = { 
      'domain': 'data-lake',
      'api': 'listMonitorsPerTarget',
      'properties': {
        "target_id": this.perfMonSelectedInstall
      } 
    }
    var response = await this.apiCallsService.sendApi(data)
  } else {
    this.connectIDSelected = false
  }
  this.serverList = response.data
  this.connectIDSelected = true;
  this.sendMonitorAPI()
  }
  getinstallList = async() => {
    var data = { 
      'domain': 'data-lake',
      'api': 'listMonitorTargets',
    }
    var response = await this.apiCallsService.sendApi(data)
    return response
  }


}
