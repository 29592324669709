<div class="content-card">
    <div class="adminContainer">
        <h1>Installs</h1>
        <div>
            <form class="form-group">
                <div class="row">
                    <div class="col-md-3 col-12">
                        <h3>Add Installation</h3>
                        <input [(ngModel)]="installName" name="installation" class="form-control "
                            placeholder="Enter Install Name" />
                    </div>
                    <div class="col-md-3 col-12">
                        <h3>Select Instance</h3>    
                        <select class="form-control" [(ngModel)]="selectedInstance" name="instance" (change)="get_dbname()">
                            <option selected value="">Please Select Instance</option>
                            <option [value]="instance.id" *ngFor="let instance of get_instances; let i = index">{{instance.host}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-12">
                        <h3>Select Instance DB</h3>
                        <select class="form-control" name="dbname" [(ngModel)]="selectedDbName">
                            <option selected value="">Please Select Instance DB</option>
                            <option *ngFor="let dbnames  of dbnamelist; let i = index">{{dbnames}}</option>
                        </select>
                    </div>                    
                    <div class="col-md-12 col-12">                    
                        <input [(ngModel)]="username" name="installation" class="form-control "
                                placeholder="Enter User Name" />
                        <input [(ngModel)]="password" name="installation" type="password" class="form-control "
                                placeholder="Enter password Name" />
                    </div>
                </div>
            </form>
            <button (click)="createInstallation();" class="btn btn-primary createBtn">Save</button>
        </div>
    </div>
</div>

<!-- Installation table Data -->

<div class="content-card">
    <!-- <div style="margin-bottom:30px;"  class="install-loader">
        <h2>Please Wait -- Loading Tickets</h2>
        <div class="progress-bar">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        </div> -->
    <div class="row">
        <div class="col-sm-12">
          <div class="table-responsive">
            <table *ngIf="getInstallationData.length && deleteFlag==true" datatable id="tix-table" class="table row-border hover">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Dcops</th>
                        <th scope="col">Application</th>
                        <th scope="col">Instance Name</th>
                        <th scope="col">Database Name</th>
                        <th scope="col">User Name</th>
                        <th scope="col">Closed</th>
                        <th id="view-ticket-col" scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let installations of getInstallationData">
                        <td>{{ installations.id }}</td>
                        <td>{{ installations.name }}</td>
                        <td>{{ installations.dcops }}</td>
                        <td>{{ installations.application }}</td>
                        <td>{{installations.instance_name}}</td>
                        <td>{{installations.dbname}}</td>
                        <td>{{installations.username}}</td>     
                        <td>
                            <mat-slide-toggle class="custom-slide-toggle" color="primary"
                                [checked]="installations.disabled == true ? true : false"
                                (change)="updateToggle(installations, $event)"></mat-slide-toggle>
                        </td>
                        <td class="d-flex flex-row">
                            <!-- <a class="btn btn-outline-dar kmx-1" [routerLink]="['/admin/view-install', installations.id]" (click)="installDetails(installations)" title="View Installs" data-toggle="tooltip"><mat-icon>remove_red_eye</mat-icon></a> -->
                            <a class="btn btn-outline-dar kmx-1" (click)="installDetails(installations)"
                                title="View Install" data-toggle="tooltip"><mat-icon>remove_red_eye</mat-icon></a>
                            <a class="btn btn-outline-dar kmx-1"
                                [routerLink]="['/admin/edit-install', installations.id]" title="Edit Tickets"
                                data-toggle="tooltip"><mat-icon>edit</mat-icon></a>
                            <a class="btn btn-outline-dar kmx-1" (click)="deleteInstall(installations)"
                                title="Delete Install" data-toggle="tooltip"><mat-icon>delete</mat-icon></a>

                            <!-- <a class="btn btn-outline-dar kmx-1" [routerLink]="['/ticket', ticket.id]" title="Delete Tickets" data-toggle="tooltip"><mat-icon>delete</mat-icon></a> -->
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
            </div>
          </div>
        </div>
    </div>
</div>