<div class="container-fluid">
  <div *ngIf="!router.url.includes('login')" class="row">
    <div class="header col-sm-12">
      <app-dash-header></app-dash-header>
    </div>
  </div>
  <div class="row" id="background-gray">
    <div *ngIf="!router.url.includes('login')" class="col-1 col-xl-2 sidebar">
      <app-sidebar-nav></app-sidebar-nav>
    </div>
    <!-- <div *ngIf="!router.url.includes('login')" class="content col-11 col-xl-10"> -->
    <div class="content col-11 col-xl-10">
        <div class="content-area">
          <div class="content-card">
            <router-outlet></router-outlet>
          </div>
        </div>
    </div>
    <!-- <div *ngIf="router.url.includes('login')">
              <router-outlet></router-outlet>
      </div> -->
  </div>
</div>