import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-recent-logins',
  templateUrl: './recent-logins.component.html',
  styleUrls: ['./recent-logins.component.scss']
})
export class RecentLoginsComponent implements OnInit {
  constructor(private router: Router, private apiCallsService:ApiCallsService) { }
  selectedInstall:string;
  controlNuggets = JSON.parse(sessionStorage.getItem("installs"));
  nuggetHTML: string;
  recentLoginTableHeads
  recentLoginTableName
  recentLoginTableRows
  recentLoginTableID
  recentLoginTableSize
  recentLoginPage
  activeNugget
  meetingCSV = []
  recentLoginPageSize
  controls: any[] = []
  controlsSpinner = true;
  recentLoginTable = undefined
  userTable = undefined
  async ngOnInit(): Promise<any> {
    this.controls = await this.createControls(this.controlNuggets);
    this.controlsSpinner = false;
    this.selectInstallControl(this.controls[0]['connect-id'], this.controls[0].name)
  }
  createControls = async (controlsParam) => {
    var controlArr = [];
    for (var i = 0; i < controlsParam.length; i++) {
        var controlResponse = await this.fetchControlSummary(controlsParam[i]["connect-id"]);
        this.controlNuggets[i].response = controlResponse.data;
        controlArr.push(this.controlNuggets[i])
    }
    return controlArr
}

fetchControlSummary = async (connectIDParam) =>{
  var data = {
      "domain":"data-lake",
      "api":"currentDataPerConnect",
      "properties": {
          "connect-id": connectIDParam,
      }
  };
  var response = await this.apiCallsService.sendApi(data)
  return response 
}

 selectInstallControl = (install, name) => {
  this.recentLoginTable = undefined
  this.selectedInstall = install
  this.activeNugget = name;
  var selectedName = name
  this.fetchMeetings(this.selectedInstall, selectedName)
}

fetchMeetings = async (installParam, nameParam) => {
  var data = {
      "domain":"data-lake",
      "api":"recentLoginReport",
      "properties": {
          "connect-id": installParam,
      }
  };
  var response = await this.apiCallsService.sendApi(data)
  if (response.status == "FAILED_INVALID") {
      alert("The Connect instance does not have proper data. Try another Server")
      return
  }
  this.renderMeetings(response.data.data["query-results"], installParam, nameParam)
}

downloadrptcsv = () => {
  var csvNameDate:any = new Date()  
  csvNameDate = csvNameDate.toDateString()
  var data = this.meetingCSV
  var csv = data[0].toString()+"%0D%0A";
  for(var c=0; c<data[1].length;c++){
      for(var i=1; i<data.length;i++){
          data[i][c] = data[i][c].toString();
          data[i][c] = data[i][c].replace(' ', '%20')
          data[i][c] = data[i][c].replace('#', '%20')
          data[i][c] = data[i][c].replace(',', '%20')
          csv += data[i][c]+","
      }
      csv+="%0D%0A";
  }
  var link = document.createElement('a');
  link.href = "data:application/octet-stream,field1%2Cfield2%0Afoo%2Cbar%0Agoo%2Cgai%0A";
  link.href = "data:application/octet-stream,"+csv;
  link.download = 'recentLogins '+csvNameDate+'.csv';
  link.click();
}

renderMeetings = (tableParam, installID, installName) => {
  this.userTable = undefined
  this.meetingCSV = []
  this.recentLoginTableName = installName
  this.recentLoginTable = true
  this.recentLoginTableID = installID
  this.recentLoginTableHeads = tableParam.headings
  this.recentLoginTableRows = tableParam.rows
  if (tableParam.rows.length !== 0) {
    for (var m = 0; m < tableParam.rows[0].length; m++) {
      this.meetingCSV[m] = []
    }
    for (var m = 0; m < tableParam.rows.length; m++) {
      for (var l = 0; l < tableParam.rows[m].length; l++) {
        this.meetingCSV[l].push(tableParam.rows[m][l])
      }
    }
    for (var p = 0; p < this.meetingCSV[0].length; p++) {
      this.meetingCSV[0][p] = new Date(this.meetingCSV[0][p])
      this.meetingCSV[0][p] = this.meetingCSV[0][p].toLocaleString()
    }
    this.meetingCSV.unshift(tableParam.headings)
    }
  for (var i = 0; i < this.recentLoginTableRows.length; i++) {
    this.recentLoginTableRows[i][0] = new Date(this.recentLoginTableRows[i][0])
  }
  this.recentLoginPage = 1
  this.recentLoginPageSize = 10
  this.recentLoginTableSize = tableParam.rows.length
}

}
