<div>
    <mat-tab-group id="disableTabs" [selectedIndex]="indexVal" class="col-sm-12">
        <mat-tab>
            <ng-template mat-tab-label>
                <span>SCO Search</span>
            </ng-template>
            <div class="row">
                <div class="col-3">
                    <input [(ngModel)]="scoSearch" type="text" />
                </div>
                <div class="col-2">
                    <input [(ngModel)]="scoCheck" style="margin-right: 5px" type="checkbox" /><label>Name Search</label>
                </div>
                <div class="col-2">
                    <button (click)="search('sco')" class="btn btn-primary">Search</button>
                </div>
            </div>
                <p-table *ngIf="scoTable == true" #dt id="sco-table" [resizableColumns]="true" [value]="scoTableResp"  [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
    [paginator]="true" [rowHover]="true" styleClass="p-datatable-gridlines" [globalFilterFields]="['sco_id', 'name', 'url', 'owner', 'login', 'type', 'version', 'folder_name', 'folder_id', 'source_id', 'create_date', 'end', 'disabled', 'template']">
    <ng-template pTemplate="caption">
      SCOs 
    </ng-template>
            <ng-template pTemplate="header">
                <tr>
                  <th pResizableColumn pSortableColumn="sco_id">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      Sco ID
      
                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="name">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      Name

                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="url">
                    <div class="p-d-flex p-jc-between p-ai-center">
                     URL    
    
                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="owner">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      Owner     
      
                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="login">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      Login       
          
                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="type">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      Type  
          
                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="version">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      Version   
      
                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="folder_name">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Folder Name
             
                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="folder_id"> 
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Folder ID
 
                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="source_id">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Source Sco ID
   
                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="create_date">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Created Date

                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="end">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Ended
    
                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="disabled">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Disabled
   
                  </div>
                  </th>
                  <th pResizableColumn pSortableColumn="template">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Template

                  </div>
                  </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-sco>
                <tr (click)="scoSearchTable(sco[0])">
                  <td title="{{sco[0]}}">{{sco[0]}}</td>
                  <td title="{{sco[1]}}">{{sco[1]}}</td>
                  <td title="{{sco[2]}}">{{sco[2]}}</td>
                  <td title="{{sco[3]}}">{{sco[3]}}</td>
                  <td title="{{sco[4]}}">{{sco[4]}}</td>
                  <td title="{{sco[5]}}">{{sco[5]}}</td>
                  <td title="{{sco[6]}}">{{sco[6]}}</td>
                  <td title="{{sco[7]}}">{{sco[7]}}</td>
                  <td title="{{sco[8]}}">{{sco[8]}}</td>
                  <td title="{{sco[9]}}">{{sco[9]}}</td>
                  <td title="{{sco[10] | date: 'MM/dd/yy H:mm aa'}}">{{sco[10] | date: 'MM/dd/yy H:mm aa'}}</td>
                  <td title="{{sco[11] | date: 'MM/dd/yy H:mm aa'}}">{{sco[11] | date: 'MM/dd/yy H:mm aa'}}</td>
                  <td title="{{sco[12]}}">{{sco[12]}}</td>
                  <td title="{{sco[13]}}">{{sco[13]}}</td>
                </tr>
            </ng-template>
        </p-table>

                <p-table *ngIf="meetingTable == true" #dt id="meeting-table" [value]="meetingTableResp" [resizableColumns]="true"  [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                [paginator]="true" [rowHover]="true" styleClass="p-datatable-gridlines" [globalFilterFields]="['version', 'start', 'end', 'asset', 'attend']">
                <ng-template pTemplate="caption">
                  Meetings 
                </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                              <th pResizableColumn pSortableColumn="version">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Version
                              </div>
                              </th>
                              <th pResizableColumn pSortableColumn="start">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                  Start Date
                              </div>
                              </th>
                              <th pResizableColumn pSortableColumn="end">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    End Date           
                              </div>
                              </th>
                              <th pResizableColumn pSortableColumn="asset">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Asset ID     
                              </div>
                              </th>
                              <th pResizableColumn pSortableColumn="attend">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Attendees
                              </div>
                              </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-meeting>
                            <tr (click)="clickButton(meeting[0], meeting[4])">
                              <td title="{{meeting[1]}}">{{meeting[1]}}</td>
                              <td title="{{meeting[2] | date: 'MM/dd/yy H:mm aa'}}">{{meeting[2] | date: 'MM/dd/yy H:mm aa'}}</td>
                              <td title="{{meeting[3] | date: 'MM/dd/yy H:mm aa'}}">{{meeting[3] | date: 'MM/dd/yy H:mm aa'}}</td>
                              <td title="{{meeting[4]}}">{{meeting[4]}}</td>
                              <td title="{{meeting[5]}}">{{meeting[5]}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span>User Search</span>
            </ng-template>
            <div class="row">
                <div class="col-2">
                    <input [(ngModel)]="userSearch" type="text" />
                </div>
                <div class="col-2">
                    <input [(ngModel)]="userCheck" style="margin-right: 5px" type="checkbox" /><label>Name Search</label>
                </div>
                <div class="col-2">
                    <button (click)="search('user')" class="btn btn-primary">Search</button>
                </div>
            </div>
            <div class="row">
                <p-table *ngIf="userTable == true" #dt id="list-accounts-table" [value]="userTableResp" [resizableColumns]="true"  [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                [paginator]="true" [rowHover]="true" styleClass="p-datatable-gridlines" [globalFilterFields]="['principle', 'name', 'type', 'login', 'ext_login', 'disabled', 'email']">
                <ng-template pTemplate="caption">
                  Users 
                </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                              <th pResizableColumn pSortableColumn="principle">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Principle ID
                              </div>
                              </th>
                              <th pResizableColumn pSortableColumn="name">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Name   
                              </div>
                              </th>
                              <th pResizableColumn pSortableColumn="type">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Type                    
                              </div>
                              </th>
                              <th pResizableColumn pSortableColumn="login">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Login    
                              </div>
                              </th>
                              <th  pResizableColumn pSortableColumn="ext_login">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Ext Login    
                              </div>
                              </th>
                              <th pResizableColumn pSortableColumn="disabled">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Disabled On       
                              </div>
                              </th>
                              <th  pResizableColumn pSortableColumn="email">
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Email
                              </div>
                              </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-user>
                            <tr>
                              <td title="{{user[0]}}">{{user[0]}}</td>
                              <td title="{{user[1]}}">{{user[1]}}</td>
                              <td title="{{user[2]}}">{{user[2]}}</td>
                              <td title="{{user[3]}}">{{user[3]}}</td>
                              <td title="{{user[4]}}">{{user[4]}}</td>
                              <td title="{{user[5] | date: 'MM/dd/yy H:mm aa'}}">{{user[5] | date: 'MM/dd/yy H:mm aa'}}</td>
                              <td title="{{user[6]}}">{{user[6]}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span>Session Search</span>
            </ng-template>
            <div class="row">
                <div class="col-2">
                    <input [(ngModel)]="sessionSearch" type="text" />
                </div>
                <div class="col-2">
                    <input [(ngModel)]="sessionCheck" style="margin-right: 5px" type="checkbox" /><label>Name Search</label>
                </div>
                <div class="col-2">
                    <input [(ngModel)]="timeCheck" style="margin-right: 5px" type="checkbox" /><label>All Sessions</label>
                </div>
                <div class="col-2">
                    <button (click)="search('session')" class="btn btn-primary">Search</button>
                </div>
            </div>
                <div class="row">
                    <p-table *ngIf="sessionTable == true" #dt id="list-accounts-table" [value]="sessionTableResp" [resizableColumns]="true"  [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                    [paginator]="true" [rowHover]="true" styleClass="p-datatable-gridlines" [globalFilterFields]="['session_id', 'principle_id', 'session_cookie', 'created', 'ip', 'name', 'challenge', 'disabled']">
                    <ng-template pTemplate="caption">
                      Sessions 
                    </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                  <th pResizableColumn pSortableColumn="session_id">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Session ID
                                  </div>
                                  </th>
                                  <th pResizableColumn pSortableColumn="principle_id">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Principle ID
                                  </div>
                                  </th>
                                  <th pResizableColumn pSortableColumn="session_cookie">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Session Cookie
                                  </div>
                                  </th>
                                  <th pResizableColumn pSortableColumn="created">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Created On
                                  </div>
                                  </th>
                                  <th  pResizableColumn pSortableColumn="ip">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        IP Address
                                  </div>
                                  </th>
                                  <th pResizableColumn pSortableColumn="name">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Name
                                  </div>
                                  </th>
                                  <th pResizableColumn pSortableColumn="challenge">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Challenge
                                  </div>
                                  </th>
                                  <th  pResizableColumn pSortableColumn="disabled">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Disabled On
                                  </div>
                                  </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-session>
                                <tr>
                                  <td title="{{session[0]}}">{{session[0]}}</td>
                                  <td title="{{session[1]}}">{{session[1]}}</td>
                                  <td title="{{session[2]}}">{{session[2]}}</td>
                                  <td title="{{session[3] | date: 'MM/dd/yy H:mm aa'}}">{{session[3] | date: 'MM/dd/yy H:mm aa'}}</td>
                                  <td title="{{session[4]}}">{{session[4]}}</td>
                                  <td title="{{session[5]}}">{{session[5]}}</td>
                                  <td title="{{session[6]}}">{{session[6]}}</td>
                                  <td title="{{session[7] | date: 'MM/dd/yy H:mm aa'}}">{{session[7] | date: 'MM/dd/yy H:mm aa'}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                </div>
        </mat-tab>
    </mat-tab-group>
</div>