<div>
    <mat-tab-group class="col-sm-12">
        <mat-tab>
          <ng-template mat-tab-label>
            <span (click)="getRecentMeetings()">Recent meetings</span>
        </ng-template>
         
        </mat-tab>
      </mat-tab-group>
</div>
