import { Injectable } from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = false; // ... your login logic here
    if (isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    const isAdmin = sessionStorage.getItem("isInsightsAdmin"); // ... your login logic here
    if (isAdmin == 'true') {
      return true;
    } else {
      this.router.navigate(["/dashboard"]);
      return false;
    }
  }

}

@Injectable({
  providedIn: 'root'
})
export class SSOGuardService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    const isSSO = sessionStorage.getItem("is_show_ssl_log"); 
    if (isSSO == 'true') {
      return true;
    } else {
      this.router.navigate(["/dashboard"]);
      return false;
    }
  }

}
