import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiCallsService } from 'src/app/shared/services/api-calls.service';

// Define an interface for your JSON data
interface ProductData {
  Count: number;
  Items: { id: string; product_nam: string; }[];
  ResponseMetadata: {
    HTTPHeaders: {
      connection: string;
      "content-length": string;
      "content-type": string;
      date: string;
      server: string;
      "x-amz-crc32": string;
      "x-amzn-requestid": string;
    };
    HTTPStatusCode: number;
    RequestId: string;
    RetryAttempts: number;
  };
  ScannedCount: number;
}


@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  seshkeyraw = sessionStorage.getItem('sessionKey');
  seshKey = JSON.parse(this.seshkeyraw);
  a : any[];
  b : ProductData;
  productNames : string[]

  constructor(private apiCallsService: ApiCallsService,) {
    this.getSalesforceProduct()
   }
  urlUtil = () => {
    var baseUrl = window.location.origin;
    if (baseUrl === environment.frontendBaseURL) {
      baseUrl = environment.baseUrl; 
    }
    var url = baseUrl + '/api';
    return url
  }


  getSalesforceProduct = async () => {
    // Iterate through the Items array and extract product names.
    // this.productNames = this.b.Items.map(item => item.product_nam);
    // console.log("this. b :",this.productNames);
    var data = {
      api: 'getProductData',
      "domain": "data-lake",
      'session-key': this.seshKey,
    };
    var response = await this.apiCallsService.sendApi(data);
    // console.log("response 78 : ",response);
    // this.a = response.map(item => item.product_nam);
    if (response && response.Items) {
      // Iterate through the Items array and extract the product names
      this.productNames = response.Items.map(item => item.product_nam);
      // console.log("API :",this.productNames);
      // this.a = productNames;
    } else {
      console.log('Response structure is unexpected.');
    }
    // for (var i = 0; i < response.data.length; i++) {
    //   this.a = response.Items[i].product_nam
    //  }
    return response;
  };

  installToast
    getFDate = (time, format) => {
    //format "long" = "2019-05-31T00:00:00-05:00"
    //format "short" = 03-22-1951
    //format "api" = 03/22/1951
    //format "time" = "11:22AM"
    //format "hour" = "12 AM"
    //format "day"  = "Jun 12"
    //format "month"= "Jun 2019"
    //format "today"= "Friday, May 23, 2019"
    //format "query" = 1951-22-01
    const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    const date = new Date(time);
    let monthNum = date.getMonth() ;
    let dayNum = date.getDate();
    let hourNum = date.getHours();
    let minNum = date.getMinutes();
    let secNum = date.getSeconds();
    let month
    let day
    let hour
    let min
    let sec
    const gmt = date.getTimezoneOffset();
    const year = date.getFullYear()
    let ampm = "AM";
    if( hourNum >= 12 ) {
      ampm = "PM";
      if(hourNum >12){
        hourNum -= 12; 
      }
    }
    if (monthNum < 9) {
      monthNum = monthNum+1
      month = "0" + monthNum.toString();
    } else {
      monthNum = monthNum+1
      month = monthNum.toString();
    }
    if (dayNum < 10) {
      day = "0" + dayNum.toString()
    }
    else {
      day = dayNum.toString()
    }
    if (hourNum < 10) {
      hour = "0" + hourNum.toString()
    }
    else {
      hour = hourNum.toString()
    }
    if (minNum < 10) {
      min = "0" + minNum.toString()
    }
    else {
      min = minNum.toString()
    }
    if (secNum < 10) {
      sec = "0" + secNum.toString()
    }
    else {
      sec = secNum.toString()
    }
      
      if(format==="long"){
        return year + "-" + month + "-" + day + "T" +  hour + ":" + min + ":" + sec+"-"+gmt;
      }else if (format==="time"){
        return hour + ":" + min + ampm;
    }else if (format==="hour"){
      return hour + " "+ ampm;
    }else if (format==="day"){
      return months[date.getMonth()]+" "+day;
    }else if (format==="month"){
      return months[date.getMonth()]+" "+year;
    }else if (format==="today"){
      return days[date.getDay()]+", "+months[date.getMonth()]+" "+day+", "+year;
    }else if (format==="short"){
      return month+"-"+day+"-"+year;
    }else if (format==="short"){
      return month+"-"+day+"-"+year;
    }else if (format==="api"){
      // console.log(month+"/"+day+"/"+year)
      return month+"/"+day+"/"+year;
      }
  }



getYAxis = async (percentile, data, count, times) => {
	var k = percentile;
	var d = data[k];
	if (!d){
		alert("found no data for percentile: "+k);
		return null;
  }
  if (count == 0) {
    return ["nope"]
  }
	var arr = JSON.parse(d);
	
	var yAxis = {};
	yAxis["Label"]=percentile+" percentile latency, ms";
	yAxis["position"] = "left";
	var dataset =  {};
	if (arr.length!=count) {
		alert("ERROR, times array has length "+times.length+" while count is "+count);
	}
	dataset["label"]="latency";
	dataset["color"]="light blue";
	dataset["min"]=Math.min.apply(null,arr);
	dataset["max"]=Math.max.apply(null,arr);
	var dataPoints = [];
	for (var i=0;i<times.length;++i) {
		dataPoints[dataPoints.length] = {"x":times[i],"y":arr[i]};
	}
	dataset["data"]=dataPoints;

	yAxis["datasets"]= [ dataset ];
	return yAxis;
}

testPercentile = async (p, data) => {
  var count = data["count"];
  var times = data["times"];
  times[0] = times[0] * 1000;
  // convert times to millisecond times
  for (var i=1;i<times.length;++i) {
    times[i] = times[i-1] + times[i] * 1000;
  }
  var response = await this.getYAxis(p, data, count, times);
  var respArr = []
  respArr.push(response["datasets"], times, 1, null, response["Label"])
  return respArr
}


    entitlementsCheck = () => {
    var entitlementCheck = sessionStorage.getItem("entitlements")
    return entitlementCheck
  }
  
    setEntitlements = (entitlement) => {
    var entitlementValue
    switch(entitlement.name) {
      case "killmeeting" : entitlementValue = "kill-meeting"
      break;
      default: 
    }
    return entitlementValue
  }
  // change to push new build
 domainUtil = () => {
    var apiDomain;
    var domain =  window.location.hostname.split('.');
    if (domain[0] === "insights-beta") {
      apiDomain = "insights-beta"
    }
    else if (domain[0] === "localhost") {
      apiDomain = "insights"
    }
    else {
      apiDomain = "insights"
    }
    return apiDomain
  }
  
 seshKeyUtil = () => {
    var seshkeyraw = sessionStorage.getItem("session-key");
    return seshkeyraw
  }


  getColors = (color) => {
    var colorObj = {
      "darkYellow": "#F7A209",
      "lightBlue": "#269AD0",
      "darkBlue": "#25408F",
      "black" : "#282521",
      "grey": "#6D6A67",
      "brown":"#8C4600",
      "red":"#d92804",
      "green": "#64c708",
      "softYellow": "#e3d80b"
    }
     return colorObj[color]
  }


  canActivate(): boolean {
    var installDisabled = JSON.parse(localStorage.getItem("SelectedInstalls"));   
    if (installDisabled == null || installDisabled.length == 0) {
      this.installToast = true
      setTimeout(() =>{
        this.installToast = null
      },12000);
      return false;
    }
    return true;
  }

  scatterChartTransform = (x) => {
    var yAxes = x
    var labelData = []
    var chartData = []
    var colorOrder = []
    var yAxesLabel = []
    for (var i = 0; i < yAxes.length; ++i) {
      var yAx = yAxes[i];
      var label = yAx["Label"];
      yAxesLabel.push(label)
      var datas = yAx["datasets"];
      var id = i + 1;
      for (var j=0;j<datas.length;++j) {
        var data = datas[j];
        data["yAxisID"] = id;
        chartData[chartData.length] = {"data":data.data,"yAxisID":id,"label":data.label};	
        colorOrder.push(data.color)
        for (var k = 0; k<data.data.length;k++) {
          labelData.push(data.data[i].x)	
        }	
      }
  }
  var labels = labelData
    
  return [chartData, labels, yAxes.length, colorOrder, yAxesLabel]
}


  chartDataTransform = (x) => {
    var yAxes =x["yAxes"];
    var chartData = [];
    var yaxisMinMax = []
    for (var i = 0; i < yAxes.length; ++i) {
      var yAx = yAxes[i];
      yaxisMinMax.push({"min":yAx.min, "max":yAx.max})
      var label = yAx["label"];
      var datas = yAx["dataSeries"];
      var id = i + 1;
      for (var j=0;j<datas.length;++j) {
        var data = datas[j];
        data["yAxisID"] = id;
        chartData[chartData.length] = {"data":data.chartData,"yAxisID":id,"label":data.label};	
        // console.log("chartData",chartData)		
      }
    }
    var labels = x.xAxis.labels;
    // console.log("labels",labels)
    
    // console.log("chartData: ")
    // console.log(JSON.stringify(chartData, null, 2));
    // console.log("xAxis labels: ")
    // console.log(JSON.stringify(labels, null, 2));
    return [chartData, labels, yAxes.length, yaxisMinMax]
    }

  chartTypes = () =>{

    let charttypes =[
      { 
        chartName:"dashboard", 
        subcharts:[	
          {name:"Meetings",offsets:["meeting count","meeting count avg"],desc:"Active Meetings"},
          {name:"Users", offsets:["total users","total users avg"],desc:"Total Users"},
          {name:"Content", offsets:["content sessions","content sessions avg"],desc:"Content"}],
        verb:"query",
        object:"connect",
        options:["minutes-range","minutes-back"],
        type:"get-connect-per-minute-stats-report",
        retval:"per-minute-stats-report",
        simpleParams:[
          {"name":"connect-installation-id","type":"id","value":""},
          {"name":"minutes-range","type":"long","value":"15"},
          {"name":"minutes-back","type":"long","value":"0"}]
      },{
        chartName:"PerMinute",
        subcharts:[{name:"ActiveMeetings",offsets:["meeting count","meeting count avg"],desc:"Active Meetings"},
          {name:"TotalUsers", offsets:["total users","total users avg"],desc:"Total Concurrent Users"},
          // {name:"MeetingUsers", offsets:["meeting users","meeting users avg","user plus","user minus","meeting plus", "meeting minus" ],desc:"Meeting users (with dippage & history)"},
          //{name:"MeetingUsersDip", offsets:["meeting users","user plus","user minus","meeting plus","meeting minus"],desc:"Meeting Users (with Dippage & no History)"},
          {name:"MeetingUsers", offsets:["meeting users","meeting users avg"],desc:"Meeting Users"},			
          {name:"AuthUserogins", offsets:["user logins","user logins avg"],desc:"Authenticated User Logins"},
          
  //	BIG EXCEPTION HERE TO HANDLE MATH BETWEEN ROWS			{name:"GuestUserAccess", offsets:["user sessions","user sessions avg"],desc:"Guest User Access"},
          {name:"GuestUserAccess", offsets:["user sessions","user sessions avg","user logins"],desc:"Guest User Access"},
          {name:"OnDemandUsers", offsets:["content sessions","content sessions avg"],desc:"On-Demand Users"},
          {name:"TrainingCount", offsets:["training count","training count avg"],desc:"Training Quota"},
          //{name:"TrainingVSOnDemand", offsets:["training count","content sessions"],desc:"Training Quota VS. On-Demand"},
          {name:"OnDemandInter", offsets:["new transcript details","new transcript details avg"],desc:"On-Demand Interactions"},
          // {name:"AppMeetingUserCount", offsets:["connect concurrency","connect concurrency avg"],desc:"Application Meeting User Count"},
        ],	
        verb:"query",
        object:"connect",
        options:["minutes-range","minutes-back","download"],	
        type:"get-connect-per-minute-stats-report",	
        retval:"per-minute-stats-report",
        simpleParams:[
          {"name":"connect-installation-id","type":"id","value":""},
          {"name":"minutes-range","type":"long","value":"15"},
          {"name":"minutes-back","type":"long","value":"0"}] 
      },{
        chartName:"HourlyPeak",
        subcharts:[{name:"PeakMeeting",offsets:["rolling average","rolling average-sm"],desc:"Peak Meeting user and largest single meeting"},
            {name:"PeakOnDemand", offsets:["rolling average","rolling average-cs"],desc:"Peak On-Demand Users"},
            {name:"PeakTraining", offsets:["rolling average","rolling average-ts"],desc:"Peak Training Users"},
            {name:"PeakTotalUsers", offsets:["rolling average","rolling average-cc"],desc:"Peak Total Users"},
            // {name:"PeakMeetingUsers", offsets:["rolling average","rolling average-tc"],desc:"Peak Application Meeting Users"}
          ],
        verb:"query",
        object:"connect",
        options:["history"],
        type:"get-week-over-week-hourly-metrics-report",
        retval:"week-over-week-hourly-metrics",
        simpleParams:[{"name":"connect-installation-id","type":"id","value":""}]
      },{
        chartName:"ExtendedHourly",
        subcharts:[{name:"MaxConcurrentMeetingUsers",offsets:["max meeting users"],desc:"Max Concurrent Meeting Users"},
            {name:"MaxConcurrentUsers", offsets:["max concurrent users"],desc:"Max Concurrent Users"},
            {name:"MaxConDemandUsers", offsets:["max content sessions"],desc:"Max Concurrent On-Demand Users"},
            // {name:"MaxAppConcurrency", offsets:["max connect concurrency"],desc:"Max Application Meeting Concurrency"},
            {name:"LargestMeeting", offsets:["max single meeting"],desc:"Largest Meeting"},
            {name:"MaxGuestMinute", offsets:["max user sessions"],desc:"Max Guest Accesses/Minute"},
            {name:"MaxLoginsMinute", offsets:["max user logins"],desc:"Max Logins/Minute"}
          ],
        verb:"query",
        object:"connect",
        options:["day-count"],
        type:"get-usage-hourly-metrics-report",
        retval:"usage-hourly-metrics",
        simpleParams:[{"name":"connect-installation-id","type":"id","value":"67"},
            {"name":"day-count","type":"string","value":7}]
      },{
      
        chartName:"DailyMetrics",
        subcharts:[{name:"TotalMeetings",offsets:["meeting count"],desc:"Total Meetings"},
            {name:"TotalMeetingMinutes", offsets:["meeting minutes"],desc:"Total Meeting Minutes"},
            {name:"AverageMeetingsLength", offsets:["average meeting length"],desc:"Average Meetings Length(minutes)"},
            {name:"AverageMeetingSize", offsets:["average meeting size"],desc:"Average Meeting Size (Concurrent Users)"},
            {name:"LargestMeetingSize", offsets:["max meeting size"],desc:"Largest Meeting Size (Concurrent Users)"},
          ],
        verb:"query",
        object:"connect",
        options:["report-from-date","group-by-month"],
        type:"get-report-of-daily-metrics",
        retval:"daily-metrics",
        simpleParams:[
            {"name":"connect-installation-id","type":"id","value":"67"},
            {"name":"report-from-date","type":"date","value":"2019-06-17T18:07:38.606Z"},
            {"name":"group-by-month","type":"string","value":true}]
      },{
        chartName:"MonthlyMetrics",
        subcharts:[{name:"TotalMeetings",offsets:["meeting count","meeting count avg"],desc:"Total Meetings"},
            {name:"TotalMeetingMinutes", offsets:["meeting minutes"],desc:"Total Meeting Minutes"},
            {name:"AverageMeetingsLength", offsets:["average meeting length"],desc:"Average Meetings Length (minutes)"},
            {name:"AverageMeetingSize",offsets:["average meeting size"],desc:"Average Meeting Size (Concurrent Users)"},
            {name:"LargestMeetingSize",offsets:["max meeting size"],desc:"Largest Meeting Size (Concurrent Users)"},
          ],
        verb:"query",
        object:"connect",
        options:["group-by-month","group-by-month"],//"report-from-date","group-by-month"],
        type:"get-report-of-daily-metrics",
        retval:"daily-metrics",
        simpleParams:[{"name":"connect-installation-id","type":"id","value":"67"},
            {"name":"report-from-date","type":"date","value":"1970-01-01T06:00:00.000Z"},
            {"name":"group-by-month","type":"string","value":true}]
      },{
        chartName:"MeetingActivityRpt",
        // week shows 7 days
        // month shows 30 days
        // 2 months show 10-11 bars
        // 3 months show 16 bars
        // 6 months show 6 bars
        // year shows 11 bars
        subcharts:[{name:"MeetingActivityRpt",offsets:["meeting count"],desc:"Total Meetings"}],
        axisLabel:"MEETING COUNT",
        verb:"query",
        object:"connect",
        options:["rangeWeek","userType","reportSlices","report-from-date"],
        type:"get-report-of-daily-metrics",
        retval:"daily-metrics",
        simpleParams:[
          {"name":"connect-installation-id","type":"id","value":"72"},
          {"name":"report-from-date","type":"date","value":"2019-06-23T21:24:30.210Z"},
          {"name":"group-by-month","type":"string","value":false}
          ],
        api: "data.getLearningData",
        domain: "insights",
        filtering: [{
          userType: "ALL", 
          contentType: "SEMINAR", 
          axisLabel:"SEMINAR ATTENDEES",
          startDate: "6/30/2019", 
          endDate: "7/7/2019",
          reportSlices: "DAY"},
          {
          userType: "ALL", 
          contentType: "MEETING", 
          axisLabel:"MEETING ATTENDEES",
          startDate: "6/30/2019", 
          endDate: "7/7/2019",
          reportSlices: "DAY"}]
          
      },{
        chartName:"VirtualClsActivityRpt",
        subcharts:[{name:"MeetingActivityRpt",offsets:["meeting count"],desc:"Total Meetings"}],
        axisLabel:"MEETING COUNT",
         api: "data.getLearningData",
        domain: "insights",
        filtering: [{
          userType: "ALL", 
          contentType: "VIRTUAL_CLASSROOM",
          axisLabel:"VIRTUAL CLASSROOM attendees",
          startDate: "6/30/2019", 
          endDate: "7/7/2019",
          reportSlices: "DAY"}
        ]
          
      },{
        chartName:"ContentLaunchActivityRpt",
        subcharts:[{name:"MeetingActivityRpt",offsets:["meeting count"],desc:"Total Meetings"}],
        axisLabel:"MEETING COUNT",
        api: "data.getLearningData",
        domain: "insights",
        filtering: [{
          userType: "ALL", 
          contentType: "NON_TRAINING", 
          axisLabel:"Content Launches",
          startDate: "6/30/2019", 
          endDate: "7/7/2019",
          reportSlices: "DAY"}]
      },{
        chartName:"TrainingLaunchActivityRpt",
        subcharts:[{name:"MeetingActivityRpt",offsets:["meeting count"],desc:"Total Meetings"}],
        axisLabel:"MEETING COUNT",
        verb:"query",
        //object:"connect",
        options:["rangeWeek","userType","reportSlices","report-from-date"],
        type:"get-report-of-daily-metrics",
        retval:"daily-metrics",
        simpleParams:[
          {"name":"connect-installation-id","type":"id","value":"72"},
          {"name":"report-from-date","type":"date","value":"2019-06-23T21:24:30.210Z"},
          {"name":"group-by-month","type":"string","value":false}
          ],
        api: "data.getLearningData",
        domain: "insights",
        filtering: [{
          userType: "ALL", 
          contentType: "TRAINING", 
          axisLabel:"Training Launches",
          startDate: "6/30/2019", 
          endDate: "7/7/2019",
          reportSlices: "DAY"}]
      },{
        chartName:"OnDemandActivityRpt",
        subcharts:[{name:"MeetingActivityRpt",offsets:[],desc:"Total Meetings"}],
  //			subcharts:[{name:"MeetingActivityRpt",offsets:["meeting count"],desc:"Total Meetings"}],
        axisLabel:"MEETING COUNT",
        verb:"query",
  //			object:"connect",
        options:["rangeWeek","userType","reportSlices","report-from-date"],
        type:"get-report-of-daily-metrics",
        retval:"daily-metrics",
        simpleParams:[
          {"name":"connect-installation-id","type":"id","value":"72"},
          {"name":"report-from-date","type":"date","value":"2019-06-23T21:24:30.210Z"},
          {"name":"group-by-month","type":"string","value":false}
          ],
        api: "data.getLearningData",
        domain: "insights",
        filtering: [{
          userType: "ALL", 
          contentType: "ON_DEMAND", 
          axisLabel:"on-demand content launches",
          startDate: "6/30/2019", 
          endDate: "7/7/2019",
          reportSlices: "DAY"}]
      }]
    return charttypes;
  }

   SalesForceOptions = () => {
    // console.log("SalesForceOptions product :",this.productNames);
    let options = {
      statusMap:{
        "Open":["New","Working","Can be closed","RCA"],
        "On Hold":["On Hold","Pending Customer Response","Pending confirmation issue is resolved","Scheduled for Maintenance"],
        "Awaiting Response":["Escalated Internally","Escalated to Adobe","Escalated to Third Party"],
        "Closed":["Closed","Closed - Resolved", "Closed - No response from customer", "Closed - Duplicate Ticket", "Closed - Unresolvable", "Closed - Pending patch release", "Closed - Pending product upgrade", "Closed - Bug Filed"]
        }
      ,
      priorities : [
        "P4 - Low",
        "P3 - Medium",
        "P2 - High", 
        "P1 - Very High"],
      issues : [
        "Question", 
        "Account Request"],
      // product : [
      //   "Adobe Connect",
      //   "Captivate Prime",
      //   "Adobe Connect LTI",
      //   "Coso Insights",
      //   "ConnectSolutions Global Monitor",
      //   "Support Portal",
      //   "Other"
      // ],
      product : this.productNames,
      reason : [
        "reason",
        "Instructions unclear",
        "Missed Training",
        "Something else"],
      origin:[
        "origin1",
        "origin2"],
      type:[
        "Issue",
        "Question",
        "Account Request"]
    }
    
    return options
  }
  
}
