<div class="content-card">
    <div *ngIf="tablesLoaded == true" class="row">
        <mat-tab-group [selectedIndex]="indexVal" class="col-sm-12">
            <mat-tab *ngFor="let button of listResponse.data | keyvalue">
                <ng-template mat-tab-label>
                    <span (click)="tableChange(button.key, button.value)" *ngIf="button.key == 'backups-disabled'">Disabled</span>
                    <span (click)="tableChange(button.key, button.value)" *ngIf="button.key !== 'backups-disabled'">{{button.key.toUpperCase()}}</span>
                </ng-template>
                <div class="col-12">
                    <p-table *ngIf="button.key == 'backups-disabled'" [resizableColumns]="true" #dt id="disabled" [value]="button.value"  [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
                    [paginator]="true" styleClass="p-datatable-gridlines" [rowHover]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                  <th pResizableColumn pSortableColumn="account-name">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                      Account Name
                                      <p-sortIcon field="account-name"></p-sortIcon>
                                  </div>
                                  </th>
                                  <th pResizableColumn pSortableColumn="connect-id">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                      Connect ID
                                      <p-sortIcon field="connect-id"></p-sortIcon>
                                  </div>
                                  </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body"  let-account>
                                <tr (click)="showBackupDetails(account['connect-id'], button.key)">
                                  <td>{{account['account-name']}}</td>
                                  <td>{{account['connect-id']}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    <p-table *ngIf="button.key !== 'backups-disabled'" #dt id="meetingTable" [value]="button.value"  [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
              [paginator]="true" [rowHover]="true">
                      <ng-template pTemplate="header">
                          <tr>
                            <th pSortableColumn="account-name">
                              <div class="p-d-flex p-jc-between p-ai-center">
                                Account Name
                                <p-sortIcon field="account-name"></p-sortIcon>
                            </div>
                            </th>
                            <th pSortableColumn="connect-id">
                              <div class="p-d-flex p-jc-between p-ai-center">
                                Connect ID
                                <p-sortIcon field="connect-id"></p-sortIcon>
                            </div>
                            </th>
                            <th pSortableColumn="db-backup-status.time">
                              <div class="p-d-flex p-jc-between p-ai-center">
                                DB Backup
                                <p-sortIcon field="db-backup-status.time"></p-sortIcon>
                            </div>
                            </th>
                            <th pSortableColumn="content-backup-status.time">
                              <div class="p-d-flex p-jc-between p-ai-center">
                                Content Backup
                                <p-sortIcon field="content-backup-status.time"></p-sortIcon>
                            </div>
                            </th>
                            <th pSortableColumn="content-backup-status.data.Success">
                              <div class="p-d-flex p-jc-between p-ai-center">
                                Success
                                <p-sortIcon field="content-backup-status.data.Success"></p-sortIcon>
                            </div>
                            </th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body"  let-account>
                          <tr (click)="showBackupDetails(account['connect-id'], button.key)">
                            <td>{{account['account-name']}}</td>
                            <td>{{account['connect-id']}}</td>
                            <td *ngIf="account['db-backup-status']"><span *ngIf="account['db-backup-status'].time">{{account['db-backup-status'].time | date: 'short'}}</span></td>
                            <td *ngIf="account['content-backup-status']"><span *ngIf="account['content-backup-status'].time">{{account['content-backup-status'].time | date: 'short'}}</span></td>
                            <td *ngIf="account['content-backup-status']">{{account['content-backup-status'].data.Succcess}}</td>
                          </tr>
                      </ng-template>
                  </p-table>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div *ngIf="detailsShow == true" class="row">
            <h2 class="col-12">{{detailsAccountName}}</h2>
            <div *ngIf="dbBackupStatus == true" class="col-6">
                <h4>Backup Details</h4>
                <div *ngFor="let backup of backupDetailsArr">
                    <p><b>{{backup.title}}:</b> {{backup.value}}</p>
                </div>
                <p><b>Last Full Backup:</b> <span *ngIf="backupDetailsObj['last-full-backup-timestamp']">{{backupDetailsObj['last-full-backup-timestamp'] | date:'short'}}</span></p>
                <p><b>Last Log Backup:</b> <span *ngIf="backupDetailsObj['last-log-backup-timestamp']">{{contentTime | date:'short'}}</span></p>
            </div>
            <div *ngIf="contentBackupStatus == true" class="col-6">
                <h4>Content Details</h4>
                <div *ngFor="let content of contentDetailsArr">
                    <p><b>{{content.title}}:</b> {{content.value}}</p>
                </div>
            </div>
    </div>
    <div *ngIf="noDataToDisplay == true" class="row">
        <div class="col-12">
            <p style="text-align: center; font-size: 20px; font-weight: bold;">There is no data for this install currently</p>
        </div>
    </div>
</div>