import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit  } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import { UtilsService } from '../shared/services/utils.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-tech-support',
  templateUrl: './tech-support.component.html',
  styleUrls: ['./tech-support.component.scss']
})
export class TechSupportComponent implements OnInit {
  CreatedById: string;
  AccountId: string;
  constructor(private router: Router, private apiCallsService:ApiCallsService, private utilsService:UtilsService) { }
  installs = JSON.parse(sessionStorage.getItem("installations"));
  sessionKeys = JSON.parse(sessionStorage.getItem("sessionKeys"));
  ticketListJson
  ticketData
  filterTicketStatus = ""
  reportersArr
  hiddenTable
  filterReporter = ""
  dateToggle = "DATE CREATED"
  dateUpdated = true;
  filterPriority = ""
  ticketTableSize
  showAll = false
  ticketOpenNum:number = 0
  ticketOnHoldNum:number = 0
  ticketAwaitingNum:number = 0
  ticketClosedNum:number = 0 
  ticketPageSize = 15
  ticketPage = 1
  ticketListRecords 
  ticketHeading = "Recent Tickets"
  sortArr
  lastmonth:any;
  sortCol
  sortFlag = true
  showTicketListTable = undefined

  today = new Date();

  async ngOnInit(): Promise<any> {
    var tempReportArr = []
    this.ticketListJson = await this.fetchSupportList("send")
    this.today.setMonth(this.today.getMonth() - 1);
    this.lastmonth = new Date(this.today);
    this.ticketListRecords = this.ticketListJson.response.records
    for (var i = 0; i < this.ticketListRecords.length; i++) {
      var pstat = this.findstat(this.ticketListRecords[i].status)
      switch(pstat) {
        case "Open":
          this.ticketOpenNum += 1
          break;
        case "On Hold":
          this.ticketOnHoldNum += 1
          break;
        case "Awaiting Response":
          this.ticketAwaitingNum += 1
          break;
        case "Closed":
          this.ticketClosedNum += 1
          break;
        default:
          // code block
      }
    }
    var groupName="meetings"
    var idx= 3;
    var pseudoData = this.ticketListJson.response.records
    for (var key in this.ticketListRecords) {
      if ("suppliedName" in this.ticketListRecords[key] == true) {
        this.ticketListRecords[key].displayName = this.ticketListRecords[key].suppliedName
      } else if ("ownerName" in this.ticketListRecords[key] == true  && this.ticketListRecords[key].ownerName !== "") {
        this.ticketListRecords[key].displayName = this.ticketListRecords[key].ownerName
      }
      else {
        this.ticketListRecords[key].displayName = this.ticketListRecords[key].createdByName;
      }
      tempReportArr.push(this.ticketListRecords[key].displayName)
    }
    this.reportersArr = [...new Set(tempReportArr.map(a => a))];
    var hello = await this.UpdateDOM(this.ticketListRecords);
    this.hiddenTable = false
    setTimeout(()=>{ document.getElementById('dropdown-content').addEventListener('click',function (event){
      event.stopPropagation();
   }); }, 100);
    this.sortArr = pseudoData
  }

  
 findstat = (status) => {
	var s = this.utilsService.SalesForceOptions();
	var sm = Object.keys(s.statusMap);
	for(var c in sm){
		for (var i in s.statusMap[sm[c]]){
			if(status === s.statusMap[sm[c]][i]){return sm[c];}
		}
	}
	return null
}

filterTickets = () => {
  var event = new Event('input', {
    'bubbles': true,
    'cancelable': true
    });
 var filterParent = document.getElementsByClassName("dataTables_filter")
 var filterInput = filterParent[0].children[0].children[0] as HTMLInputElement
 filterInput.value = this.filterTicketStatus + " " + this.filterPriority + " " + this.filterReporter 
 filterInput.dispatchEvent(event);
}

  sortTable = (event) =>{
    var i
    var order = 'asc'
    var col = event.target.parentElement.innerText;
    col = col.trim();
    col = col.toLowerCase();
    switch (col) {
      case "ticket id": col = "caseNumber" 
      break;
      case "date created": col = "createdDate"
      break;
      case "reporter": col = "displayName"
      break;
      case "date updated": col = "lastModifiedDate"
      default:
        col = col
    }
    if (col === this.sortCol) {
      this.sortFlag = !this.sortFlag
    } else {
      this.sortFlag = true
    }
    if (this.sortFlag === true) {
      order = 'asc'
    } else if (this.sortFlag === false) {
      order = 'desc'
    }
    this.sortFlag = col
    var sortResult = this.sortArr.sort(this.compareValues(col, order))
  }

  compareValues = (key, order) => {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
      }
    
      const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];
    
      let comparison = 0;
      if (varA > varB) {
      comparison = 1;
      } else if (varA < varB) {
      comparison = -1;
      }
      return (
      (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  UpdateDOM = async (ticketJson) => {
    for (var i = 0; i < ticketJson.length; i++) {
      this.ticketListRecords[i].caseNumber = parseInt(ticketJson[i].caseNumber)
      this.ticketListRecords[i].createdDate = new Date(ticketJson[i].createdDate)
      this.ticketListRecords[i].lastModifiedDate = new Date (ticketJson[i].lastModifiedDate)
    }
    this.ticketTableSize = ticketJson.length
    this.showTicketListTable = true
    return true
  }

  dateSwitch = (bool) => {
    if (bool == true) {
      this.dateToggle = "DATE CREATED"
      this.dateUpdated = true
    } else {
      this.dateToggle = "DATE UPDATED"
      this.dateUpdated = false
    }
      event.stopPropagation();
  }

  showTicketToggle = async (param) => {
    this.ticketOpenNum = 0
    this.ticketOnHoldNum = 0
    this.ticketAwaitingNum = 0
    this.ticketClosedNum = 0
    if (param == true) {
      var tempReportArr = []
      this.showAll = true
      this.ticketListJson = await this.fetchSupportList("all")
      this.today.setMonth(this.today.getMonth() - 1);
      this.lastmonth = new Date(this.today);
      this.ticketListRecords = this.ticketListJson.response.records
      for (var i = 0; i < this.ticketListRecords.length; i++) {
        var pstat = this.findstat(this.ticketListRecords[i].status)
        switch(pstat) {
          case "Open":
            this.ticketOpenNum += 1
            break;
          case "On Hold":
            this.ticketOnHoldNum += 1
            break;
          case "Awaiting Response":
            this.ticketAwaitingNum += 1
            break;
          case "Closed":
            this.ticketClosedNum += 1
            break;
          default:
            // code block
        }
      }
      var groupName="meetings"
      var idx= 3;
      for (var key in this.ticketListRecords) {
        if ("suppliedName" in this.ticketListRecords[key] == true) {
          this.ticketListRecords[key].displayName = this.ticketListRecords[key].suppliedName
        } else if ("ownerName" in this.ticketListRecords[key] == true  && this.ticketListRecords[key].ownerName !== "") {
          this.ticketListRecords[key].displayName = this.ticketListRecords[key].ownerName
        }
        else {
          this.ticketListRecords[key].displayName = this.ticketListRecords[key].createdByName;
        }
        tempReportArr.push(this.ticketListRecords[key].displayName)
        var reporterInd = tempReportArr.indexOf(this.filterReporter)
        if (reporterInd == -1) {
          this.filterReporter = ''
        }
      }
      this.reportersArr = [...new Set(tempReportArr.map(a => a))];
      var res = await this.UpdateDOM(this.ticketListRecords);
    } else {
      var tempReportArr = []
      this.showAll = false
      this.ticketListJson = await this.fetchSupportList("send")
      this.today.setMonth(this.today.getMonth() - 1);
      this.lastmonth = new Date(this.today);
      this.ticketListRecords = this.ticketListJson.response.records
      for (var i = 0; i < this.ticketListRecords.length; i++) {
        var pstat = this.findstat(this.ticketListRecords[i].status)
        switch(pstat) {
          case "Open":
            this.ticketOpenNum += 1
            break;
          case "On Hold":
            this.ticketOnHoldNum += 1
            break;
          case "Awaiting Response":
            this.ticketAwaitingNum += 1
            break;
          case "Closed":
            this.ticketClosedNum += 1
            break;
          default:
            // code block
        }
      }
      var groupName="meetings"
      var idx= 3;
      for (var key in this.ticketListRecords) {
        if ("suppliedName" in this.ticketListRecords[key] == true) {
          this.ticketListRecords[key].displayName = this.ticketListRecords[key].suppliedName
        } else if ("ownerName" in this.ticketListRecords[key] == true  && this.ticketListRecords[key].ownerName !== "") {
          this.ticketListRecords[key].displayName = this.ticketListRecords[key].ownerName
        }
        else {
          this.ticketListRecords[key].displayName = this.ticketListRecords[key].createdByName;
        }
        tempReportArr.push(this.ticketListRecords[key].displayName)
        var reporterInd = tempReportArr.indexOf(this.filterReporter)
        if (reporterInd == -1) {
          this.filterReporter = ''
        }
      }
      this.reportersArr = [...new Set(tempReportArr.map(a => a))];
      var res = await this.UpdateDOM(this.ticketListRecords);
    }
    var waitRender = await this.pollDOM()
  }

  pollDOM = async() => {
    var parel = document.getElementsByClassName("dataTables_filter")
    var el
    if (parel.length > 0) {
     el = parel[0].children[0].children
    } else {
      setTimeout(this.pollDOM, 300);
    }
  
    if (el.length) {
      this.filterTickets()
      this.hiddenTable = false;
    } else {
      setTimeout(this.pollDOM, 300); // try again in 300 milliseconds
    }
  }

  fetchSupportList = async (closeDate) => {
    this.showTicketListTable = false
    this.hiddenTable = true;
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    var keysmqcc = this.sessionKeys.smqcc;
    var keysjccc = this.sessionKeys.sjccc;
    var inskeys = [];
    for(var c in this.installs){
      if(this.installs[c].active){
        inskeys.push(this.installs[c].key)
      }
    }
    if (closeDate == "send" || !closeDate) {
      this.ticketHeading = "Recent Tickets"
       this.ticketData ={
        "api":"support.getTickets",
        "username":sessionStorage.getItem("email"),
        "sessionKeys": {
          "smqcc": keysmqcc,
          "sjccc": keysjccc
        },
        "filtering":{
          "state":"ALL",
          "closedSince": this.utilsService.getFDate( d, "api"),
      }};
    } else {
      this.ticketHeading = "All Tickets"
       this.ticketData ={
      "api":"support.getTickets",
      "username":sessionStorage.getItem("email"),
      "sessionKeys": {
        "smqcc": keysmqcc,
        "sjccc": keysjccc
      },
      "filtering":{
        "state":"ALL",
    }};
    }
    var response = await this.apiCallsService.sendApi(this.ticketData)
    return response
  }


  
}
