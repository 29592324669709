<div class="content-card">
<div class="row">
    <div class="col-sm-2">
        <label for="dataCenter">Select Datacenter</label>
    <select *ngIf="renderDrop == true" class="form-control" id="dataCenter" (change)="getConnectIds()" [(ngModel)]="selectedDatacenter" name="dataCenter">
        <option selected>Please Select Datacenter</option>
        <option *ngFor="let connectID of datacenterList; let i = index" [ngValue]="connectID">
          {{connectID}}
        </option>
      </select>
      </div>
    <div  *ngIf="showConnect == true" class="col-sm-2">
    <label for="connectID">Select Install</label>
<select class="form-control" id="type" (change)="selectConnectIDApi()" [(ngModel)]="perfMonSelectedInstall" name="connectID">
    <option selected>Please Select Connect ID</option>
    <option *ngFor="let install of connectIDList; let i = index" [ngValue]="[install.connect_id, install.account_name]">
      {{install.account_name}}
    </option>
  </select>
  </div>
  <div *ngIf="connectIDSelected == true" class="col-sm-12">
  <button *ngFor="let server of serverList; let i = index" (click)="setServer(server['server-key'])" class="btn btn-primary col-1" name="server">
          {{server['machine-name']}}
    </button>
</div>
</div>
<div class="row" *ngIf="connectIDSelected == true">
  <div *ngIf="indexVal !== 2" class="col-sm-2">
      <div>
      <p><b>Start</b></p>
      <span style="font-size:12px;margin-right:10px">Range</span><mat-slide-toggle (change)="toggleTimeRange()" [(ngModel)]="dayToggle"></mat-slide-toggle><span style="font-size:12px;margin-left:10px">Custom</span>
        </div>
    </div>
  <div *ngIf="dayToggle == false"  class="col-sm-2">
      <label for="range">Range</label>
      <select [disabled]="selectedMetricsChart == 'HourlyPeak' && indexVal == 2" [(ngModel)]="selectedTimeRange" class="form-control"  (change)="sendMonitorAPI()"  name="range" id="rangeDrop">
          <option value="10">10 Minutes</option>
          <option value="15">15 Minutes</option>
          <option value="20">20 Minutes</option>
          <option value="30">30 Minutes</option>
          <option value="45">45 Minutes</option>
          <option value="60">1 Hour</option>
          <option value="120">2 Hours</option>
          <option value="180">3 Hours</option>
          <option value="240">4 Hours</option>
          <option value="300">5 Hours</option>
          <option value="360">6 Hours</option>
          <option value="420">7 Hours</option>
          <option value="480">8 Hours</option>
          <option value="540">9 Hours</option>
          <option value="600">10 Hours</option>
          <option value="660">11 Hours</option>
          <option value="720">12 Hours</option>
          <option value="780">13 Hours</option>
          <option value="840">14 Hours</option>
          <option value="900">15 Hours</option>
          <option value="960">16 Hours</option>
          <option value="1020">17 Hours</option>
          <option value="1080">18 Hours</option>
          <option value="1140">19 Hours</option>
          <option value="1200">20 Hours</option>
          <option value="1260">21 Hours</option>
          <option value="1320">22 Hours</option>
          <option value="1380">23 Hours</option>
          <option value="1440">24 Hours</option>
      </select>
  </div>
  <div *ngIf="showTests == true  && dayToggle == true" class="col-sm-2">
    <label for="startDate">Start Date</label>
    <form class="form-inline">
        <div style="width:100%" class="form-group">
          <div class="input-group">
            <input class="form-control date-input" placeholder="yyyy-mm-dd"
                   name="startDate" [maxDate]="maxDate" [(ngModel)]="selectedStartDate" (ngModelChange)="sendMonitorAPI()" ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </form>
  </div>
  <div *ngIf="showTests == true && dayToggle == true" class="col-sm-2">
    <label for="timeSelect">Enter Time</label>
    <div class="form-group">
        <div class="time-input input-group">
    <ngb-timepicker name="timeSelect" (ngModelChange)="sendTime()" [(ngModel)]="startTime"></ngb-timepicker>
    </div>
    </div>
  </div>
  <div class="col-sm-2">
    <div>
      <p><b>End</b></p>
    <span style="font-size:12px;margin-right:10px">Now</span><mat-slide-toggle [disabled]="selectedMetricsChart == 'HourlyPeak' && indexVal == 2" (change)="toggleDate()" [(ngModel)]="dateToggle"></mat-slide-toggle><span style="font-size:12px;margin-left:10px">Custom</span>
      </div>
  </div>
  <div class="col-sm-2">
      <label for="endDate">End Date</label>
      <form class="form-inline">
          <div style="width:100%" class="form-group">
            <div class="input-group">
              <input [disabled]="dateDisabled == true" class="form-control date-input" placeholder="yyyy-mm-dd"
                     name="endDate" [maxDate]="maxDate" [(ngModel)]="endDate" (ngModelChange)="sendMonitorAPI()" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                <button  [disabled]="dateDisabled == true" class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
              </div>
            </div>
          </div>
        </form>
  </div>
  <div class="col-sm-2">
      <label for="timeSelect">Enter Time</label>
      <div class="form-group">
          <div class="input-group time-input">
      <ngb-timepicker [disabled]="dateDisabled == true" name="timeSelect" (ngModelChange)="sendTime()" [(ngModel)]="time"></ngb-timepicker>
      </div>
      </div>
  </div>
</div>
<div class="row">
<mat-tab-group [selectedIndex]="indexVal" *ngIf="serverSelected == true" id="meetingDetailsTabs" class="col-sm-12">
<mat-tab>
    <ng-template mat-tab-label>
        <span (click)="showCharts('performance'); indexVal = 0">Performance Charts</span>
    </ng-template>
    <div class="row">
<div class="col-sm-2">
    <label for="monitorDrop">Monitor Type</label>
    <select [(ngModel)]="selectedMonitor" class="form-control"  (change)="sendMonitorAPI()"  name="monitorDrop" id="monitorDrop">
        <option value="CPU">CPU</option>
    <optgroup label="Memory">
        <option value="MemSize">Memory</option>
        <option value="MemPages">Hard PG</option>
        <option value="MemSwitch">C Switch</option>
    </optgroup>
    <optgroup label="Disk">
        <option value="DiskQ">R/W Queue</option>
        <option value="DiskT">MS per R/W</option>
    </optgroup>
    <optgroup label="TCP">
        <option value="NetConn">Conn</option>
        <option value="NetErr">Errs</option>
        <option value="NetSeg">Segs</option>
        <option value="NetRetrans">Retr</option>
    </optgroup>
    </select>
</div>
<!-- <div *ngIf="perfMonSelectedServer" class="offset-sm-1 col-sm-1">
    <label for="monitorDrop">Monitor Type</label>
    <select [(ngModel)]="selectedMonitor" class="form-control"  (change)="sendMonitorAPI()"  name="monitorDrop" id="monitorDrop">
        <option value="CPU">CPU</option>
    <optgroup label="Memory">
        <option value="MemSize">Memory</option>
        <option value="MemPages">Hard PG</option>
        <option value="MemSwitch">C Switch</option>
    </optgroup>
    <optgroup label="Disk">
        <option value="DiskQ">R/W Queue</option>
        <option value="DiskT">MS per R/W</option>
    </optgroup>
    <optgroup label="TCP">
        <option value="NetConn">Conn</option>
        <option value="NetErr">Errs</option>
        <option value="NetSeg">Segs</option>
        <option value="NetRetrans">Retr</option>
    </optgroup>
    </select>
</div> -->
</div>
  


<hr class="col-sm-12" *ngIf="serverSelected == true" />
<div class="row">
    <div *ngIf="graphLoaded == true" class="col-sm-12 form-group">
        <canvas id="performance-graph" baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
        [plugins]="lineChartPlugins">
      </canvas>
      </div>
</div>
</mat-tab>
<mat-tab class="row">
    <ng-template mat-tab-label>
        <span (click)="getJvmChart(); indexVal = 1">JVM Charts</span>
    </ng-template>
    <div class="row">
        <mat-tab-group id="jvmGraphTabs" class="col-sm-12">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span (click)="changeGraph('first')">gc 5 minute</span>
                </ng-template>
                <div *ngIf="showJVMCharts == true && renderJvm == 'first'" class="col-sm-12 form-group">
                    <canvas id="first-graph" baseChart
                    [datasets]="firstChartData"
                    [labels]="firstChartLabels"
                    [options]="firstChartOptions"
                    [legend]="firstChartLegend"
                    [chartType]="firstChartType"
                    [plugins]="firstChartPlugins">
                  </canvas>
                  </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span (click)="changeGraph('second')">gc 60 minute</span>
                </ng-template>
                <div *ngIf="showJVMCharts == true && renderJvm == 'second'" class="col-sm-12 form-group">
                    <canvas id="second-graph" baseChart
                    [datasets]="secondChartData"
                    [labels]="secondChartLabels"
                    [options]="secondChartOptions"
                    [legend]="secondChartLegend"
                    [chartType]="secondChartType"
                    [plugins]="secondChartPlugins">
                  </canvas>
                  </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span (click)="changeGraph('third')">jvm mem</span>
                </ng-template>
                <div *ngIf="showJVMCharts == true && renderJvm == 'third'" class="col-sm-12 form-group">
                    <canvas id="third-graph" baseChart
                    [datasets]="thirdChartData"
                    [labels]="thirdChartLabels"
                    [options]="thirdChartOptions"
                    [legend]="thirdChartLegend"
                    [chartType]="thirdChartType"
                    [plugins]="thirdChartPlugins">
                  </canvas>
                  </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span (click)="changeGraph('fourth')">jvm threads</span>
                </ng-template>
                <div *ngIf="showJVMCharts == true && renderJvm == 'fourth'" class="col-sm-12 form-group">
                    <canvas id="fourth-graph" baseChart
                    [datasets]="fourthChartData"
                    [labels]="fourthChartLabels"
                    [options]="fourthChartOptions"
                    [legend]="fourthChartLegend"
                    [chartType]="fourthChartType"
                    [plugins]="fourthChartPlugins">
                  </canvas>
                  </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</mat-tab>
<mat-tab>
  <ng-template mat-tab-label>
    <span (click)="metUpdate(); indexVal = 2; dayToggle = false; selectedTimeRange = '30'">Metrics Charts</span>
</ng-template>
<div class="row">
  <div class="col-4">
<label for="typeSelect">Report Type</label>
<select id="selectChartType" 
#typeSelect
(change)="typeChanged($event.currentTarget.selectedOptions[0].title, typeSelect.value)" [(ngModel)]="selectedMetricsChart" class="form-control">
<option title="Hourly Peak Concurrency" value = "HourlyPeak" >Hourly Peak Concurrency</option>
  <option title="Per Minute Concurrency" value = "PerMinute" >Per Minute Concurrency</option>
</select>
</div>
<div class="col-4">
<label for="subcharts">Available Reports</label>
<select   #subcharts *ngIf="selectedMetricsChart == 'PerMinute'"  (change)="subtypechanged()" class="form-control"  [(ngModel)]="selectedSubchart" id="subcharts">
  <option selected value="ActiveMeetings">Active Meetings</option>
  <option value="TotalUsers">Total Concurrent Users</option>
  <option value="MeetingUsers">Meeting Users</option>
  <option value="AuthUserogins">Authenticated User Logins</option>
  <option value="GuestUserAccess">Guest User Access</option>
  <option value="OnDemandUsers">On-Demand Users</option>
  <option value="TrainingCount">Training Quota</option>
  <option value="OnDemandInter">On-Demand Interactions</option>
</select>
<select   #subcharts *ngIf="selectedMetricsChart == 'HourlyPeak'" (change)="subtypechanged()" class="form-control"  [(ngModel)]="selectedSubchart" id="subcharts">
  <option selected value="PeakMeeting">Peak Meeting user and largest single meeting</option>
  <option value="PeakOnDemand">Peak On-Demand Users</option>
  <option value="PeakTraining">Peak Training Users</option>
  <option value="PeakTotalUsers">Peak Total Users</option>
</select>
</div>
</div>
<div *ngIf="metricsChartReady == true" class="col-sm-12 form-group">
  <canvas id="fourth-graph" baseChart
  [datasets]="metricsChartData"
  [labels]="metricsChartLabels"
  [options]="lineChartOptions"
  [legend]="lineChartLegend"
  [chartType]="lineChartType">
</canvas>
</div>
</mat-tab>
</mat-tab-group>
</div>
</div>