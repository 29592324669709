import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-header',
  templateUrl: './dash-header.component.html',
  styleUrls: ['./dash-header.component.scss']
})
export class DashHeaderComponent implements OnInit {
  dateoptions:object = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  todayDate:string = new Date().toLocaleDateString(undefined, this.dateoptions)
  imageObj ={}
  datacenters
  firstname

  constructor() { }

  ngOnInit(): void {
    this.datacenters = JSON.parse(sessionStorage.getItem("dataCenters"));
    this.firstname = (sessionStorage.getItem("firstName"));
  }
  getImg = (param) => {
    switch (param) {
      case "London, UK":
        return "../../../assets/images/icon_datacenter_britan.svg"
        break;
      case "Hong Kong, SAR":
        return "../../../assets/images/icon_datacenter_hk.svg"
        break;
      default:
        return "../../../assets/images/icon_country_usa.svg"
      }
  }

}
