import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/services/alert.service.service';
import { ApiCallsService } from 'src/app/shared/services/api-calls.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-edit-install',
  templateUrl: './edit-install.component.html',
  styleUrls: ['./edit-install.component.scss']
})
export class EditInstallComponent implements OnInit {
  installId: any;
  public installFromParam: any = undefined
  errorBool = undefined;
  errorMsg;
  installSpinner = true;
  // selectedDbName: any;
  // selectedInstance: any;
  username: ''
  password: ''
  dbnamelist: any;
  // renderTicket = false
  // renderNewTicket = false
  

  constructor(
    private router: Router, 
    private apiCallsService:ApiCallsService,  
    private utilsService:UtilsService, 
    private modalService: NgbModal,
    private alertService: AlertService,
    private route: ActivatedRoute) { }

  baseURLSend = window.location.origin
  seshkeyraw = sessionStorage.getItem("sessionKey");
  seshKey = JSON.parse(this.seshkeyraw);
  installationFullList
  getInstallationData : any;
  selectedInstance: string = ''; 
  // get_instances: string[] = []; 
  get_instances : any[] = [];
  dbNames: string[] = [];
  selectedDbName: string[] = [];

  async ngOnInit(): Promise<any> {
    this.installFromParam = this.route.snapshot.paramMap.get('id');
    if (this.baseURLSend === environment.frontendBaseURL) {
  this.baseURLSend = environment.baseUrl;
  }
    await this.getInstallations();
    // this.listUserJson = await this.fetchAdminList();
    // this.adminSpinner = false;
    // this.renderAdminTable(this.listUserJson.response.userList)
    await this.Get_instances();
    await this.get_dbname();
    if (this.getInstallationData.instance_id) {
      this.selectedInstance = this.getInstallationData.instance_id;
    } else {
      // If getInstallationData.instance_id is not set, you may want to set a default
      // this.selectedInstance = someDefaultValue;
    }
    var installationResponse = await this.getInstallations()
    this.installationFullList = installationResponse.response?.installationList
    // this.renderTicket = true
    // this.renderNewTicket = false
    
      
      
  
  }
  getInstallations = async() => {
    var data = {
      // "api": "authentication.administratorlistinstallations",
      "api": "administration.administratorListInstallations",
      "session-key": this.seshKey,
      "id": this.installFromParam
    };
    var response = await this.apiCallsService.sendApi(data);
    this.getInstallationData = response
    console.log(" this.getInstallationData : ",  this.getInstallationData);
    
    return response
  }

  Get_instances = async () => {
    var data = {
      "api": "get_instances"
    };
    var response = await this.apiCallsService.sendApi(data);
    if (response && response.instances) {
      this.get_instances = response.instances;
  
      // Set selectedInstance based on getInstallationData.instance_id
      if (this.getInstallationData.instance_id) {
        this.selectedInstance = this.getInstallationData.instance_id;
      } else {
        // If getInstallationData.instance_id is not set, you may want to set a default
        // this.selectedInstance = someDefaultValue;
      }
    } else {
      console.log('Response structure is unexpected.');
    }
    return response;
  };


  async get_dbname() {
    var data = {
      "api":"getdbname",
      "id": +this.selectedInstance
    };
    try {
      var response = await this.apiCallsService.sendApi(data);

      if (response && response.dbnames) {
        this.dbnamelist = response.dbnames;
        this.selectedDbName = this.getInstallationData.dbname;
      } else {
        console.log('Response structure is unexpected.', this.get_dbname);
      }
    } catch (error) {
      console.error(error);
    }
    return response;
  }

EditInstallations = async () => {
  var data = {
    "api": "administration.administratorSetInstallations",
    "session-key": this.seshKey,
    "id": this.installFromParam,
    "name": this.getInstallationData.name,
    "instance_id": +this.selectedInstance,
    "dbname": this.selectedDbName,
    "db_username": this.getInstallationData.username,
    "db_password": this.getInstallationData.password,
    "disabled":"",
  };
  try {
    var response = await this.apiCallsService.sendApi(data);
    if(response.status === 400){
      this.installSpinner = true;
      this.errorBool = true;
      this.alertService.showAlert(
        this.errorMsg = response.message,
        'error'
        );
    }
    else {
      this.router.navigate(['/admin/install']);
    }
    // this.alertService.showAlert('Update Successful Installation has been updated.', 'success');
    // this.router.navigate(['/admin/install']);
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error(error);
  }
}


}
