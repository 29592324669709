<div class="content-card">
    <h2 style="margin-bottom:20px;">Custom Monitoring</h2>
    <div style="margin-bottom:20px;" class="row">
        <h4 class="col-12">Select an account:</h4>
        <div *ngFor="let account of customAccountsArr; let i = index" class="col-3 col-sm-2">
            <button class="btn btn-primary" [ngClass]="{'active': selectedIndex == i}" (click)="getCustomMonitor(account.account, i)">{{account.name}}</button>
        </div>
    </div>
    <div style="margin-bottom:20px;" *ngIf="showTests == true" class="row">
        <div class="col-3">
            <label for="testSelect">Select a test</label>
            <select [(ngModel)]="testID"  class="form-control" (change)="getMonitorData()" name="testSelect">
                <option *ngFor="let test of testArr" [ngValue]="test['test-id']">{{test.label}}</option>
            </select>
        </div>
    </div>
    <div *ngIf="showTests == true" class="row">
        <div class="col-sm-2">
            <div>
            <p><b>Start</b></p>
            <span style="font-size:12px;margin-right:10px">Range</span><mat-slide-toggle (change)="toggleTimeRange()" [(ngModel)]="dayToggle"></mat-slide-toggle><span style="font-size:12px;margin-left:10px">Custom</span>
              </div>
          </div>
        <div *ngIf="dayToggle == false"  class="col-sm-2">
            <label for="range">Range</label>
            <select [(ngModel)]="selectedTimeRange" class="form-control"  (change)="getMonitorData()"  name="range" id="rangeDrop">
                <option value="10">10 Minutes</option>
                <option value="15">15 Minutes</option>
                <option value="20">20 Minutes</option>
                <option value="30">30 Minutes</option>
                <option value="45">45 Minutes</option>
                <option value="60">1 Hour</option>
                <option value="120">2 Hours</option>
                <option value="180">3 Hours</option>
                <option value="240">4 Hours</option>
                <option value="300">5 Hours</option>
                <option value="360">6 Hours</option>
                <option value="420">7 Hours</option>
                <option value="480">8 Hours</option>
                <option value="540">9 Hours</option>
                <option value="600">10 Hours</option>
                <option value="660">11 Hours</option>
                <option value="720">12 Hours</option>
                <option value="780">13 Hours</option>
                <option value="840">14 Hours</option>
                <option value="900">15 Hours</option>
                <option value="960">16 Hours</option>
                <option value="1020">17 Hours</option>
                <option value="1080">18 Hours</option>
                <option value="1140">19 Hours</option>
                <option value="1200">20 Hours</option>
                <option value="1260">21 Hours</option>
                <option value="1320">22 Hours</option>
                <option value="1380">23 Hours</option>
                <option value="1440">24 Hours</option>
            </select>
        </div>
        <div *ngIf="showTests == true  && dayToggle == true" class="col-sm-2">
          <label for="startDate">Start Date</label>
          <form class="form-inline">
              <div style="width:100%" class="form-group">
                <div class="input-group">
                  <input class="form-control date-input" placeholder="yyyy-mm-dd"
                         name="startDate" [maxDate]="maxDate" [(ngModel)]="selectedStartDate" (ngModelChange)="getMonitorData()" ngbDatepicker #d="ngbDatepicker">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                  </div>
                </div>
              </div>
            </form>
        </div>
        <div *ngIf="showTests == true && dayToggle == true" class="col-sm-2">
          <label for="timeSelect">Enter Time</label>
          <div class="form-group">
              <div class="time-input input-group">
          <ngb-timepicker name="timeSelect" (ngModelChange)="sendTime()" [(ngModel)]="startTime"></ngb-timepicker>
          </div>
          </div>
        </div>
        <div class="col-sm-2">
          <div>
            <p><b>End</b></p>
          <span style="font-size:12px;margin-right:10px">Now</span><mat-slide-toggle (change)="toggleDate()" [(ngModel)]="dateToggle"></mat-slide-toggle><span style="font-size:12px;margin-left:10px">Custom</span>
            </div>
        </div>
        <div class="col-sm-2">
            <label for="endDate">End Date</label>
            <form class="form-inline">
                <div style="width:100%" class="form-group">
                  <div class="input-group">
                    <input [disabled]="dateDisabled == true" class="form-control date-input" placeholder="yyyy-mm-dd"
                           name="endDate" [maxDate]="maxDate" [(ngModel)]="endDate" (ngModelChange)="getMonitorData()" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                      <button  [disabled]="dateDisabled == true" class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                  </div>
                </div>
              </form>
        </div>
        <div class="col-sm-2">
            <label for="timeSelect">Enter Time</label>
            <div class="form-group">
                <div class="input-group time-input">
            <ngb-timepicker [disabled]="dateDisabled == true" name="timeSelect" (ngModelChange)="sendTime()" [(ngModel)]="time"></ngb-timepicker>
            </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="showChart == true" class="col-sm-12 chart">
            <canvas baseChart
              [datasets]="scatterChartData"
              [options]="scatterChartOptions"
              [labels]="scatterChartLabels"
              [colors]="scatterChartColors"
              [chartType]="scatterChartType">
            </canvas>
          </div>
    </div>
</div>
