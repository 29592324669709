import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})


export class AlertService {

  constructor() { }

  showAlert(title: string, icon: any, timer: number = 3000) {
    Swal.fire({
      title: title,
      icon: icon,
      toast: true,
      position: "top",
      timer: timer,
      timerProgressBar: true,
      showConfirmButton: false,
    });
  }
    deleteAlert() {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
    })
  }
}