import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cc-s3',
  templateUrl: './cc-s3.component.html',
  styleUrls: ['./cc-s3.component.scss']
})
export class CcS3Component implements OnInit {
  listResponse
  tableDisplay
  smqResp
  sjcResp
  hkResp
  lonResp
  disabledResp
  backupDetailsObj
  backupEndTime
  backupStartTime
  backupDetailsArr
  contentDetailsObj
  contentTime
  contentDetailsArr = []
  detailsShow = false
  contentBackupStatus = false
  dbBackupStatus = false
  detailsAccountName
  tableToggle = 'enabled'
  noDataToDisplay = false
  tablesLoaded
  indexVal
  tableObj

  constructor(private router: Router, private apiCallsService:ApiCallsService) { }

  async ngOnInit(): Promise<any> {
    this.listResponse = await this.getInitialList()
    this.tablesLoaded = true
    this.indexVal = 0
   }

   showBackupDetails = (param, datacenter) => {
    this.backupDetailsArr = []
    this.contentDetailsArr = []
    var backupArr = []
    backupArr.push(this.listResponse.data[datacenter])
    for (var i = 0; i < backupArr[0].enabled.length; i++) {
      if (param == backupArr[0].enabled[i]['connect-id'] ) {
        this.detailsAccountName = backupArr[0].enabled[i]['account-name'] + " (" + backupArr[0].enabled[i]['connect-id'] + ")"
        if (backupArr[0].enabled[i]['last-s3-run']) {
          this.backupDetailsObj = backupArr[0].enabled[i]['last-s3-run'].stats
          for (var key in this.backupDetailsObj) {
            var newObj = {
              "title": key,
              "value": this.backupDetailsObj[key]
            }
            this.backupDetailsArr.push(newObj)
          }
          this.backupEndTime = backupArr[0].enabled[i]['last-s3-run']['last-finish-time']
          this.backupStartTime = backupArr[0].enabled[i]['last-s3-run']['last-start-time']
          this.dbBackupStatus = true
        } else {
          this.dbBackupStatus = false
        }
    } else {
      continue
    }
  }
    this.detailsShow = true
   }

   getInitialList = async() => {
    var data = {
      "domain":"data-lake",
      "api":"list-s3-archive-statuses",
      "properties": {
      }
    };
    var response = await this.apiCallsService.sendApi(data)
    return response
  }

  tableChange = (param, object) => {
    this.detailsShow = false;
    this.noDataToDisplay = false;
    this.tableObj = object
  }

}
