<div class="background-image">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-lg-8 col-xl-6 col-md-10 login-container">
      <div class="row">
          <div class="col-sm-12 col-md-6" id="login-left">
            <h2>Welcome to CoSo Insights</h2>
            <p>Enter your username and password to login</p>
            <img id="login-coso-logo" src="../../assets/images/logo_coso_cloud_bug_white.svg" />
            <a class="coso-login-link" href="https://www.cosocloud.com" target="_blank">cosocloud.com</a>
          </div>
          <div class="col-sm-12 col-md-4" id="login-center">
            <div [hidden]="loginSpinner" id="login-spinner">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <h3>Log In</h3>
            <p class="error-pass" *ngIf="errorBool == true">
              {{errorMsg}}
            </p>
            <form class="form-group">
            <input class="form-control" type="text" id="username" placeholder="Username" />
            <input class="form-control" type="password" id="password" placeholder="Password" />
            <button type="submit" class="btn btn-primary" (click)="login()">LOG IN</button>
            <button class="btn btn-light" (click)="open(content)">Forgot Password</button>
            </form>
          </div>
          <div class="col-sm-12 col-md-2" id="login-right"></div>
      </div>
      </div>
    </div>
    <!-- <ngb-toast class="alert alert-success" id="passToast" *ngIf="passwordToast">
      <p>Your password has been reset!</p>
      <p>{{successMessage}}</p>
    </ngb-toast> -->
    <ngb-toast class="alert alert-danger" id="authError" *ngIf="authError">
      <p>Something went wrong with the Authorization Process. Please try to login again. ErrMsg: {{authErrorMsg}}</p>
    </ngb-toast>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Forgot Password</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Please enter the email associated with the account</p>
        <input class="col-sm-12 form-control" type="text" placeholder="Enter your email" [(ngModel)]="forgotEmail" />
        <button id="forgotSubmit" (click)="forgotPass()" class="col-sm-12 btn btn-primary">Submit</button>
        <p *ngIf="errorforgot" class="text-center">{{userErrorMsg}}</p>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
      </div>
    </ng-template>
</div>