import { Component, OnInit, HostListener } from '@angular/core';
import { ApiCallsService } from '../../shared/services/api-calls.service';
import {Router, CanActivate } from '@angular/router';
import { trigger, state, transition, style, animate } from '@angular/animations';  

@Component({
  selector: 'app-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss'],
  animations:[ 
    trigger('fade',
    [ 
      state('void', style({ opacity : 0})),
      transition(':enter',[ animate(300)]),
      transition(':leave',[ animate(500)]),
    ]
)]
})
export class SidebarNavComponent implements OnInit {

  constructor(private apiCallsService:ApiCallsService, private router: Router) { }
  showDataboards = false;
  isLoggedIn = sessionStorage.getItem("loggedInBool");
  isAdmin:any = sessionStorage.getItem("isInsightsAdmin");
  isSSO:any = sessionStorage.getItem("is_show_ssl_log"); 
  entitlementObj = JSON.parse(sessionStorage.getItem("entitlements"))
  installDisabled = JSON.parse(localStorage.getItem('SelectedInstalls'))
  customMon = JSON.parse(sessionStorage.getItem('customMonCheck'))
  only
  ccHomepage = sessionStorage.getItem("onlyCC")
  auditFull
  ccOnly = false
  indexVal = 0
  auditInactiveUsers
  supportTicketing
  e2Effectiveness
  supportOnly = false
  insightsSwitch = true
  
  ngOnInit(): void {
    if (this.ccHomepage == 'cc') {
      this.indexVal = 1
    }
    if (this.isSSO == "true"){
      this.isSSO = true
    }
    if (this.isAdmin == "true") {
      this.isAdmin = true
    }
    for (var key in this.entitlementObj) {
      switch (key) {
        case "support-ticketing" :
           if (this.entitlementObj[key] === true) {
             this.supportTicketing = true
           }
        break;
        case "e2-effectiveness" :
          if (this.entitlementObj[key] === true) {
            this.e2Effectiveness = true
          }
       break;
       case "audit-inactive-users" :
        if (this.entitlementObj[key] === true) {
          this.auditFull = true
          this.auditInactiveUsers = true
        }
        break;
        case "no-connect" :
          if (this.entitlementObj[key] === true) {
            this.supportOnly = true
          }
     break;
        default: 
        
      }
    }
    if (this.isLoggedIn !== "yes") {
      this.router.navigate(['/login']);
    } 
  }

  CCPerms = sessionStorage.getItem("CCPerms")
  toggleNav = true;
  showAudit = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (document.getElementById('mobileNav')) {
      if (window.pageYOffset > 77) {
        let element = document.getElementById('mobileNav')
        element.classList.add('sticky');
      }else {
        let element = document.getElementById('mobileNav')
        element.classList.remove('sticky');
      }
    }
    if (document.getElementById('sidebarNav')) {
     if (window.pageYOffset > 119) {
        let element = document.getElementById('sidebarNav');
        element.classList.add('sticky');
     } else {
        let element = document.getElementById('sidebarNav');
        element.classList.remove('sticky'); 
     }
    }
     if (window.pageYOffset > 1) {
      if (document.getElementById('sidebarNav')) {
      let element = document.getElementById('bottomArea');
      element.classList.add('showbot');
      }
    } else {
      if (document.getElementById('sidebarNav')) {
     let element = document.getElementById('bottomArea');
       element.classList.remove('showbot'); 
      }
    }
  }

  logout = () => {
    var logoutObj: {[k: string]: any} = {};
    var sessionKeys = JSON.parse(sessionStorage.getItem("sessionKeys"));
    logoutObj.keysmqcc = sessionKeys.smqcc;
    logoutObj.keysjccc = sessionKeys.sjccc;
    var response = this.apiCallsService.logoutApi(logoutObj)
    sessionStorage.clear()
    this.router.navigate(['/login'])
    return response
  }
  
  navigateToRoute(route: string): void {
    this.router.navigate([route]);
    // window.location.pathname = route;
  }
  
}
