import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import {FormGroup, FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-installs-select-dash',
  templateUrl: './installs-select-dash.component.html',
  styleUrls: ['./installs-select-dash.component.scss']
})
export class InstallsSelectDashComponent implements OnInit {

  selectedInstalls = []
  installationList = JSON.parse(sessionStorage.getItem("installs"))
  installationChecks = this.installationList

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.selectedInstalls = JSON.parse(localStorage.getItem('SelectedInstalls'));
    if (this.selectedInstalls !== null) {
      for (var i = 0; i < this.installationChecks.length; i++) {
        for (var o = 0; o < this.selectedInstalls.length; o++) {
          if (this.selectedInstalls[o].name == this.installationChecks[i].name) {
            this.installationChecks[i].checked = true;
          }
        }
      }
  }
}

  installChecked = (install, checkvalue) => {
    if (this.selectedInstalls == null) {
      this.selectedInstalls = []
    }
    if (checkvalue == true) {
      this.selectedInstalls.push(install)
      localStorage.setItem('SelectedInstalls', JSON.stringify(this.selectedInstalls))
    } else {
      for (var i = 0; i < this.selectedInstalls.length; i++) {
        if (this.selectedInstalls[i].name == install.name) {
          this.selectedInstalls.splice(i, 1);
        }
      }
      localStorage.setItem('SelectedInstalls', JSON.stringify(this.selectedInstalls))
    }
  }

  selectInstall = (selected) => {
    sessionStorage.setItem('SelectedInstalls', JSON.stringify(selected))
    this.router.navigate(['/dashboard'])
  }

}
