<div class="d-none d-xl-block">
    <div (scroll)="onWindowScroll($event);" id="sidebarNav" class="sidebar-navigation">
        <mat-tab-group [selectedIndex]="indexVal" animationDuration="0ms" class="tabContainer"
            *ngIf="CCPerms == 'Yes' && supportOnly !== true" mat-align-tabs="center">
            <mat-tab label="Insights">
                <ul class="nav-primary">
                    <li [routerLink]="['/dashboard']"><mat-icon class="sideIcon">apps</mat-icon>Dashboard</li>
                    <li *ngIf="supportTicketing == true" [routerLink]="['/tech-support']"><mat-icon
                            class="sideIcon">local_hospital</mat-icon>Tech Support</li>
                    <!-- <li id="databoardMenu" (click)="showDataboards = !showDataboards"><span><mat-icon
                                class="sideIcon">menu</mat-icon>Databoards</span></li> -->
                    <li class="subMenu" [hidden]="!showDataboards">
                        <div class="subMenuLinks">
                            <a [routerLink]="['/meetings']"><mat-icon class="sideIcon">people</mat-icon>Meetings</a>
                            <a [routerLink]="['/recentuserlog']"><mat-icon
                                    class="sideIcon">perm_identity</mat-icon>Recent Logins</a>
                            <a [routerLink]="['/recentcontent']"><mat-icon class="sideIcon">work</mat-icon>Recent
                                Content</a>
                        </div>
                    </li>
                    <li *ngIf="isAdmin == true" id="admin" [routerLink]="['/admin']"><mat-icon
                            class="sideIcon">flash_on</mat-icon>Admin</li>
                    <li [routerLink]="['/metrics']"><mat-icon class="sideIcon">show_chart</mat-icon>Metrics</li>
                    <li [routerLink]="['/tech-support']"><mat-icon class="sideIcon">contact_mail</mat-icon>Support</li>
                    <li *ngIf="isSSO == true" (click)="navigateToRoute('/ssologs')">
                        <mat-icon class="sideIcon">assignment</mat-icon>SSO Logs
                    </li>
                    <!-- <li [routerLink]="['/reports']"><mat-icon class="sideIcon">assignment</mat-icon>Reports</li> -->
                    <!-- <li *ngIf="customMon == true" [routerLink]="['/customMonitor']"><mat-icon
                            class="sideIcon">assessment</mat-icon>Custom Monitoring</li> -->
                    <li *ngIf="auditFull == true" id="auditMenu" (click)="showAudit = !showAudit"><span><mat-icon
                                class="sideIcon">menu</mat-icon>Audit</span>
                    <li class="subMenu" [hidden]="!showAudit">
                        <div class="subMenuLinks">
                            <a *ngIf="auditInactiveUsers == true" [routerLink]="['/disableuser']"><mat-icon
                                    class="sideIcon">people</mat-icon>Disable User</a>
                        </div>
                    </li>
                    <li id="logout" (click)="logout()"><mat-icon class="sideIcon">exit_to_app</mat-icon>Logout</li>
                </ul>
            </mat-tab>
            <mat-tab label="Control Center">
                <ul class="nav-primary">
                    <li [routerLink]="['/ccListAccounts']">Account Management</li>
                    <li [routerLink]="['/ccPerfMon']">Performance Monitor</li>
                    <li [routerLink]="['/ccConnectBackup']">Backup Status</li>
                    <li [routerLink]="['/ccs3']">S3 Archive</li>
                    <li *ngIf="customMon == true" [routerLink]="['/ccCustomMonitor']">Custom Monitoring</li>
                    <li [routerLink]="['/license-report']">License Reports</li>
                    <li [routerLink]="['/ccRemoteMonitor']">Remote Monitor</li>
                    <li title="Extras" [routerLink]="['/ccExtras']">Extras</li>
                    <li (click)="logout()">Logout</li>
                </ul>
            </mat-tab>
        </mat-tab-group>
        <ul *ngIf="CCPerms !== 'Yes' && supportOnly !== true" class="nav-secondary">
            <li [routerLink]="['/dashboard']"><mat-icon class="sideIcon">apps</mat-icon>Dashboard</li>
            <li *ngIf="supportTicketing == true" [routerLink]="['/tech-support']"><mat-icon
                    class="sideIcon">local_hospital</mat-icon>Tech Support</li>
            <!-- <li id="databoardMenu" (click)="showDataboards = !showDataboards"><span><mat-icon
                        class="sideIcon">menu</mat-icon>Databoards</span></li> -->
            <!-- <div [hidden]="showDataboards" style="border-bottom:1px solid white; height:0;"></div> -->
            <li id="subMenu" [hidden]="!showDataboards">
                <div id="subMenuLinks">
                    <a [routerLink]="['/meetings']"><mat-icon class="sideIcon">people</mat-icon>Meetings</a>
                    <a [routerLink]="['/recentuserlog']"><mat-icon class="sideIcon">perm_identity</mat-icon>Recent
                        Logins</a>
                    <a [routerLink]="['/recentcontent']"><mat-icon class="sideIcon">work</mat-icon>Recent Content</a>
                </div>
            </li>
            <!-- <li *ngIf="isAdmin == true" id="admin"  [routerLink]="['/admin']"><mat-icon
                    class="sideIcon">flash_on</mat-icon>Admin</li> -->
            <li *ngIf="isAdmin == true" id="admin" (click)="navigateToRoute('/admin')"><mat-icon
                    class="sideIcon">flash_on</mat-icon>Admin</li>        
            <li [routerLink]="['/metrics']"><mat-icon class="sideIcon">show_chart</mat-icon>Metrics</li>
            <li [routerLink]="['/tech-support']"><mat-icon class="sideIcon">contact_mail</mat-icon>Support</li>
            <li *ngIf="isSSO == true" (click)="navigateToRoute('/ssologs')">
                <mat-icon class="sideIcon">assignment</mat-icon>SSO Logs
            </li>
            <!-- <li *ngIf="isSSO == true" (click)="navigateToRoute('/ssologs')"><mat-icon class="sideIcon">assignment</mat-icon>SSO Logs</li> -->
            <!-- <li [routerLink]="['/reports']"><mat-icon class="sideIcon">assignment</mat-icon>Reports</li> -->
            <!-- <li *ngIf="customMon == true" [routerLink]="['/customMonitor']"><mat-icon class="sideIcon">assessment</mat-icon>Custom Monitoring</li> -->
            <li *ngIf="auditFull == true" id="auditMenu" (click)="showAudit = !showAudit" [routerLink]="['/reports']"><mat-icon class="sideIcon">menu</mat-icon>Audit</li>
            <li class="subMenu" [hidden]="!showAudit">
                <div class="subMenuLinks">
                    <a *ngIf="auditInactiveUsers == true" [routerLink]="['/disableuser']"><mat-icon
                            class="sideIcon">people</mat-icon>Disable User</a>
                </div>
            </li>
            <li id="logout" (click)="logout()"><mat-icon class="sideIcon">exit_to_app</mat-icon>Logout</li>
        </ul>
        <mat-tab-group [selectedIndex]="indexVal" animationDuration="0ms" class="tabContainer"
            *ngIf="supportOnly == true && CCPerms == 'Yes'" mat-align-tabs="center">
            <mat-tab label="Insights">
                <ul class="nav-secondary">
                    <li [routerLink]="['/tech-support']"><mat-icon class="sideIcon">local_hospital</mat-icon>Tech
                        Support</li>
                    <li id="logout" (click)="logout()"><mat-icon class="sideIcon">exit_to_app</mat-icon>Logout</li>
                </ul>
            </mat-tab>
            <mat-tab label="Control Center">
                <ul class="nav-primary">
                    <li [routerLink]="['/ccListAccounts']">Account Management</li>
                    <li [routerLink]="['/ccPerfMon']">Performance Monitor</li>
                    <li [routerLink]="['/ccConnectBackup']">Backup Status</li>
                    <li [routerLink]="['/ccs3']">S3 Archive</li>
                    <li *ngIf="customMon == true" [routerLink]="['/ccCustomMonitor']">Custom Monitoring</li>
                    <li [routerLink]="['/license-report']">License Reports</li>
                    <li [routerLink]="['/ccRemoteMonitor']">Remote Monitor</li>
                    <li [routerLink]="['/ccExtras']">Extras</li>
                    <li (click)="logout()">Logout</li>
                </ul>
            </mat-tab>
        </mat-tab-group>
        <ul *ngIf="supportOnly == true && CCPerms !== 'Yes'" class="nav-secondary">
            <li [routerLink]="['/tech-support']"><mat-icon class="sideIcon">local_hospital</mat-icon>Tech Support</li>
            <li id="logout" (click)="logout()"><mat-icon class="sideIcon">exit_to_app</mat-icon>Logout</li>
        </ul>
    </div>
    <div style="padding-top:30px;" id="bottomArea">
        <div class="row">
            <div class="col-8 offset-2">
                <div class="icon-links row">
                    <div class="col-4 text-center"><a href="https://www.facebook.com/cosocloud"><svg
                                class="social-icons" width="20" height="20" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg" data-svg="facebook">
                                <path
                                    d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z">
                                </path>
                            </svg></a></div>
                    <div class="col-4 text-center"><a href="https://twitter.com/CoSoCloud"><svg class="social-icons"
                                width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                data-svg="twitter">
                                <path
                                    d="M19,4.74 C18.339,5.029 17.626,5.229 16.881,5.32 C17.644,4.86 18.227,4.139 18.503,3.28 C17.79,3.7 17.001,4.009 16.159,4.17 C15.485,3.45 14.526,3 13.464,3 C11.423,3 9.771,4.66 9.771,6.7 C9.771,6.99 9.804,7.269 9.868,7.539 C6.795,7.38 4.076,5.919 2.254,3.679 C1.936,4.219 1.754,4.86 1.754,5.539 C1.754,6.82 2.405,7.95 3.397,8.61 C2.79,8.589 2.22,8.429 1.723,8.149 L1.723,8.189 C1.723,9.978 2.997,11.478 4.686,11.82 C4.376,11.899 4.049,11.939 3.713,11.939 C3.475,11.939 3.245,11.919 3.018,11.88 C3.49,13.349 4.852,14.419 6.469,14.449 C5.205,15.429 3.612,16.019 1.882,16.019 C1.583,16.019 1.29,16.009 1,15.969 C2.635,17.019 4.576,17.629 6.662,17.629 C13.454,17.629 17.17,12 17.17,7.129 C17.17,6.969 17.166,6.809 17.157,6.649 C17.879,6.129 18.504,5.478 19,4.74">
                                </path>
                            </svg></a></div>
                    <div class="col-4 text-center"><a href="https://www.linkedin.com/company/coso-cloud"><svg
                                class="social-icons" width="20" height="20" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg" data-svg="linkedin">
                                <path
                                    d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z">
                                </path>
                                <path
                                    d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z">
                                </path>
                            </svg></a></div>
                </div>
                <div>
                    <hr style="border-top-color:rgba(255,255,255,.2)" />
                </div>
                <div class="">
                    <a style="color:white" href="https://cosocloud.com/" target="_blank"
                        class="uk-button uk-button-text">COSO CLOUD</a> <span><svg class="social-icons" width="20"
                            height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                            data-svg="triangle-right">
                            <polygon points="8 5 13 10 8 15"></polygon>
                        </svg></span>
                </div>
                <p style="font-weight:400;color:rgba(255,255,255,.5); font-size: 0.875rem;">Copyright 2023 CoSo Cloud
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-8  offset-2">
                <p style="font-weight:400;color:white; font-size: 0.475rem;">UI version 2021.1.075</p>
            </div>
        </div>
    </div>
</div>
<div class="d-xl-none">
    <div (scroll)="onWindowScroll($event);" id="mobileNav" class="sidebar-navigation">
        <ul *ngIf="insightsSwitch == true" class="nav-primary">
            <div *ngIf="CCPerms == 'Yes'" style="padding:0" class="custom-control custom-switch">
                <span style="float:left;padding-left:5px;font-size:12px;color:white;padding-top:3px;">CC</span>
                <input style="width:100%" [(ngModel)]="insightsSwitch" type="checkbox" class="custom-control-input"
                    id="customSwitch1" />
                <label class="custom-control-label" style="left:53%" for="customSwitch1"></label>
                <span style="float:right;padding-right:5px;font-size:12px;color:#00a6d9;padding-top:3px;">IS</span>
            </div>
            <li title="Dashboard" [routerLink]="['/dashboard']"><mat-icon class="sideIcon">apps</mat-icon></li>
            <li title="Support" *ngIf="supportTicketing == true" [routerLink]="['/tech-support']"><mat-icon
                    class="sideIcon">local_hospital</mat-icon></li>
            <!-- <li title="Databoards" id="databoardMenu" (click)="showDataboards = !showDataboards"><span><mat-icon
                        class="sideIcon">menu</mat-icon></span></li> -->
            <li class="subMenu" [hidden]="!showDataboards">
                <div class="subMenuLinks">
                    <a title="Meetings" [routerLink]="['/meetings']"><mat-icon class="sideIcon">people</mat-icon></a>
                    <a title="Recent Users" [routerLink]="['/recentuserlog']"><mat-icon
                            class="sideIcon">perm_identity</mat-icon></a>
                    <a title="Recent Content" [routerLink]="['/recentcontent']"><mat-icon
                            class="sideIcon">work</mat-icon></a>
                </div>
            </li>
            <li *ngIf="isAdmin == true" id="admin" [routerLink]="['/admin']"><mat-icon
                    class="sideIcon">flash_on</mat-icon></li>
            <li title="Metrics" [routerLink]="['/metrics']"><mat-icon class="sideIcon">show_chart</mat-icon></li>

            <li [routerLink]="['/tech-support']"><mat-icon class="sideIcon">contact_mail</mat-icon></li>
            <li *ngIf="isSSO == true" [routerLink]="['/ssologs']">
                <mat-icon class="sideIcon">assignment</mat-icon>
            </li>
            <!-- <li title="Reports" [routerLink]="['/reports']"><mat-icon class="sideIcon">assignment</mat-icon></li> -->
            <!-- <li *ngIf="customMon == true" [routerLink]="['/ccCustomMonitor']"><mat-icon
                    class="sideIcon">assessment</mat-icon></li> -->
            <li title="Audit" *ngIf="auditFull == true" id="auditMenu" (click)="showAudit = !showAudit"><span><mat-icon
                        class="sideIcon">menu</mat-icon></span>
            <li class="subMenu" [hidden]="!showAudit">
                <div class="subMenuLinks">
                    <a title="Disable User" *ngIf="auditInactiveUsers == true" [routerLink]="['/disableuser']"><mat-icon
                            class="sideIcon">people</mat-icon></a>
                </div>
            </li>
            <li title="Logout" id="logout" (click)="logout()"><mat-icon class="sideIcon">exit_to_app</mat-icon></li>
        </ul>
        <ul *ngIf="insightsSwitch == false" class="nav-primary">
            <div style="padding:0" class="custom-control custom-switch">
                <span style="float:left;padding-left:5px;font-size:12px;color:#00a6d9;padding-top:3px;">CC</span>
                <input style="width:100%" [(ngModel)]="insightsSwitch" type="checkbox" class="custom-control-input"
                    id="customSwitch1" />
                <label class="custom-control-label" style="left:53%" for="customSwitch1"></label>
                <span style="float:right;padding-right:5px;font-size:12px;color:white;padding-top:3px;">IS</span>
            </div>
            <li title="Account Management" [routerLink]="['/ccListAccounts']"><mat-icon>account_box</mat-icon></li>
            <li title="Performance Monitor" [routerLink]="['/ccPerfMon']"><mat-icon>slow_motion_video</mat-icon></li>
            <li title="Backup Status" [routerLink]="['/ccConnectBackup']"><mat-icon>cached</mat-icon></li>
            <li title="Archive Status" [routerLink]="['/ccs3']"><mat-icon>cached</mat-icon></li>
            <li *ngIf="customMon == true" [routerLink]="['/ccCustomMonitor']"><mat-icon>assessment</mat-icon></li>
            <li title="License Reports" [routerLink]="['/license-report']"><mat-icon> show_chart</mat-icon></li>
            <li title="Resource Monitor" [routerLink]="['/ccRemoteMonitor']"><mat-icon>signal_cellular_alt</mat-icon>
            </li>
            <li title="Extras" [routerLink]="['/ccExtras']"><mat-icon> business</mat-icon></li>
            <li title="Logout" id="logout" (click)="logout()"><mat-icon class="sideIcon">exit_to_app</mat-icon></li>
        </ul>
    </div>
</div>